import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

export const NavButton = styled(NavLink)`
  color: var(--color-textLight);
  font-size: var(--font-size-sm);
  font-weight: 500;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 6px;
  white-space: nowrap;
  margin: 0 0.25rem;

  //Temporary styles to delete when all pages are implemented
  min-width: 100px;
  display: flex;
  justify-content: center;

  &:hover {
    color: var(--color-textLight);
    background-color: var(--color-primaryHover);
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &.active {
    color: var(--color-textContrast) !important;
    background: var(--color-primaryLight) !important;
  }
`;

import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from "@mui/material";
import * as React from "react";

export interface AvatarProps extends MuiAvatarProps {
  // Add your custom props here
}

export const Avatar: React.FC<AvatarProps> = ({ ...props }) => {
  return <MuiAvatar {...props} />;
};

import { ToggleButton as MuiToggleButton, ToggleButtonProps as MuiToggleButtonProps, styled } from "@mui/material";
import * as React from "react";

//Excluding unused props
type ToggleButtonBaseProps = Omit<
  MuiToggleButtonProps,
  | "centerRipple"
  | "disableFocusRipple"
  | "disableElevation"
  | "disableRipple"
  | "disableTouchRipple"
  | "focusRipple"
  | "TouchRippleProps"
  | "touchRippleRef"
>;

export interface ToggleButtonProps extends ToggleButtonBaseProps {
  // Add your custom props here
}

const StyledToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  textTransform: "none",
  padding: theme.spacing(0.5, 1),
}));

export const ToggleButton: React.FC<ToggleButtonProps> = ({ size = "small", color = "secondary", ...props }) => {
  return <StyledToggleButton size={size} color={color} {...props} />;
};

import { DirectionsCar, FormatListBulleted, Place } from "@mui/icons-material";
import { Box, Grid, List, ListItem, Stack, Typography, styled } from "@mui/material";
import { Dialog, Divider, MenuItem, TextField } from "components_new";
import React, { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { AreaAccuracyTable } from "features/data-quality/AreaAccuracyTable";
import {
  ControlsScatterContainer,
  DataQualityMetrics,
  DataQualityMetricsContainer,
} from "features/data-quality/DataQualityMetrics";
import { InfoPopover } from "features/data-quality/InfoPopover";
import accuracyTableData from "features/data-quality/nationwide-by-aadt-group.json";
import NationwideScatter2023Img from "features/data-quality/nationwide-scatter-2023.png";
import { roadClassGroups } from "features/data-quality/roadClassGroups";

import { FlexContainer } from "components";

import { usePageTracking } from "hooks";

import { Chart, ChartContainer } from "./DocsCharts";
import { DocsIcon } from "./DocsIcon";
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";
import { QualityPoint } from "./QualityPoint";
import { QualityPointType, generateQualityPoints } from "./qualityPoints";

const DataQualtyContainer = styled(PageContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitle = styled("h4")`
  grid-area: subtitle;
  margin: 0.5rem 0;
  font-size: 16px;
`;

const DevItemsContainer = styled("div")`
  max-width: 1500px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  gap: 2rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 992px) {
    gap: 4rem;
  }
`;

const DevItem = styled("div")`
  margin: 1rem 0;
  display: grid;
  align-content: start;
  align-items: center;
  justify-items: flex-start;
  grid-template-columns: auto 1rem 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "docs-icon . subtitle"
    "docs-text docs-text docs-text";

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "docs-icon"
      "subtitle"
      "docs-text";
  }
`;

const QualityAssuranceList = styled("div")`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
`;

const Text = styled("span")<{ italic?: boolean; black?: boolean; bold?: boolean }>`
  max-width: 1500px;
  grid-area: docs-text;
  margin: 0.5rem 0;
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${({ black }) => (black ? "#000000" : "var(--color-textSecondary)")};
`;

const ChartTitle = styled("h4")`
  color: var(--color-text);
  font-size: 14px;
  font-weight: 500;
  margin: 0.5rem 0 0.25rem 0;
  text-align: center;

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
  }
`;

const ChartImg = styled("img")`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const Glossary = styled(FlexContainer)`
  width: 500px;
  justify-content: space-between;
`;

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: "100%",
  maxWidth: "1500px",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

export const DataQuality: FC = () => {
  const [selectedChart, setSelectedChart] = useState<Chart>();
  const [qualityPoints, setQualityPoints] = useState<QualityPointType[]>(generateQualityPoints(setSelectedChart));

  usePageTracking();

  const handleToggleQualityPoint = useCallback(
    (index: number, callback: Dispatch<SetStateAction<QualityPointType[]>>) => {
      callback(qualityPoints.map((point, i) => (i === index ? { ...point, isOpen: !point.isOpen } : point)));
    },
    [qualityPoints],
  );

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={!!selectedChart} onClose={() => setSelectedChart(undefined)}>
        <ChartContainer>
          <ChartTitle>{selectedChart?.title}</ChartTitle>
          <ChartImg src={selectedChart?.image} alt="" />
        </ChartContainer>
      </Dialog>
      <>
        <PageHeader title="OpenPaths Patterns: Data Quality" />
        <DataQualtyContainer>
          <Typography variant="h5" fontWeight={700} marginBottom={2} textAlign={"center"}>
            Quality Assurance
          </Typography>
          <Text>
            Quality assurance is an integral part of the OpenPaths Patterns data product creation process. When traffic
            volumes and trips are updated, validation reports are automatically created to ensure quality goals are
            achieved. Validation is completed for every region of the US using local and national data sources such as
            traffic counts and travel survey data.
          </Text>
          <StyledDivider />
          <DataQualityMetrics />
          <StyledDivider />
          <Box marginY={1}>
            <Typography variant="h5" fontWeight={700} marginBottom={2} textAlign={"center"}>
              Data Quality Metrics - Nationwide USA - 2023
            </Typography>
            <DataQualityMetricsContainer>
              <ControlsScatterContainer>
                <TextField
                  fullWidth
                  select
                  label="Road classes"
                  value={0}
                  SelectProps={{ open: false, IconComponent: () => <div style={{ marginLeft: "-16px" }} /> }}
                >
                  <MenuItem value={0}>
                    <List>
                      {Object.entries(roadClassGroups).map(([key, { label, legendSymbol }]) => {
                        return (
                          <ListItem key={key} sx={{ justifyContent: "space-between" }}>
                            <Typography fontSize={12} fontWeight={500} color={"text.secondary"}>
                              {label}
                            </Typography>
                            <Grid item xs={"auto"} container alignItems={"center"}>
                              <Grid container alignItems={"center"} marginRight={1} item xs={"auto"}>
                                {legendSymbol}
                              </Grid>
                              <InfoPopover>
                                <Stack margin={1}>
                                  {roadClassGroups[key].classes.map((c) => (
                                    <Typography key={c.id} variant="caption">
                                      {c.label}
                                    </Typography>
                                  ))}
                                </Stack>
                              </InfoPopover>
                            </Grid>
                          </ListItem>
                        );
                      })}
                    </List>
                  </MenuItem>
                </TextField>
                <img src={NationwideScatter2023Img} alt="Nationwide Scatter 2023" width={700} height={400} />
              </ControlsScatterContainer>
              <AreaAccuracyTable data={accuracyTableData} />
            </DataQualityMetricsContainer>
          </Box>

          <Grid container maxWidth={1500} flexDirection={"column"}>
            <Text bold>Quality review and validation are completed across multiple dimensions, including:</Text>
            <QualityAssuranceList>
              {qualityPoints?.map((point, i) => (
                <QualityPoint
                  key={i}
                  isOpen={point.isOpen}
                  title={point.title}
                  subtitle={point.subtitle}
                  handleToggle={() => handleToggleQualityPoint(i, setQualityPoints)}
                >
                  {point.children}
                </QualityPoint>
              ))}
            </QualityAssuranceList>
          </Grid>
          <Text italic>
            <Text italic black bold>
              How is the target obtained?
            </Text>{" "}
            Targets are primarily estimated from household travel surveys, including the National Household Travel
            Survey. Federally sponsored travel reports, such as those published by the National Cooperative Highway
            Research Program (NCHRP).
          </Text>
          <Glossary>
            <Text>
              <Text black bold>
                HBW:
              </Text>{" "}
              home-based work
            </Text>
            <Text>
              <Text black bold>
                HBO:
              </Text>{" "}
              home-based other
            </Text>
            <Text>
              <Text black bold>
                NHB:
              </Text>{" "}
              non home-based
            </Text>
          </Glossary>

          <StyledDivider />
          <Typography variant="h5" fontWeight={700} marginBottom={2} textAlign={"center"}>
            How is OpenPaths Patterns developed?
          </Typography>
          <DevItemsContainer>
            <DevItem>
              <DocsIcon>
                <Place />
              </DocsIcon>
              <SubTitle>Sampled Movement</SubTitle>
              <Text>
                Billions of GPS location points from mobile devices and connected vehicles are transformed into trips
                routed on the road network, with origins and destinations, trip purpose, time of day, and home location.
              </Text>
            </DevItem>
            <DevItem>
              <DocsIcon>
                <DirectionsCar />
              </DocsIcon>
              <SubTitle>Referenced Movement</SubTitle>
              <Text>
                More than 2 million observed traffic counts from published government sources and toll road authorities,
                along with demographic and employment data, and points of interest data are regularly compiled to assist
                in the process of scaling sampled movements.
              </Text>
            </DevItem>
            <DevItem>
              <DocsIcon>
                <FormatListBulleted />
              </DocsIcon>
              <SubTitle>Scaled Movement</SubTitle>
              <Text>
                Mobility relationships derived from government surveys, and technical publications, are used in the
                expansion process along with the referenced movement data, to produce travel data representative of the
                total population.
              </Text>
            </DevItem>
          </DevItemsContainer>
        </DataQualtyContainer>
      </>
    </>
  );
};

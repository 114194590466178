import { Box, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useMemo } from "react";

import { RoadClassSelector, SelectorRoadClasses } from "components";

import { useAppSelector } from "hooks";

import { StyledCheckbox } from "./ScreenlineEditor";

export interface ImportOptionsTabProps {
  roadClasses: SelectorRoadClasses | null;
  setRoadClasses: Dispatch<SetStateAction<SelectorRoadClasses | null>>;
  appendToExistingScreenlines: boolean;
  setAppendToExistingScreenlines: Dispatch<SetStateAction<boolean>>;
  ignoreDisjointFeatures: boolean;
  setIgnoreDisjointFeatures: Dispatch<SetStateAction<boolean>>;
}

export const ImportOptionsTab: FC<ImportOptionsTabProps> = ({
  roadClasses,
  setRoadClasses,
  appendToExistingScreenlines,
  setAppendToExistingScreenlines,
  ignoreDisjointFeatures,
  setIgnoreDisjointFeatures,
}) => {
  const metadataRoadClasses = useAppSelector((state) => state.analytics.roadsMetadata.data?.roadClasses);
  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const disjointFeaturesCount = useAppSelector(
    (state) => state.screenlines.uploadedScreenlines.data?.disjointCount || 0,
  );

  const savedRoadClasses = useMemo(() => metadataRoadClasses?.map((rc) => rc.id) || [], [metadataRoadClasses]);
  const totalRoadClasses = useMemo(() => Object.keys(roadClasses?.items || {}).length, [roadClasses?.items]);
  const activeRoadClasses = useMemo(
    () => Object.values(roadClasses?.items || {}).filter((rc) => rc.isChecked).length,
    [roadClasses?.items],
  );
  return (
    <Stack paddingTop={3} rowGap={0.5} divider={<Divider />}>
      <Box>
        <RoadClassSelector
          roadClasses={roadClasses}
          savedRoadClasses={savedRoadClasses}
          setRoadClasses={setRoadClasses}
        />
        <Typography fontSize={11} marginTop={0.5}>
          {activeRoadClasses === 0 ? (
            <>
              Imported screenlines will not include any intersections since no road classes are selected. Screenline
              intersections can later be added.
            </>
          ) : (
            <>
              Imported screenlines will include intersections with the selected{" "}
              <Typography fontSize={11} component={"span"} fontWeight={700}>
                {`${activeRoadClasses} of ${totalRoadClasses} road classes`}
              </Typography>
              . Screenline intersections can later be edited.
            </>
          )}
        </Typography>
      </Box>
      {screenlines.length > 0 && (
        <FormControlLabel
          control={
            <StyledCheckbox
              sx={{ padding: 1 }}
              checked={appendToExistingScreenlines}
              onChange={(e) => setAppendToExistingScreenlines(e.target.checked)}
            />
          }
          label="Append to existing screenlines"
          slotProps={{ typography: { fontSize: 11, fontWeight: 600 } }}
        />
      )}
      {disjointFeaturesCount > 0 && (
        <FormControlLabel
          control={
            <StyledCheckbox
              sx={{ padding: 1 }}
              checked={ignoreDisjointFeatures}
              onChange={(e) => setIgnoreDisjointFeatures(e.target.checked)}
            />
          }
          label="Ignore screenlines fully outside of the selected area of interest"
          slotProps={{ typography: { fontSize: 11, fontWeight: 600 } }}
        />
      )}
    </Stack>
  );
};

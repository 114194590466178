import styled from "@emotion/styled";
import React, { FC, useState } from "react";

import { getSelectedVolumeObject } from "features/map/utils";

import { StreetName } from "components";

import { LEFT_SIDE_DIRECTIONS, SelectedVolume, Volume } from "types";

import { getVolumesByDirections } from "utils/ui";

export interface VolumePopupContentProps {
  isPedestriansMode: boolean;
  selectedVolume: SelectedVolume | null;
  volume: Volume[];
  title?: string;
  areSegmentsAddable?: boolean;
  onHover: (segmentIdx: number | null) => void;
  onClick: (selectedVolume: SelectedVolume, id: string, volumesProps?: Volume[]) => void;
}

const Header = styled.div`
  padding: 0.6rem 0.8rem 0.4rem;
  border-bottom: 1px solid var(--color-border);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const VolumeListContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;
`;

const VolumesList = styled.table`
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-spacing: 0 0.25rem;
`;

const StreetVolumeContainer = styled.tr<{ highlighted: boolean }>`
  vertical-align: middle;
  cursor: pointer;
  background-color: ${(props) => (props.highlighted ? "var(--color-secondaryLight)" : "transparent")};
  border-radius: 4px;
  & td:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  & td:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const VolumePopupContent: FC<VolumePopupContentProps> = ({
  isPedestriansMode,
  selectedVolume,
  volume,
  title,
  areSegmentsAddable,
  onHover,
  onClick,
}) => {
  const [hoveredSegmentIdx, setHoveredSegmentIdx] = useState<number | null>(null);

  const handleMouseEnter = (segmentIdx: number) => {
    if (segmentIdx !== selectedVolume?.ftSegmentIdx) {
      setHoveredSegmentIdx(segmentIdx);
      onHover(segmentIdx);
    }
  };

  const handleMouseLeave = () => {
    if (hoveredSegmentIdx) {
      setHoveredSegmentIdx(null);
      onHover(null);
    }
  };

  const handleClick = (selectedVolume: SelectedVolume, id: string) => {
    onClick(selectedVolume, id, volume);
    setHoveredSegmentIdx(null);
    onHover(null);
  };

  if (!title && !volume?.length) {
    return null;
  }

  return (
    <>
      <Header>
        <h3 style={{ paddingRight: "1rem" }}>
          {title || `${volume.length} road${volume.length !== 1 ? "s" : ""} selected`}
        </h3>
      </Header>
      <VolumeListContainer>
        <VolumesList>
          <tbody>
            {volume.map((v, i) => {
              const volumeObj = getSelectedVolumeObject(v);
              const volumeIndex =
                volumeObj.tfDirection && LEFT_SIDE_DIRECTIONS.indexOf(volumeObj.tfDirection) !== -1 ? 1 : 0;
              return (
                <StreetVolumeContainer
                  key={i}
                  highlighted={hoveredSegmentIdx === v.fromToIdx || selectedVolume?.ftSegmentIdx === v.fromToIdx}
                  onMouseEnter={() => {
                    handleMouseEnter(v.fromToIdx);
                  }}
                  onMouseLeave={handleMouseLeave}
                >
                  <StreetName
                    onClick={() =>
                      handleClick(
                        volumeObj,
                        // Here we determine the segment id based on the volume index and the segment direction
                        volumeIndex === 1 && i === 0 && v?.toFromId ? v.toFromId : v.fromToId,
                      )
                    }
                  >
                    {v.st_name || "Not named"}
                  </StreetName>

                  {getVolumesByDirections(v, isPedestriansMode, areSegmentsAddable, handleClick)}
                </StreetVolumeContainer>
              );
            })}
          </tbody>
        </VolumesList>
      </VolumeListContainer>
    </>
  );
};

import { Layer } from "mapbox-gl";

import {
  MAJOR_NETWORK_VOLUME_OPACITY,
  MAJOR_ROAD_VOLUME_COLOR,
  ROAD_HAIRLINE_COLOR,
  ROAD_NETWORK_VOLUME_HIGHLIGHT_OPACITY,
  ROAD_NETWORK_VOLUME_OPACITY,
  ROAD_SEGMENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
  ROAD_VOLUME_COLOR,
  ROAD_VOLUME_HOVER_COLOR,
} from "features/map/layerColors";

import { ROADS_SOURCE_ID } from "./sources";

export const ROADS_HAIRLINES_LAYER_ID = "ROADS_HAIRLINES_LAYER_ID";
export const ROADS_VOLUMES_LAYER_ID = "ROADS_VOLUMES_LAYER_ID";
export const ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID = "ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID";
export const ROADS_SEGMENTS_LAYER_ID = "ROADS_SEGMENTS_LAYER_ID";
export const LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID = "LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID";

const SelectLinkColorIds = {
  hairlineColor: "#f99933",
  volumeColor: "#fbbf80",
  volumeHoverColor: "#fab266",
  segmentHighlightColor: "#f88c1a",
  segmentSuperHighlightColor: "#7c4000",
  permanentHighlightColor: ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
  networkVolumeOpacity: 0.6,
  networkVolumeHighlightOpacity: 0.8,
  measureRangeColor: "#ffff00",
  majorRoadVolumeColor: "#fbbf80",
  majorRoadVolumeOpacity: 0.6,
};

export const getRoadsLayers = (layerName: string, isSelectLinkResults?: boolean): Layer[] => {
  const roadsLayers = [
    // Add roads hairlines layer
    {
      id: ROADS_HAIRLINES_LAYER_ID,
      type: "line",
      source: ROADS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": isSelectLinkResults ? SelectLinkColorIds.hairlineColor : ROAD_HAIRLINE_COLOR,
        "line-width": 0,
      },
    },
    // Add limited access roads volume layer
    {
      id: LIMITED_ACCESS_ROADS_VOLUMES_LAYER_ID,
      type: "line",
      source: ROADS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": isSelectLinkResults ? SelectLinkColorIds.majorRoadVolumeColor : MAJOR_ROAD_VOLUME_COLOR,
        "line-width": 0,
        "line-offset": ["number", ["feature-state", "volumeOffset"], 0],
        "line-opacity": isSelectLinkResults ? SelectLinkColorIds.majorRoadVolumeOpacity : MAJOR_NETWORK_VOLUME_OPACITY,
      },
    },
    // Add roads volume layer
    {
      id: ROADS_VOLUMES_LAYER_ID,
      type: "line",
      source: ROADS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": isSelectLinkResults ? SelectLinkColorIds.volumeColor : ROAD_VOLUME_COLOR,
        "line-width": 0,
        "line-offset": ["number", ["feature-state", "volumeOffset"], 0],
        "line-opacity": isSelectLinkResults ? SelectLinkColorIds.networkVolumeOpacity : ROAD_NETWORK_VOLUME_OPACITY,
      },
    },
    // Add roads highlighted volumes layer
    {
      id: ROADS_HIGHLIGHTED_VOLUMES_LAYER_ID,
      type: "line",
      source: ROADS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": isSelectLinkResults ? SelectLinkColorIds.volumeColor : ROAD_VOLUME_COLOR,
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ["number", ["feature-state", "volumeWeight"], 0],
          0,
        ],
        "line-offset": ["number", ["feature-state", "volumeOffset"], 0],
        "line-opacity": isSelectLinkResults
          ? SelectLinkColorIds.networkVolumeHighlightOpacity
          : ROAD_NETWORK_VOLUME_HIGHLIGHT_OPACITY,
      },
    },
    // // Add roads segments layer
    {
      id: ROADS_SEGMENTS_LAYER_ID,
      type: "line",
      source: ROADS_SOURCE_ID,
      "source-layer": layerName,
      paint: {
        "line-color": [
          "case",
          ["boolean", ["feature-state", "permanentSelectHighlight"], false],
          isSelectLinkResults ? SelectLinkColorIds.permanentHighlightColor : ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR,
          ["boolean", ["feature-state", "hoverHighlight"], false],
          isSelectLinkResults ? SelectLinkColorIds.volumeHoverColor : ROAD_VOLUME_HOVER_COLOR,
          ["boolean", ["feature-state", "selectHighlight"], false],
          isSelectLinkResults ? SelectLinkColorIds.segmentSuperHighlightColor : ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR,
          isSelectLinkResults ? SelectLinkColorIds.segmentHighlightColor : ROAD_SEGMENT_HIGHLIGHT_COLOR,
        ],
        "line-width": [
          "interpolate",
          ["exponential", 1.6],
          ["zoom"],
          6,
          ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
          20,
          ["case", ["boolean", ["feature-state", "hover"], false], 35, 30],
        ],
        "line-offset": 0,
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          ["boolean", ["feature-state", "permanentSelectHighlight"], false],
          1,
          0,
        ],
      },
    },
  ] as Layer[];

  return roadsLayers;
};

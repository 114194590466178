import { Geometry } from "geojson";
import { AnySourceData } from "mapbox-gl";

export const BOUNDARIES_SOURCE_ID = "BOUNDARIES_SOURCE_ID";

export const getCommonSources = (selectedAreaGeometry: Geometry) => {
  return [
    {
      id: BOUNDARIES_SOURCE_ID,
      source: {
        type: "geojson",
        data: selectedAreaGeometry,
      } as AnySourceData,
    },
  ];
};

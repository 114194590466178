export const datasetWarnings = [
  {
    id: "W1",
    warning: "No gates defined",
    resolution: "Check if gates were intentionally omitted.",
  },
  {
    id: "W2",
    warning: "Gate marker point is far from subarea boundary",
    resolution:
      "This may happen when updating the subarea polygon after gates were already defined. Revise if the gate needs to be updated or replaced. Note that gates far from the subarea may result in gaps in the exported road network (when gate segments are not connected to the segments that intersect the subarea).",
  },
  {
    id: "W3",
    warning: "Gate marker is far from the nearest segment in that gate",
    resolution:
      "This may result from adding distant segments to a selected gate. Revise if the segments should all be part of the same gate. Maybe multiple gates should be defined, or some segments should be removed from the gate.",
  },
  {
    id: "W4",
    warning: "Segments within gate are too far from each other",
    resolution:
      "Similar to the above, revise if the segments should be part of the same gate, and split the gate or remove segments if needed.",
  },
];

import { useMemoryStore } from "api/MemoryStoreContext";
import { MemoryStoreKeys } from "api/memoryStore";
import { Switch } from "components_new";
import { isEqual } from "lodash";
import React, { FC, MutableRefObject, useEffect, useMemo, useState } from "react";

import { BaseMapStyles } from "features/filters/BaseMapStyles";
import { RangeFilter } from "features/filters/RangeFilter";
import { isInAvailableRange } from "features/filters/utils";
import { ModuleData } from "features/map/ModuleManager";

import { RoadClassSelector, SelectorRoadClasses } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";
import { mapActions } from "store/sections/map";

import { MeasureRange, RoadClassCategory } from "types";

import { getFromToSegmentIndex, getReverseSegmentIndex } from "utils/parse";

interface RoadsMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  roadsModuleData: ModuleData;
}

export const RoadsMapLayers: FC<RoadsMapLayersProps> = ({ map, roadsModuleData }) => {
  const dispatch = useAppDispatch();
  const memoryStore = useMemoryStore();

  const { filterRoadSegmentsByRange, filterRoadSegmentsByRoadClasses, changeShowRoadVolumes } = roadsModuleData.data;

  const [roadClasses, setRoadClasses] = useState<SelectorRoadClasses | null>(null);

  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIndexes = useAppSelector((state) => state.analytics.roadSegmentIndexes);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);
  const savedRoadClasses = useAppSelector((state) => state.filters.roadClasses);

  const availableRange = useAppSelector((state) => state.filters.roadAvailableRange.data);
  const availableRangeState = useAppSelector((state) => state.filters.roadAvailableRange.state);

  const roadsRange = useAppSelector((state) => state.filters.roadRange);

  const range = useMemo<[number, number]>(
    () => (roadsRange ? roadsRange : [availableRange?.min || 0, availableRange?.max || 0]),
    [roadsRange, availableRange],
  );

  const loading =
    roadsVolumes.state === DataState.LOADING ||
    roadsMetadata.state === DataState.LOADING ||
    roadSegmentIndexes.state === DataState.LOADING ||
    availableRangeState === DataState.LOADING;

  const activeRoadClasses = useMemo(
    () =>
      Object.values(roadClasses?.items || {})
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => Number(value)),
    [roadClasses],
  );
  const previousActiveRoadClasses = usePrevious(activeRoadClasses);

  useEffect(() => {
    if (
      previousActiveRoadClasses &&
      !isEqual(previousActiveRoadClasses, activeRoadClasses) &&
      !isEqual(activeRoadClasses, savedRoadClasses)
    ) {
      dispatch(filtersActions.setRoadClasses(activeRoadClasses));
      filterRoadSegmentsByRoadClasses?.(activeRoadClasses);
    }
  }, [activeRoadClasses, previousActiveRoadClasses, filterRoadSegmentsByRoadClasses, savedRoadClasses, dispatch]);

  const handleChangeShowRoadVolumes = () => {
    dispatch(mapActions.setShowRoadVolumes(!showRoadVolumes));
    changeShowRoadVolumes(!showRoadVolumes);
  };

  const handleFilterRoadSegmentsByRange = () => {
    if (roadsVolumes.data && roadsMetadata.data && roadSegmentIndexes.data && availableRange && range) {
      if (range[0] >= availableRange.min || range[1] <= availableRange.max) {
        const allSegmentFromToIndexes = new Set();
        const limitedAccessFromToSegmentIndexes = new Set();
        const restAccessFromToSegmentIndexes = new Set();

        const [minRange, maxRange] = range;
        const segmentVolumesData = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_VOLUMES) as Map<number, number>;
        const allLimitedAccessFromToSegmentIndexes = new Set(
          memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES_BY_ROAD_CLASS)[
            RoadClassCategory.LIMITED_ACCESS
          ],
        );
        const allAccessSegmentFromToIndexes = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_FROM_TO_INDEXES);

        if (segmentVolumesData) {
          segmentVolumesData.forEach((volume, volumeSegmentIdx) => {
            const reverseSegmentIdx = getReverseSegmentIndex(volumeSegmentIdx);
            const reverseVolume = segmentVolumesData.get(reverseSegmentIdx) || null;

            if (
              !allAccessSegmentFromToIndexes.has(volumeSegmentIdx) &&
              !allAccessSegmentFromToIndexes.has(reverseSegmentIdx)
            ) {
              return;
            }

            if (
              (volume >= minRange && volume <= maxRange) ||
              (reverseVolume && reverseVolume >= minRange && reverseVolume <= maxRange)
            ) {
              const fromToSegmentIndex = getFromToSegmentIndex(volumeSegmentIdx);

              allSegmentFromToIndexes.add(fromToSegmentIndex);

              if (allLimitedAccessFromToSegmentIndexes.has(fromToSegmentIndex)) {
                limitedAccessFromToSegmentIndexes.add(fromToSegmentIndex);
              } else {
                restAccessFromToSegmentIndexes.add(fromToSegmentIndex);
              }
            }
          });

          filterRoadSegmentsByRange(
            Array.from(allSegmentFromToIndexes.keys()).sort(),
            Array.from(limitedAccessFromToSegmentIndexes.keys()).sort(),
            Array.from(restAccessFromToSegmentIndexes.keys()).sort(),
          );
        }
      } else {
        filterRoadSegmentsByRange(null);
      }
    }
  };

  const handleSetRoadSegmentsRange = (newRange: [number, number], availableRange: MeasureRange) => {
    dispatch(filtersActions.setRoadsRange(isInAvailableRange(newRange, availableRange) ? null : newRange));
  };

  return (
    <>
      <Switch
        label="Segment Volumes"
        checked={showRoadVolumes}
        onChange={handleChangeShowRoadVolumes}
        disabled={loading}
        sx={{ marginBottom: 2 }}
      />
      <RoadClassSelector
        loading={loading}
        roadClasses={roadClasses}
        savedRoadClasses={savedRoadClasses}
        setRoadClasses={setRoadClasses}
      />

      {!roadsModuleData.data.isSelectLinkResults ? (
        <RangeFilter
          label="Volumes"
          range={range}
          availableRange={availableRange || { min: 0, max: 0 }}
          loading={loading}
          filterByRange={handleFilterRoadSegmentsByRange}
          setRange={handleSetRoadSegmentsRange}
        />
      ) : null}
      <BaseMapStyles map={map.current} disabled={roadsModuleData.data.isSelectLinkResults} sx={{ marginTop: 2 }} />
    </>
  );
};

import { DimensionListWithCodes } from "./DimensionListWithCodes";
import { OdCsvCommonColumnsRows } from "./OdCsvCommonColumns";

const tripTypes: Record<string, string> = {
  "I-I": "Internal/Internal (from internal zone to internal zone)",
  "I-X": "Internal/External (from internal zone to gate)",
  "X-I": "External/Internal (from gate to internal zone)",
  "X-X": "External/External (between gates)",
};

const zoneIdColumns = [
  {
    column: "start_id",
    type: "Text",
    description: <div> Zone ID of the flow start (zone or gate)</div>,
    notes: "",
  },
  {
    column: "start_idseq",
    type: "Integer",
    description: (
      <div>
        {" "}
        Renumbered ID of the flow start (zone or gate), mapped into 1-32,000 range as Bentley CUBE allows maximum value
        for OD zone IDs of 32,000{" "}
      </div>
    ),
    notes: "",
  },
  {
    column: "end_id",
    type: "Text",

    description: <div> Zone ID of the flow end (zone or gate)</div>,
    notes: "",
  },
  {
    column: "end_idseq",
    type: "Integer",
    description: (
      <div>
        {" "}
        Renumbered ID of the flow end(zone or gate), mapped into 1-32,000 range as Bentley CUBE allows maximum value for
        OD zone IDs of 32,000{" "}
      </div>
    ),
    notes: "",
  },
];

const additionalColumns = [
  {
    column: "trip_type",
    type: "Text",
    description: (
      <div>
        Sub-area traversal type for the trips included in the flow:
        <DimensionListWithCodes elements={tripTypes} />
      </div>
    ),
    notes: "",
  },
  {
    column: "starts_at_gate",
    type: "Text",
    description: <div>If the flow starts at a gate (Yes/No)</div>,
    notes: "",
  },
  {
    column: "ends_at_gate",
    type: "Text",
    description: <div> If the flow ends at a gate (Yes/No) </div>,
    notes: "",
  },
  {
    column: "count",
    type: "Double",
    description: <div> Vehicle count </div>,
    notes: "",
  },
];

export const ODVolumesFileColumns = zoneIdColumns.concat(OdCsvCommonColumnsRows, additionalColumns);

import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, FC, FocusEvent, useEffect, useState } from "react";

export type NumberTextFiedProps = {
  min?: number;
  max?: number;
  step?: number;
  value: string;
} & Omit<TextFieldProps, "type" | "value">;

const normalizeValue = (value: string, min: number, max: number) => {
  if (!value || +value < min) return min.toString();
  if (+value > max) return max.toString();

  return value.replace(/\.$/, "");
};

export const NumberTextFied: FC<NumberTextFiedProps> = ({
  min = 0,
  max = Infinity,
  step,
  helperText,
  value,
  onChange,
  onBlur,
  ...props
}) => {
  const [rangeHelperText, setRangeHelperText] = useState(" ");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const reg = /^[0-9]*\.?[0-9]*$/;

    if (reg.test(value) && onChange) {
      onChange(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: normalizeValue(value, min, max),
        },
      });
    }
    if (onBlur) onBlur(e);
  };

  useEffect(() => {
    if (+value) {
      if (+value < min) return setRangeHelperText(`Minimum is ${min}`);

      if (+value > max) return setRangeHelperText(`Maximum is ${max}`);
    }

    setRangeHelperText(" ");
  }, [value, min, max]);

  return (
    <TextField
      type="text"
      value={value}
      helperText={rangeHelperText ? rangeHelperText : helperText}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{
        inputMode: "numeric",
      }}
      {...props}
    />
  );
};

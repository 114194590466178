import { TextField as MuiTextField, OutlinedTextFieldProps as MuiTextFieldProps, styled } from "@mui/material";
import * as React from "react";

export interface TextFieldProps extends Omit<MuiTextFieldProps, "variant"> {
  // Add your custom props here
}

const StyledTextField = styled((props: TextFieldProps) => <MuiTextField {...props} />)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    fontWeight: 500,
    fontSize: 15,
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#fff",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: 14,
  },
}));

export const TextField: React.FC<TextFieldProps> = ({ size = "small", color = "secondary", ...props }) => {
  return <StyledTextField margin="dense" size={size} color={color} InputLabelProps={{ shrink: true }} {...props} />;
};

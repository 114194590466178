import { Map } from "mapbox-gl";

import { GateCandidate, Gate } from "types";

const defaultLayers = ["admin_country", "road-label"];

export enum EditorGateCandidates {
  SourceId = "candidates",
  LayerId = "segments-candidates",
}

interface EditorGateCandidatesData {
  candidates: GateCandidate[];
  generatedGates: Gate[];
}

export const initEditorGateCandidates = (map: Map, { candidates, generatedGates }: EditorGateCandidatesData) => {
  const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));

  map.addSource(EditorGateCandidates.SourceId, {
    type: "geojson",
    promoteId: "fromToSegId",
    data: {
      type: "FeatureCollection",
      features: candidates.map((gate) => ({
        type: "Feature",
        properties: {
          fromToSegId: gate.fromToSegId,
          toFromSegId: gate.toFromSegId,
          roadClass: gate.roadClass,
        },
        geometry: gate.geometry,
      })),
    },
  });

  map.addLayer(
    {
      id: EditorGateCandidates.LayerId,
      type: "line",
      source: EditorGateCandidates.SourceId,
      paint: {
        "line-color": "#F43F5E",
        "line-opacity": 0.6,
        "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 2, 10, 6, 13, 8, 16, 9, 20, 18],
      },
      layout: {
        "line-cap": "round",
      },
      filter: [
        "match",
        ["get", "fromToSegId"],
        ["in", ...generatedGates.map((gate) => gate.segments.map((s) => s.id)).flat(1)],
        false,
        true,
      ],
    },
    ...existingDefaultLayers,
  );

  return {
    clear: () => {
      if (map.getLayer(EditorGateCandidates.LayerId)) {
        map.removeLayer(EditorGateCandidates.LayerId);
      }

      if (map.getSource(EditorGateCandidates.SourceId)) {
        map.removeSource(EditorGateCandidates.SourceId);
      }
    },
  };
};

import { Api } from "api";
import { produce, setAutoFreeze, setUseStrictShallowCopy } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, select, takeLatest } from "redux-saga/effects";

import { SelectLinkAnalysisMode } from "components/pages/analytics/select-link/types";
import { getSegmentsFromToIds, toSelectLinkConfigEssentials } from "components/pages/analytics/select-link/utils";

import {
  MeasureRange,
  RoadsVolumes,
  SegmentIndexesForIdsMapSource,
  SelectLinkConfig,
  SelectLinkConfigCreationRequest,
  SelectLinkConfigEssentials,
  SelectLinkConfigUpdateRequest,
  SelectLinkConfigs,
  SelectLinkSegmentCountsRequest,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { Action, ActionsUnion, createAction } from "../actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "../interfaces";
import { AnalyticsActionType, GlobalActionType, SelectLinkActionType } from "./actionTypes";

export interface SelectLinkState {
  configs: LoadingErrorData<SelectLinkConfig[]>;
  selectLinkSegmentCounts: LoadingErrorData<RoadsVolumes>;
  selectLinkAvailableRange: MeasureRange | null;

  // current state of the config as user edits it, for rendering map features
  currentSelectLinkConfig: SelectLinkConfigEssentials | null;

  // config after last "Run Analysis" button click (for export and rendering select link results map features)
  resultsSelectLinkConfig: SelectLinkConfigEssentials | null;

  // config stored in the BE, on load or after the last click on "Save" button (for revert and persistence)
  savedSelectLinkConfig: LoadingErrorData<SelectLinkConfig>;

  newConfigId: string | null;
  errorMessage: string | null;
  errorDialogMessage: string | null;
  loading: boolean;
  isUpdated: boolean;
  isSelectLinkExportDisabled: boolean;
  selectLinkAnalysisMode: SelectLinkAnalysisMode;
}

export type SelectLinkAction = ActionsUnion<typeof selectLinkActions>;

export const selectLinkActions = {
  listSelectLinkConfigs: () => createAction(SelectLinkActionType.LIST_SELECT_LINK_CONFIGS),
  listSelectLinkConfigsSucceeded: (configs: SelectLinkConfigs) =>
    createAction(SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_SUCCEEDED, configs),
  listSelectLinkConfigsFailed: (error: ResponseError) =>
    createAction(SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_FAILED, error),

  createSelectLinkConfig: (request: SelectLinkConfigCreationRequest) =>
    createAction(SelectLinkActionType.CREATE_SELECT_LINK_CONFIG, request),
  createSelectLinkConfigSucceeded: (config: SelectLinkConfig) =>
    createAction(SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_SUCCEEDED, {
      config,
    }),
  createSelectLinkConfigFailed: (errorMessage: string) =>
    createAction(SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_FAILED, { errorMessage }),

  fetchSelectLinkConfig: (configId: string) => createAction(SelectLinkActionType.FETCH_SELECT_LINK_CONFIG, configId),
  fetchSelectLinkConfigSucceeded: (config: SelectLinkConfig) =>
    createAction(SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_SUCCEEDED, { config }),
  fetchSelectLinkConfigFailed: (errorMessage: string) =>
    createAction(SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_FAILED, { errorMessage }),

  updateSelectLinkConfig: (configId: string, request: SelectLinkConfigUpdateRequest) =>
    createAction(SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG, { configId, request }),
  updateSelectLinkConfigSucceeded: (config: SelectLinkConfig) =>
    createAction(SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_SUCCEEDED, { config }),
  updateSelectLinkConfigFailed: (errorMessage: string) =>
    createAction(SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_FAILED, { errorMessage }),

  deleteSelectLinkConfig: (configId: string) => createAction(SelectLinkActionType.DELETE_SELECT_LINK_CONFIG, configId),
  deleteSelectLinkConfigSucceeded: (config: SelectLinkConfig) =>
    createAction(SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_SUCCEED, config),
  deleteSelectLinkConfigFailed: (errorMessage: string) =>
    createAction(SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_FAILED, { errorMessage }),

  clearNewSelectLinkConfig: () => createAction(SelectLinkActionType.CLEAR_NEW_SELECT_LINK_CONFIG),
  clearSelectLinkConfig: () => createAction(SelectLinkActionType.CLEAR_SELECT_LINK_CONFIG_DETAILS),
  clearErrorMessages: () => createAction(SelectLinkActionType.CLEAR_ERROR_MESSAGES),

  updateCurrentSelectLinkConfig: (update: SelectLinkConfigEssentials) =>
    createAction(SelectLinkActionType.UPDATE_CURRENT_SELECT_LINK_CONFIG, { update }),

  updateResultsSelectLinkConfig: (update: SelectLinkConfigEssentials) =>
    createAction(SelectLinkActionType.UPDATE_RESULTS_SELECT_LINK_CONFIG, { update }),

  // Select Link Analysis Actions
  fetchSelectLinkSegmentCounts: (config: SelectLinkSegmentCountsRequest) =>
    createAction(SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS, config),
  fetchSelectLinkSegmentCountsSucceeded: (segmentCounts: RoadsVolumes) =>
    createAction(SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_SUCCEEDED, {
      segmentCounts,
    }),
  fetchSelectLinkSegmentCountsFailed: (error: ResponseError) =>
    createAction(SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_FAILED, error),

  clearSelectLinkSegmentCounts: () => createAction(SelectLinkActionType.CLEAR_SELECT_LINK_SEGMENT_COUNTS),
  setSelectLinkAvailableRange: (availableRange: MeasureRange | null) =>
    createAction(SelectLinkActionType.SET_AVAILABLE_RANGE, availableRange),
  setSelectLinkAnalysisMode: (mode: SelectLinkAnalysisMode) =>
    createAction(SelectLinkActionType.SET_SELECT_LINK_ANALYSIS_MODE, mode),
  setSelectLinkExportDisabled: (isDisabled: boolean) =>
    createAction(SelectLinkActionType.SET_SELECT_LINK_EXPORT_DISABLED, isDisabled),
};

const initialState: SelectLinkState = {
  configs: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  selectLinkSegmentCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  selectLinkAvailableRange: null,
  currentSelectLinkConfig: null,
  resultsSelectLinkConfig: null,
  savedSelectLinkConfig: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  newConfigId: null,
  errorMessage: null,
  errorDialogMessage: null,
  loading: false,
  isUpdated: false,
  isSelectLinkExportDisabled: true,
  selectLinkAnalysisMode: SelectLinkAnalysisMode.Configuration,
};

const reducer: Reducer<SelectLinkState, SelectLinkAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SelectLinkActionType.LIST_SELECT_LINK_CONFIGS: {
        draft.configs.state = DataState.LOADING;
        draft.configs.error = null;
        draft.loading = true;

        return;
      }
      case SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_SUCCEEDED: {
        draft.configs = {
          state: DataState.AVAILABLE,
          data: action.payload.configs,
          error: null,
        };
        draft.loading = false;

        return;
      }
      case SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_FAILED: {
        draft.configs.state = DataState.ERROR;
        draft.configs.error = action.payload;
        draft.loading = false;

        return;
      }
      case SelectLinkActionType.CREATE_SELECT_LINK_CONFIG: {
        draft.savedSelectLinkConfig.state = DataState.LOADING;
        draft.savedSelectLinkConfig.error = null;
        draft.loading = true;
        draft.errorMessage = null;

        return;
      }
      case SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_SUCCEEDED: {
        draft.savedSelectLinkConfig = {
          state: DataState.AVAILABLE,
          data: action.payload.config,
          error: null,
        };
        draft.loading = false;
        draft.newConfigId = action.payload.config.configId;

        return;
      }
      case SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_FAILED:
      case SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_FAILED:
        draft.savedSelectLinkConfig.state = DataState.ERROR;
        draft.loading = false;
        draft.isUpdated = false;
        draft.errorMessage = action.payload.errorMessage;

        return;
      case SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_FAILED: {
        draft.savedSelectLinkConfig.state = DataState.ERROR;
        draft.loading = false;
        draft.errorDialogMessage = action.payload.errorMessage;

        return;
      }
      case SelectLinkActionType.CLEAR_NEW_SELECT_LINK_CONFIG: {
        draft.newConfigId = null;
        return;
      }
      case SelectLinkActionType.CLEAR_SELECT_LINK_CONFIG_DETAILS: {
        draft.resultsSelectLinkConfig = initialState.resultsSelectLinkConfig;
        draft.savedSelectLinkConfig = initialState.savedSelectLinkConfig;
        return;
      }
      case SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS: {
        draft.selectLinkSegmentCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };

        return;
      }
      case SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_SUCCEEDED: {
        setAutoFreeze(false);
        setUseStrictShallowCopy(false);
        draft.selectLinkSegmentCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.segmentCounts,
          error: null,
        };
        return;
      }
      case SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_FAILED: {
        draft.selectLinkSegmentCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };

        return;
      }
      case SelectLinkActionType.CLEAR_SELECT_LINK_SEGMENT_COUNTS: {
        draft.selectLinkSegmentCounts = {
          state: DataState.EMPTY,
          data: null,
          error: null,
        };

        return;
      }
      case SelectLinkActionType.FETCH_SELECT_LINK_CONFIG: {
        draft.savedSelectLinkConfig.state = DataState.LOADING;
        draft.loading = true;

        return;
      }
      case SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG: {
        draft.savedSelectLinkConfig.state = DataState.LOADING;
        draft.loading = true;
        draft.isUpdated = false;

        return;
      }
      case SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_SUCCEEDED:
      case SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_SUCCEEDED: {
        draft.currentSelectLinkConfig = toSelectLinkConfigEssentials(action.payload.config);
        draft.savedSelectLinkConfig = {
          state: DataState.AVAILABLE,
          data: action.payload.config,
          error: null,
        };
        draft.loading = false;
        draft.isUpdated = true;

        return;
      }
      case SelectLinkActionType.CLEAR_ERROR_MESSAGES: {
        draft.errorMessage = null;
        draft.errorDialogMessage = null;

        return;
      }
      case SelectLinkActionType.SET_AVAILABLE_RANGE: {
        draft.selectLinkAvailableRange = action.payload;

        return;
      }
      case SelectLinkActionType.SET_SELECT_LINK_ANALYSIS_MODE: {
        draft.selectLinkAnalysisMode = action.payload;

        return;
      }
      case SelectLinkActionType.SET_SELECT_LINK_EXPORT_DISABLED: {
        draft.isSelectLinkExportDisabled = action.payload;

        return;
      }
      case SelectLinkActionType.DELETE_SELECT_LINK_CONFIG: {
        draft.savedSelectLinkConfig.state = DataState.LOADING;

        return;
      }
      case SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_SUCCEED: {
        draft.savedSelectLinkConfig.state = DataState.AVAILABLE;
        draft.savedSelectLinkConfig.data = null;

        return;
      }
      case SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_FAILED: {
        draft.savedSelectLinkConfig.state = DataState.ERROR;
        draft.errorMessage = action.payload.errorMessage;

        return;
      }
      case SelectLinkActionType.UPDATE_CURRENT_SELECT_LINK_CONFIG: {
        draft.currentSelectLinkConfig = action.payload.update;

        return;
      }
      case SelectLinkActionType.UPDATE_RESULTS_SELECT_LINK_CONFIG: {
        draft.resultsSelectLinkConfig = action.payload.update;

        return;
      }
      default:
        return state;
    }
  });

export default reducer;

function* listSelectLinkConfigs(): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { listSelectLinkConfigs },
    } = api as Api;
    const configs = yield call(listSelectLinkConfigs);

    yield put({
      type: SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_SUCCEEDED,
      payload: { configs },
    });
  } catch (error) {
    reportAboutErrorState(error, SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_FAILED);

    yield put({
      type: SelectLinkActionType.LIST_SELECT_LINK_CONFIGS_FAILED,
      payload: error,
    });
  }
}

function* createSelectLinkConfig(action: Action<string, SelectLinkConfigCreationRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { createSelectLinkConfig },
    } = api as Api;
    const config = yield call(createSelectLinkConfig, action.payload);

    yield put({
      type: SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_SUCCEEDED,
      payload: { config },
    });
  } catch (error: any) {
    reportAboutErrorState(error, SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_FAILED);

    const errorMessage = error?.body?.what || "";
    yield put({
      type: SelectLinkActionType.CREATE_SELECT_LINK_CONFIG_FAILED,
      payload: { errorMessage },
    });
  }
}

function* fetchSelectLinkConfig(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchSelectLinkConfig },
    } = api as Api;
    const config: any = yield call(fetchSelectLinkConfig, action.payload);
    const selectedFocusAreaId: any = yield select((state) => state.global.selectedFocusAreaId);

    if (!selectedFocusAreaId) {
      yield put({
        type: GlobalActionType.SET_SELECTED_FOCUS_AREA_ID,
        payload: { focusAreaId: config.licensedAreaId.toString(), reset: false },
      });
    }

    const segmentIds = getSegmentsFromToIds((config as SelectLinkConfig).segmentsGroups, true) as string[];

    if (segmentIds) {
      yield put({
        type: AnalyticsActionType.FETCH_SEGMENT_INDEXES_FOR_IDS,
        payload: {
          config: { segmentIds, timePeriod: config.timePeriod },
          source: SegmentIndexesForIdsMapSource.SELECT_LINK,
        },
      });
    }

    yield put({
      type: SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_SUCCEEDED,
      payload: { config },
    });
  } catch (error: any) {
    reportAboutErrorState(error, SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_FAILED);

    const errorMessage = error?.body?.what || "";
    yield put({
      type: SelectLinkActionType.FETCH_SELECT_LINK_CONFIG_FAILED,
      payload: { errorMessage },
    });
  }
}

function* updateSelectLinkConfig(
  action: Action<string, { configId: string; request: SelectLinkConfigUpdateRequest }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { updateSelectLinkConfig },
    } = api as Api;
    const { configId, request } = action.payload;
    const config = yield call(updateSelectLinkConfig, configId, request);

    yield put({
      type: SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_SUCCEEDED,
      payload: { config },
    });
  } catch (error: any) {
    reportAboutErrorState(error, SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_FAILED);

    const errorMessage = error?.body?.what || "Something went wrong. Please try again.";
    yield put({
      type: SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG_FAILED,
      payload: { errorMessage },
    });

    yield put({
      type: GlobalActionType.SET_TOAST_MESSAGE,
      payload: {
        content: errorMessage,
        severity: "error",
      },
    });
  }
}

function* deleteSelectLinkConfig(action: Action<string, string>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { deleteSelectLinkConfig },
    } = api as Api;
    const config = yield call(deleteSelectLinkConfig, action.payload);

    yield put({
      type: SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_SUCCEED,
      payload: { config },
    });
  } catch (error: any) {
    reportAboutErrorState(error, SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_FAILED);

    const errorMessage = error?.body?.what || "";
    yield put({
      type: SelectLinkActionType.DELETE_SELECT_LINK_CONFIG_FAILED,
      payload: { errorMessage },
    });
  }
}

function* fetchSelectLinkSegmentCounts(action: Action<string, SelectLinkSegmentCountsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getSelectLinkSegmentCounts },
    } = api as Api;
    const segmentCounts: any = yield call(getSelectLinkSegmentCounts, action.payload);
    yield put({
      type: SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_SUCCEEDED,
      payload: { segmentCounts },
    });
    yield put({
      type: SelectLinkActionType.SET_AVAILABLE_RANGE,
      payload: { min: action.payload.countsFilter.min, max: segmentCounts.maxVolume },
    });
  } catch (e: any) {
    reportAboutErrorState(e, SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_FAILED);

    yield put({
      type: SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS_FAILED,
      payload: {
        message: e.message || e,
      },
    });
  }
}

export function* selectLinkSaga() {
  yield all([
    takeLatest(SelectLinkActionType.LIST_SELECT_LINK_CONFIGS, listSelectLinkConfigs),
    takeLatest(SelectLinkActionType.CREATE_SELECT_LINK_CONFIG, createSelectLinkConfig),
    takeLatest(SelectLinkActionType.FETCH_SELECT_LINK_CONFIG, fetchSelectLinkConfig),
    takeLatest(SelectLinkActionType.UPDATE_SELECT_LINK_CONFIG, updateSelectLinkConfig),
    takeLatest(SelectLinkActionType.DELETE_SELECT_LINK_CONFIG, deleteSelectLinkConfig),
    takeLatest(SelectLinkActionType.FETCH_SELECT_LINK_SEGMENT_COUNTS, fetchSelectLinkSegmentCounts),
  ]);
}

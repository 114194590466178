import { FilterArguments, FiltersType, Measure, MeasureRange, MeasureType } from "types";

export const buildFilters = (filters: FiltersType | null): FilterArguments => {
  const filter: Record<string, string[]> = {};

  if (filters) {
    for (let [filterKey, filterValues] of Object.entries(filters)) {
      if (!filterValues.isChecked) {
        filter[filterKey] = Object.entries(filterValues.items)
          .filter(([, itemValue]) => itemValue.isChecked)
          .map(([, itemValue]) => itemValue.value);
      }
    }
  }

  return filter;
};

export const isInAvailableRange = (range: [number, number], availableRange: MeasureRange) =>
  availableRange.min === range[0] && availableRange.max === range[1];

export const getCurrentMeasure = <T extends Measure>(measures: T[] | undefined, measure: MeasureType): T | null =>
  measures?.find((m) => m.columnName === measure) ?? null;

import { Alert as MuiAlert, AlertProps as MuiAlertProps, styled } from "@mui/material";
import * as React from "react";

export interface AlertProps extends MuiAlertProps {
  // Add your custom props here
}

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  padding: "0 12px",

  "& .MuiAlert-action": {
    margin: "auto 0 auto auto",
  },
}));

export const Alert: React.FC<AlertProps> = ({ ...props }) => {
  return <StyledAlert {...props} />;
};

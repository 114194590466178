import { styled } from "@mui/material";
import { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";

import { AnalyticMap } from "features/corridor-discovery/AnalyticMap";
import { EdgeAnalyticsPanel } from "features/corridor-discovery/EdgeAnalyticsPanel";
import { EdgePopupContent, EdgePopupProps } from "features/corridor-discovery/EdgePopupContent";
import { LayersLegend } from "features/corridor-discovery/LayersLegend";
import { LoadingSpinnerWrapper } from "features/corridor-discovery/LoadingSpinnerWrapper";
import { MapControlsPanel } from "features/corridor-discovery/MapControlPanel";
import { MapController } from "features/corridor-discovery/MapController";
import { OverlayPopupContent } from "features/corridor-discovery/OverlayPopupContent";

import { PopupWrapper } from "components";

import { usePageTracking } from "hooks";

const MapPageContainer = styled("div")`
  position: relative;
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 100%;
  grid-template-areas: "left-sidebar map";
  height: 100%;
  overflow: hidden;
`;

export const CorridorDiscoveryPage = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  const map = useRef<Map | null>(null);
  const mapController = useRef(null);

  const edgePopupRef = useRef<HTMLDivElement>(null);
  const setEdgePopupRef = useRef<Dispatch<SetStateAction<EdgePopupProps | null>>>(null);

  const overlayPopupRef = useRef<HTMLDivElement>(null);
  const setOverlayPopupRef = useRef<Dispatch<SetStateAction<MapboxGeoJSONFeature[] | null>>>(null);
  const overlayLayerIds = useRef<string[]>([]);

  const closeEdgeAnalyticsPanelRef = useRef<() => void>(null);

  usePageTracking();

  return (
    <MapPageContainer>
      <MapControlsPanel mapController={mapController} map={map.current} />
      <MapController
        map={map}
        mapController={mapController}
        mapLoaded={mapLoaded}
        edgePopupRef={edgePopupRef}
        setEdgePopupRef={setEdgePopupRef}
        closeEdgeAnalyticsPanelRef={closeEdgeAnalyticsPanelRef}
        overlayPopupRef={overlayPopupRef}
        setOverlayPopupRef={setOverlayPopupRef}
        overlayLayerIds={overlayLayerIds}
      />

      <AnalyticMap map={map} onMapLoaded={setMapLoaded}>
        <PopupWrapper
          popupRef={edgePopupRef}
          setPopupRef={setEdgePopupRef}
          renderPopupContent={({ id, feature, fromToCounts, toFromCounts }) => (
            <EdgePopupContent id={id} feature={feature} fromToCounts={fromToCounts} toFromCounts={toFromCounts} />
          )}
        />
        <PopupWrapper
          popupRef={overlayPopupRef}
          setPopupRef={setOverlayPopupRef}
          renderPopupContent={(features) => <OverlayPopupContent map={map} features={features} />}
        />
      </AnalyticMap>
      <LayersLegend
        mapLoaded={mapLoaded}
        mapController={mapController}
        map={map.current}
        overlayLayerIds={overlayLayerIds}
      />
      <EdgeAnalyticsPanel closeEdgeAnalyticsPanelRef={closeEdgeAnalyticsPanelRef} />
      <LoadingSpinnerWrapper />
    </MapPageContainer>
  );
};

import React, { useMemo } from "react";

import { SpinnerOverlay } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

export const RoadVmtLoadingSpinnerWrapper = () => {
  const roadVmtMetadataState = useAppSelector((state) => state.roadVmt.roadVmtMetadata.state);
  const zoneCountsState = useAppSelector((state) => state.roadVmt.roadVmtZoneCounts.state);

  const isLoading = useMemo(() => {
    return roadVmtMetadataState === DataState.LOADING || zoneCountsState === DataState.LOADING;
  }, [roadVmtMetadataState, zoneCountsState]);

  return <>{isLoading ? <SpinnerOverlay /> : null}</>;
};

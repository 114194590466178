import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from "@mui/material";
import * as React from "react";

//Excluding unused props
type MenuItemBaseProps = Omit<
  MuiMenuItemProps,
  | "centerRipple"
  | "disableFocusRipple"
  | "disableElevation"
  | "disableRipple"
  | "disableTouchRipple"
  | "focusRipple"
  | "TouchRippleProps"
  | "touchRippleRef"
>;

export interface MenuItemProps extends MenuItemBaseProps {
  // Add your custom props here
}

export const MenuItem: React.FC<MenuItemProps> = ({ ...props }) => {
  return <MuiMenuItem {...props} />;
};

import { Box, Popover as MuiPopover, PopoverProps as MuiPopoverProps } from "@mui/material";
import * as React from "react";

export interface PopoverProps extends Omit<MuiPopoverProps, "open"> {
  control: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
    handleClose: () => void,
    open: boolean,
  ) => JSX.Element;
  children: React.ReactNode;
  hover?: boolean;
}

export const Popover: React.FC<PopoverProps> = ({ control, children, sx, hover, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "popover" : undefined;

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      {control(handleOpen, handleClose, open)}
      <MuiPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ pointerEvents: hover ? "none" : "auto" }}
        disableRestoreFocus={hover ? true : false}
        {...props}
      >
        {children}
      </MuiPopover>
    </Box>
  );
};

import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  WarningAmberRounded,
} from "@mui/icons-material";
import { Icon, IconProps } from "@mui/material";
import { FC } from "react";

import { ValidationMessageSeverity } from "types";

export interface SeverityIconProps extends IconProps {
  severity: ValidationMessageSeverity | undefined;
}

export const SeverityIcon: FC<SeverityIconProps> = ({ severity, ...props }) => {
  const getIconProps = () => {
    switch (severity) {
      case ValidationMessageSeverity.Error:
        return { component: ErrorOutlineOutlined, color: "error" };
      case ValidationMessageSeverity.Warning:
        return { component: WarningAmberRounded, color: "warning" };
      case ValidationMessageSeverity.Info:
        return { component: InfoOutlined, color: "info" };
      default:
        return { component: CheckCircleOutlineOutlined, color: "success" };
    }
  };

  const { component, color } = getIconProps();

  return <Icon component={component} color={color as any} {...props} />;
};

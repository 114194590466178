import { timeFormat } from "d3-time-format";

export const formatCreatedTime = (date: Date | null) => {
  return date ? timeFormat("%m-%d-%Y %I:%M%p")(date) : "NA";
};

export const formatCreatedTimeShort = (date: Date | null) => {
  return date ? timeFormat("%m-%d-%Y")(date) : "NA";
};

export const capitalize = (str: string | undefined) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertKmToMiles = (km: number) => {
  return km * 0.621371;
};

export const convertMetersToMiles = (meters: number) => {
  return meters * 0.000621371;
};

export const convertSquareKmToSquareMiles = (km: number) => {
  return km * 0.386102;
};

export const convertSquareMetersToSquareMiles = (meters: number) => {
  return meters * 0.000000386102;
};

export const convertMetersToFeet = (meters: number) => {
  return meters * 3.2808399;
};

export const convertFeetToMeters = (feet: number) => {
  return feet * 0.3048;
};

import { HighlightAlt, WarningAmberOutlined } from "@mui/icons-material";
import { Grid, ListItemIcon, ListItemText, SxProps } from "@mui/material";
import { MenuItem, TextField } from "components_new";
import React, { FC } from "react";

import { FocusAreaItem } from "types";

export interface AreaOfFocusSelectorProps {
  areas: FocusAreaItem[];
  selectedAreaId: string;
  disabled?: boolean;
  sx?: SxProps;
  onAreaChange: (areaId: string) => void;
}

export const AreaOfFocusSelector: FC<AreaOfFocusSelectorProps> = ({
  areas,
  selectedAreaId,
  disabled,
  sx,
  onAreaChange,
}) => {
  return (
    <TextField fullWidth select label="Area of interest" disabled={disabled} value={selectedAreaId} sx={sx}>
      {areas.map((area) => (
        <MenuItem key={area.id} value={area.id} onClick={() => onAreaChange(area.id)}>
          <Grid container alignItems={"center"} wrap="nowrap">
            <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>
              {area.enabled ? (
                <HighlightAlt fontSize="small" color={"secondary"} />
              ) : (
                <WarningAmberOutlined fontSize="small" color={"warning"} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={area.label}
              primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
              secondary={area.disabledReason}
              secondaryTypographyProps={{ color: "warning.main", fontSize: 10 }}
              sx={{ margin: 0 }}
            />
          </Grid>
        </MenuItem>
      ))}
    </TextField>
  );
};

import { FC } from "react";

import { ElevatedTable, FooterTableCell, HeadTableCell, TableBody, TableCell, TableHead, TableRow } from "./Table";

interface FileColumn {
  column: string;
  type: string;
  description: any;
  notes: string;
}

export interface FileTableProps {
  fileColumns: FileColumn[];
}

export const FileTable: FC<FileTableProps> = ({ fileColumns }) => {
  return (
    <ElevatedTable>
      <TableHead>
        <TableRow>
          <HeadTableCell>Column</HeadTableCell>
          <HeadTableCell>Type</HeadTableCell>
          <HeadTableCell>Description</HeadTableCell>
          <HeadTableCell>Notes</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fileColumns.map((f, i) => (
          <TableRow key={i}>
            <TableCell width={"150px"}>{f.column}</TableCell>
            <TableCell width={"150px"}>{f.type}</TableCell>
            <TableCell>{f.description}</TableCell>
            <TableCell width={"150px"}>{f.notes}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </ElevatedTable>
  );
};

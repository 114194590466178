import { createContext } from "react";

import AccountApi, { AccountApiType } from "./AccountApi";
import AnalyticsApi, { AnalyticsApiType } from "./AnalyticsApi";
import LicenseApi, { LicenseApiType } from "./LicenseApi";
import RestHandler from "./RestHandler";
import TileServiceApi, { TileServiceApiType } from "./TileServiceApi";

export interface Api {
  analyticsApi: AnalyticsApiType;
  licenseApi: LicenseApiType;
  accountApi: AccountApiType;
  tileServiceApi: TileServiceApiType;
}

export function makeApi(): Api {
  const analyticsHost = process.env.REACT_APP_ANALYTICS_API_HOST || process.env.REACT_APP_API_HOST;

  const analyticsRestHandler = new RestHandler(`${analyticsHost}/analytics`);
  const licenseRestHandler = new RestHandler(`${process.env.REACT_APP_API_HOST}/license`);
  const accountRestHandler = new RestHandler(`${process.env.REACT_APP_API_HOST}/account`);
  const tileServiceResHandler = new RestHandler();

  return {
    analyticsApi: AnalyticsApi(analyticsRestHandler),
    licenseApi: LicenseApi(licenseRestHandler),
    accountApi: AccountApi(accountRestHandler),
    tileServiceApi: TileServiceApi(tileServiceResHandler),
  };
}

export const ApiContext = createContext<Api | undefined>(undefined);

export enum DataState {
  EMPTY = "EMPTY",
  LOADING = "LOADING",
  ERROR = "ERROR",
  AVAILABLE = "AVAILABLE",
}

export interface ResponseError {
  status: number;
  body: {
    what?: string;
    msg?: string;
  };
}

export interface DataLoading<T> {
  state: DataState.LOADING;
  data: T | null;
  error: null;
}

export interface DataEmpty {
  state: DataState.EMPTY;
  data: null;
  error: null;
}

export interface DataError<T> {
  state: DataState.ERROR;
  error: ResponseError;
  data: T | null;
}

export interface DataAvailable<T> {
  state: DataState.AVAILABLE;
  data: T;
  refreshing?: boolean;
  error: null;
}

export type LoadingErrorData<TData> = DataEmpty | DataLoading<TData> | DataError<TData> | DataAvailable<TData>;

import {
  schemeBlues,
  schemeBuGn,
  schemeBuPu,
  schemeGnBu,
  schemeGreens,
  schemeOrRd,
  schemeOranges,
  schemePuBu,
  schemePuBuGn,
  schemePuRd,
  schemePurples,
  schemeRdPu,
  schemeReds,
  schemeYlGn,
  schemeYlGnBu,
  schemeYlOrBr,
  schemeYlOrRd,
} from "d3-scale-chromatic";

export type ColorScheme = Record<string, string[]>;

export const CHOROPLETH_FILL_COLOR = "#F9FBFF";
export const CHOROPLETH_FILL_OPACITY = 0.8;
export const CHOROPLETH_FILL_OPACITY_HOVER = 0.9;
export const CHOROPLETH_FILL_OPACITY_SELECTED = 1;
export const CHOROPLETH_LINE_COLOR = "#757575";
export const CHOROPLETH_LINE_OPACITY = 1;
export const COUNTY_BORDER_LINE_WIDTH = 2.4;
export const COUNTY_BORDER_LINE_OPACITY = 0.7;
export const COUNTY_BORDER_LINE_COLOR = "#a9a9a9";

export const ZONE_HOVER_HIGHLIGHT_COLOR = "#404040";
export const ZONE_PERMANENT_HIGHLIGHT_COLOR_ORIGIN = "#cc3700";
export const ZONE_PERMANENT_HIGHLIGHT_COLOR_DESTINATION = "#660066";
export const ZONE_PERMANENT_HIGHLIGHT_FILL_OPACITY = 0.5;
export const SELECT_LINK_FILL_OPACITY_HOVER = 0.5;
export const SELECT_LINK_FILL_OPACITY = 0.5;

export const ZONE_PERMANENT_HIGHLIGHT_COLOR_ZONE = "#cc3700";

// export const CHOROPLETH_SELECTED_FILL_COLOR = [139, 135, 236, 104];
// export const CHOROPLETH_HIGHLIGHT_COLOR = [139, 135, 236, 52];

export const ROAD_VOLUME_COLOR = "#13C5EC";
export const MAJOR_ROAD_VOLUME_COLOR = "#abbdc4"; // increased lightness to improve visibility on Dark/Satellite maps
export const ROAD_HAIRLINE_COLOR = "#1E9EBA";
export const ROAD_VOLUME_HOVER_COLOR = "#139EEC";
export const ROAD_SEGMENT_HIGHLIGHT_COLOR = "#1E9EBA";
export const ROAD_SEGMENT_SUPER_HIGHLIGHT_COLOR = "purple";
export const ROAD_SEGMENT_PERMANENT_HIGHLIGHT_COLOR = "#cc3700";
export const ROAD_NETWORK_VOLUME_OPACITY = 0.6;
export const ROAD_NETWORK_VOLUME_HIGHLIGHT_OPACITY = 0.8;
export const MAJOR_NETWORK_VOLUME_OPACITY = 0.6; // increased opacity to improve visibility on Dark/Satellite maps

const asScheme = (scheme: ReadonlyArray<readonly string[]>) => scheme[7] as string[];

export const CHOROPLETH_FILL_COLOR_SCHEME = [
  "#EFF6FF",
  "#DBEAFE",
  "#BFDBFE",
  "#93C5FD",
  "#60A5FA",
  "#3B82F6",
  "#2563EB",
  "#1D4ED8",
  "#1E40AF",
  "#1E3A8A",
];

export const CHOROPLETH_FILL_COLOR_SCHEME_2 = [
  "#A1EFFF",
  "#59E2FF",
  "#35D8FB",
  "#23CEF3",
  "#13C2E8",
  "#13B1D3",
  "#11A5C6",
  "#1596B2",
  "#117994",
  "#0E445A",
];

export const CHOROPLETH_FILL_COLOR_SCHEME_3 = [
  "#FFFAF5",
  "#FFF5EB",
  "#FEE6CE",
  "#FDD0A2",
  "#FDAE6B",
  "#FD8D3C",
  "#F16913",
  "#D94801",
  "#A63603",
  "#7F2704",
];

export const CHOROPLETH_FILL_COLOR_SCHEME_4 = [
  "#FCFFF8",
  "#F2FAE7",
  "#E0F3DB",
  "#CCEBC5",
  "#A8DDB5",
  "#7BCCC4",
  "#4EB3D3",
  "#2B8CBE",
  "#0868AC",
  "#084081",
];

export const sequentialSchemes: ColorScheme = {
  Default: CHOROPLETH_FILL_COLOR_SCHEME,
  Default2: CHOROPLETH_FILL_COLOR_SCHEME_2,
  Default3: CHOROPLETH_FILL_COLOR_SCHEME_3,
  Default4: CHOROPLETH_FILL_COLOR_SCHEME_4,
  MatrixDefault: ["#ffffff", "#dae1eb", "#b6c4d7", "#92a8c3", "#6e8db0", "#47739d", "#0e5a8a"], // https://gka.github.io/palettes/#/7|s|ffffff,0e5a8a||1|1
  Teal: ["#d1eeea", "#a8dbd9", "#85c4c9", "#68abb8", "#4f90a6", "#3b738f", "#2a5674"],
  DarkMint: ["#d2fbd4", "#a5dbc2", "#7bbcb0", "#559c9e", "#3a7c89", "#235d72", "#123f5a"],
  Magenta: ["#f3cbd3", "#eaa9bd", "#dd88ac", "#ca699d", "#b14d8e", "#91357d", "#6c2167"],
  SunsetDark: ["#fcde9c", "#faa476", "#f0746e", "#e34f6f", "#dc3977", "#b9257a", "#7c1d6f"],
  BurgYl: ["#fbe6c5", "#f5ba98", "#ee8a82", "#dc7176", "#c8586c", "#9c3f5d", "#70284a"],
  Blues: asScheme(schemeBlues),
  Greens: asScheme(schemeGreens),
  Oranges: asScheme(schemeOranges),
  Purples: asScheme(schemePurples),
  Reds: asScheme(schemeReds),
  BuGn: asScheme(schemeBuGn),
  BuPu: asScheme(schemeBuPu),
  GnBu: asScheme(schemeGnBu),
  OrRd: asScheme(schemeOrRd),
  PuBuGn: asScheme(schemePuBuGn),
  PuBu: asScheme(schemePuBu),
  PuRd: asScheme(schemePuRd),
  RdPu: asScheme(schemeRdPu),
  YlGnBu: asScheme(schemeYlGnBu),
  YlGn: asScheme(schemeYlGn),
  YlOrBr: asScheme(schemeYlOrBr),
  YlOrRd: asScheme(schemeYlOrRd),
};

export const getJenksFillColorGetter = (classes: number[] | null, colorScheme: string[]) => {
  if (!classes) {
    return () => CHOROPLETH_FILL_COLOR;
  }

  return (value: number) => {
    if (!value) {
      return CHOROPLETH_FILL_COLOR;
    }
    const index = classes.findIndex((currentClass, index, obj) => {
      return value >= currentClass && (value <= obj[index + 1] || !obj[index + 1]);
    });

    return colorScheme[index];
  };
};

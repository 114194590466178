import { useAuth0 } from "@auth0/auth0-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LayersIcon from "@mui/icons-material/Layers";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { Box, Card, CardContent, Collapse, Grid, Typography, styled } from "@mui/material";
import { FeatureCollection, Geometry, GeometryCollection, Properties, featureCollection } from "@turf/helpers";
import { Button, Dialog, IconButton } from "components_new";
import { AnyPaint, Map } from "mapbox-gl";
import React, { FC, MutableRefObject, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { HelpForm } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { ServiceOverlay } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { filterByGeometry, simplifyBuffered } from "utils/geometry";

import { LayersList } from "./LayersLegendList";
import { OverlayLegendItemInfo } from "./OverlayLegendItemInfo";

const MAX_REQUEST_COUNT = 30; // to stop if a service misbehaves and never signals completeness - or is just crazy big

interface LayersLegendProps {
  mapLoaded: boolean;
  mapController: MutableRefObject<null | any>;
  map: Map | null;
  overlayLayerIds: MutableRefObject<string[]>;
}

const pStyle = {
  margin: "0 0 5px 0",
};

const Wrapper = styled(Card)`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 330px;
  bottom: 0;
  left: calc(var(--sidebar-width) + 1rem);
  margin: 1rem;
  padding: var(--padding-sm);
  border-radius: 16px;
  box-shadow: none;
`;

const getHighlightLayerPaint = (layerType: string, layerPaint: AnyPaint | null | undefined) => {
  switch (layerType) {
    case "line":
      const linePaint = layerPaint as mapboxgl.LinePaint;
      return {
        ...linePaint,
        "line-width": [
          "case",
          ["boolean", ["feature-state", "highlightSelect"], false],
          ["*", linePaint["line-width"], 2],
          linePaint["line-width"],
        ],
        "line-opacity": [
          "case",
          ["boolean", ["feature-state", "highlightSelect"], false],
          linePaint["line-opacity"] || 1,
          0,
        ],
        "line-color": "rgb(225, 13, 203)",
      };

    case "circle":
      const circlePaint = layerPaint as mapboxgl.CirclePaint;
      return {
        ...circlePaint,
        "circle-radius": [
          "case",
          ["boolean", ["feature-state", "highlightSelect"], false],
          ["*", circlePaint["circle-radius"], 2],
          circlePaint["circle-radius"],
        ],
        "circle-opacity": [
          "case",
          ["boolean", ["feature-state", "highlightSelect"], false],
          circlePaint["circle-opacity"] || 1,
          0,
        ],
        "circle-stroke-opacity": [
          "case",
          ["boolean", ["feature-state", "highlightSelect"], false],
          circlePaint["circle-stroke-opacity"] || 1,
          0,
        ],
        "circle-color": "rgb(225, 13, 203)",
      };
    default:
      return layerPaint || {};
  }
};

const getLayerPaint = (layerType: string, layerPaint: AnyPaint | null | undefined) => {
  switch (layerType) {
    case "line":
      const linePaint = layerPaint as mapboxgl.LinePaint;
      return {
        ...linePaint,
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ["*", linePaint["line-width"], 2],
          linePaint["line-width"],
        ],
      };
    // case "fill":
    //   const fillPaint = layerPaint as mapboxgl.FillPaint;
    //   return {
    //     ...fillPaint,
    //     "fill-outline-color": [
    //       "case",
    //       ["boolean", ["feature-state", "hover"], false],
    //       "red",
    //       fillPaint["fill-outline-color"] || undefined,
    //     ],
    //   };
    case "circle":
      const circlePaint = layerPaint as mapboxgl.CirclePaint;
      return {
        ...circlePaint,
        "circle-radius": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ["*", circlePaint["circle-radius"], 2],
          circlePaint["circle-radius"],
        ],
      };
    // case "symbol":
    //   const symbolPaint = layerPaint as mapboxgl.SymbolPaint;
    //   return {
    //     ...symbolPaint,
    //     "icon-size": [
    //       "case",
    //       ["boolean", ["feature-state", "hover"], false],
    //       ["*", symbolPaint["icon-size"], 2],
    //       symbolPaint["icon-size"],
    //     ],
    //   };
    default:
      return layerPaint || {};
  }
};

export const LayersLegend: FC<LayersLegendProps> = ({ map, mapController, mapLoaded, overlayLayerIds }) => {
  const { user } = useAuth0();

  const [isLegendOpen, setIsLegendOpen] = useState<boolean>(false);
  const [serviceLayerDeploying, setServiceLayerDeploying] = useState(false);
  const [isOverlaysDialogOpen, setIsOverlaysDialogOpen] = useState<boolean>(false);
  const [overlayLegendInfoDialogIndex, setOverlayLegendInfoDialogIndex] = useState<number>(-1);
  const [indexOfErrorOverlay, setIndexOfErrorOverlay] = useState<number>(-1);
  const [topCustomMapLayer, setTopCustomMapLayer] = React.useState<string | null>(null);
  const [activeServiceLayerIndeces, setActiveServiceLayerIndeces] = React.useState<number[]>([]);
  const [activeServiceLayerNames, setActiveServiceLayerNames] = React.useState<string[]>([]);
  const [invisibleServiceLayerIndeces, setInvisibleServiceLayerIndeces] = React.useState<number[]>([]);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const serviceOverlayLayers = useAppSelector((state) => state.corridor.serviceLayers);
  const selectedAreaOfInterest = useAppSelector((state) => state.global.selectedFocusArea);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const filterGeometry = useMemo(
    () =>
      selectedAreaOfInterest
        ? simplifyBuffered(selectedAreaOfInterest.geometry as Geometry, 0.002) // tolerance: ~ 200 meters
        : null,
    [selectedAreaOfInterest],
  );

  useEffect(() => {
    if (serviceOverlayLayers.state === DataState.EMPTY && activeServiceLayerNames.length > 0) {
      if (mapController.current) {
        mapController.current.layerManager.removeLayers(activeServiceLayerNames);
        mapController.current.layerManager.removeSources(
          activeServiceLayerNames.map((layerName) => `${layerName}_source`),
        );
      }

      setActiveServiceLayerIndeces([]);
      setActiveServiceLayerNames([]);
      setInvisibleServiceLayerIndeces([]);
      setTopCustomMapLayer(null);
      setServiceLayerDeploying(false);
      setIsLegendOpen(false);
      overlayLayerIds.current = [];
    }
  }, [serviceOverlayLayers.state, mapController, activeServiceLayerNames, overlayLayerIds]);

  /**
   * Fetches features from a feature service layer query url, using pagination.
   *
   * @param url The query url, configured for pagination, but without the resultOffset parameter, which will be modified here)
   * @returns response with status and `FeatureCollection` as body
   */
  const fetchFeatures = async (url: String) => {
    /**
     * Recursive function to fetch all features from a feature service layer query url, using pagination
     *
     * @param acc the accumulator (array of feature arrays, flattened when done)
     * @param url the feature layer query url
     * @param resultOffset the current result offset (incremented based on number of returned features)
     * @param requestCount the current request count (to check against maximum request count)
     */
    const recurse: (
      acc: any[],
      url: String,
      resultOffset: number,
      requestCount: number,
    ) => Promise<{ ok: boolean; body: FeatureCollection<Geometry | GeometryCollection, Properties> | null }> = async (
      acc,
      url,
      resultOffset,
      requestCount,
    ) => {
      const badResponse = { ok: false, body: null }; // include more information once different cases are handled (disconnected, service not found etc.)

      if (requestCount > MAX_REQUEST_COUNT) {
        console.log(`Maximum request count exceeded: ${requestCount} > ${MAX_REQUEST_COUNT}`);
        return badResponse;
      }

      const featuresResponse = await fetch(`${url}&resultOffset=${resultOffset}`)
        .then((response) => response.json())
        .catch(() => badResponse);

      if (featuresResponse?.type !== "FeatureCollection") {
        return badResponse; // unexpected response
      }

      const newAcc = acc.concat([featuresResponse.features]);

      return featuresResponse?.properties?.exceededTransferLimit
        ? recurse(newAcc, url, resultOffset + featuresResponse.features.length, requestCount + 1)
        : {
            ok: true,
            body: featureCollection(newAcc.flat()), // flatten array of feature arrays
          };
    };

    return recurse([], url, 0, 1); // call the recursive function with initial values
  };

  const getAllOverlayFeatures = async (serviceOverlay: ServiceOverlay) => fetchFeatures(serviceOverlay.getFeaturesUrl);

  const handleToggleLegend = () => {
    setIsLegendOpen(!isLegendOpen);

    addCustomGAEvent("corridor", "overlay_legend", "toggle_legend", user, userOrganizationName);
  };

  const handleOpenHelpFormDialog = () => {
    setIsHelpFormOpen(true);
    setIsOverlaysDialogOpen(false);
  };

  const handleCloseHelpFormDialog = () => {
    setIsHelpFormOpen(false);
    setIsOverlaysDialogOpen(true);
  };

  const handleShowOverlaysDialog = () => {
    setIsOverlaysDialogOpen(true);

    addCustomGAEvent("corridor", "overlay_legend", "open_available_overlays", user, userOrganizationName);
  };
  const handleCloseOverlayLegendInfoDialog = () => setOverlayLegendInfoDialogIndex(-1);
  const handleShowOverlayLegendInfoDialog = (index: number) => setOverlayLegendInfoDialogIndex(index);

  const handleChangeServiceLayerAvailability = async ({ checked, index }: { checked: boolean; index: number }) => {
    let customLayerId = "";
    let putIndex = 0;

    if (!topCustomMapLayer) {
      const customLayerIds = mapController.current.layerManager.getActiveCustomLayerIds();
      customLayerId = customLayerIds[customLayerIds.length - 1];

      if (customLayerId) {
        setTopCustomMapLayer(customLayerId);
      }
    }

    const serviceLayerData = serviceOverlayLayers.data?.[index];

    if (!serviceLayerData) return;

    setServiceLayerDeploying(true);

    if (checked) {
      const response = await getAllOverlayFeatures(serviceLayerData);

      if (!response.ok) {
        setServiceLayerDeploying(false);
        setIndexOfErrorOverlay(index);

        toast.error("Error while loading overlay data");
        return;
      }

      const newSortedActiveServiceLayerIndeces = [...activeServiceLayerIndeces, index].sort((a, b) => a - b);

      if (index === indexOfErrorOverlay) {
        setIndexOfErrorOverlay(-1);
      }

      setActiveServiceLayerIndeces(newSortedActiveServiceLayerIndeces);
      setActiveServiceLayerNames(
        newSortedActiveServiceLayerIndeces
          .map((i) => [
            serviceOverlayLayers.data![i].serviceLayerId,
            `${serviceOverlayLayers.data![i].serviceLayerId}_highlight`,
          ])
          .flat(1),
      );
      putIndex = newSortedActiveServiceLayerIndeces.indexOf(index);

      const sourceData = response.body;
      let behindLayerId = topCustomMapLayer || customLayerId;

      mapController.current.layerManager.addSources([
        {
          id: `${serviceLayerData.serviceLayerId}_source`,
          source: {
            type: "geojson",
            data: filterGeometry && filterByGeometry(sourceData!, filterGeometry),
          },
        },
      ]);

      if (putIndex !== 0) {
        behindLayerId =
          serviceOverlayLayers.data?.[activeServiceLayerIndeces[putIndex - 1]]?.serviceLayerId || behindLayerId;
      }

      mapController.current.layerManager.addLayers(
        [
          {
            id: serviceLayerData.serviceLayerId,
            type: serviceLayerData.mapboxLayerProperties.type,
            source: `${serviceLayerData.serviceLayerId}_source`,
            paint: getLayerPaint(
              serviceLayerData.mapboxLayerProperties.type,
              serviceLayerData.mapboxLayerProperties.paint,
            ),
            layout: serviceLayerData.mapboxLayerProperties.layout || {},
            minzoom: serviceLayerData.mapboxLayerProperties.minzoom,
            maxzoom: serviceLayerData.mapboxLayerProperties.maxzoom,
          },
        ],
        behindLayerId,
      );

      mapController.current.layerManager.addLayers([
        {
          id: `${serviceLayerData.serviceLayerId}_highlight`,
          type: serviceLayerData.mapboxLayerProperties.type,
          source: `${serviceLayerData.serviceLayerId}_source`,
          paint: getHighlightLayerPaint(
            serviceLayerData.mapboxLayerProperties.type,
            serviceLayerData.mapboxLayerProperties.paint,
          ),
          layout: serviceLayerData.mapboxLayerProperties.layout || {},
          minzoom: serviceLayerData.mapboxLayerProperties.minzoom,
          maxzoom: serviceLayerData.mapboxLayerProperties.maxzoom,
        },
      ]);

      overlayLayerIds.current.push(serviceLayerData.serviceLayerId);

      setServiceLayerDeploying(false);

      addCustomGAEvent("corridor", "overlay_legend", "add_overlay", user, userOrganizationName);
    } else {
      setActiveServiceLayerIndeces(activeServiceLayerIndeces.filter((i) => i !== index));
      setActiveServiceLayerNames(
        activeServiceLayerNames.filter(
          (i) =>
            i !== serviceOverlayLayers.data![index].serviceLayerId &&
            i !== `${serviceOverlayLayers.data![index].serviceLayerId}_highlight`,
        ),
      );
      setInvisibleServiceLayerIndeces(invisibleServiceLayerIndeces.filter((i) => i !== index));

      mapController.current.layerManager.removeLayers([
        serviceLayerData.serviceLayerId,
        `${serviceLayerData.serviceLayerId}_highlight`,
      ]);
      mapController.current.layerManager.removeSources([`${serviceLayerData.serviceLayerId}_source`]);
      const layerIndex = overlayLayerIds.current.indexOf(serviceLayerData.serviceLayerId);
      if (layerIndex !== -1) {
        overlayLayerIds.current.splice(layerIndex, 1);
      }

      setServiceLayerDeploying(false);
      addCustomGAEvent("corridor", "overlay_legend", "remove_overlay", user, userOrganizationName);
    }
  };

  const handleToggleVisibility = (index: number) => {
    const isOverlayInvisible = invisibleServiceLayerIndeces.includes(index);
    const newInvisibleServiceLayerIndeces = isOverlayInvisible
      ? invisibleServiceLayerIndeces.filter((i) => i !== index)
      : [...invisibleServiceLayerIndeces, index];

    if (isOverlayInvisible) {
      mapController.current.layerManager.updateLayerLayout(
        `${serviceOverlayLayers.data![index].serviceLayerId}_highlight`,
        "visibility",
        "visible",
      );
      mapController.current.layerManager.updateLayerLayout(
        serviceOverlayLayers.data![index].serviceLayerId,
        "visibility",
        "visible",
      );
    } else {
      mapController.current.layerManager.updateLayerLayout(
        `${serviceOverlayLayers.data![index].serviceLayerId}_highlight`,
        "visibility",
        "none",
      );
      mapController.current.layerManager.updateLayerLayout(
        serviceOverlayLayers.data![index].serviceLayerId,
        "visibility",
        "none",
      );
    }

    setInvisibleServiceLayerIndeces(newInvisibleServiceLayerIndeces);
    addCustomGAEvent("corridor", "overlay_legend", "change_overlay_visibility", user, userOrganizationName);
  };

  return serviceOverlayLayers.state === DataState.AVAILABLE && mapLoaded ? (
    <Wrapper>
      <CardContent sx={{ p: "0 !important", m: 0 }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 36px 36px" }}>
          <Box sx={{ display: "flex", alignItems: "center", fontSize: "13px", fontWeight: 600 }}>
            <LayersIcon fontSize="small" sx={{ ml: 1, mr: 1 }} />
            <>Overlays</>
          </Box>
          <IconButton onClick={handleShowOverlaysDialog}>
            <LibraryAddIcon color="primary" fontSize="small" />
          </IconButton>
          <IconButton onClick={handleToggleLegend}>
            {isLegendOpen ? (
              <KeyboardArrowDownIcon color="primary" fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon color="primary" fontSize="small" />
            )}
          </IconButton>
        </Box>
      </CardContent>
      <Collapse
        in={isLegendOpen}
        timeout="auto"
        sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", p: 0, m: 0 }}
        unmountOnExit
      >
        <LayersList
          overlays={serviceOverlayLayers.data
            .map((overlay, index) => ({ ...overlay, index, invisible: invisibleServiceLayerIndeces.includes(index) }))
            .filter((overlay, index) => activeServiceLayerIndeces.indexOf(index) !== -1)}
          removeOverlay={handleChangeServiceLayerAvailability}
          toggleVisibility={handleToggleVisibility}
          showOverlayInfo={handleShowOverlayLegendInfoDialog}
        />
      </Collapse>
      <Dialog
        closeOnBackdropClick
        closeOnEscapeKeyDown
        maxWidth="lg"
        open={isOverlaysDialogOpen}
        title="Available Overlays"
        onClose={() => setIsOverlaysDialogOpen(false)}
        actions={
          <Grid container marginTop={1} spacing={2} justifyContent="space-between">
            <Grid item xs={10}>
              <Typography color="text.secondary" sx={{ fontSize: 12 }}>
                <p style={pStyle}>You can request overlays to be added using the button on the right. </p>
                <p style={pStyle}>
                  Overlays are based on{" "}
                  <Box component="span" fontWeight="bold">
                    public ArcGIS Feature Layers.{" "}
                  </Box>
                  Let us know which layer you would like to use by sending us the layer name and owner, or the link to
                  the details page in ArcGIS Online/Enterprise, and our team will get back to you when the layer is
                  available.
                </p>
              </Typography>
            </Grid>
            <Grid item>
              <Button size="medium" color="secondary" onClick={handleOpenHelpFormDialog}>
                {" "}
                Request new overlay{" "}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Box
          sx={{
            maxHeight: "500px",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {serviceOverlayLayers.data.map((overlay, index) => (
            <OverlayLegendItemInfo
              key={index}
              overlay={overlay}
              index={index}
              invalidOverlayIndex={indexOfErrorOverlay}
              isAdded={activeServiceLayerIndeces.includes(index)}
              loading={serviceLayerDeploying}
              addOverlay={handleChangeServiceLayerAvailability}
              withAdding
            />
          ))}
        </Box>
      </Dialog>
      <HelpForm
        isOpen={isHelpFormOpen}
        onClose={handleCloseHelpFormDialog}
        initialSubject="Request a New Overlay Layer: "
      />
      <Dialog open={overlayLegendInfoDialogIndex !== -1} maxWidth={"md"} onClose={handleCloseOverlayLegendInfoDialog}>
        <Box sx={{ m: 1, mt: 6 }}>
          {overlayLegendInfoDialogIndex >= 0 && serviceOverlayLayers.data[overlayLegendInfoDialogIndex] ? (
            <OverlayLegendItemInfo
              overlay={serviceOverlayLayers.data[overlayLegendInfoDialogIndex]}
              index={overlayLegendInfoDialogIndex}
            />
          ) : null}
        </Box>
      </Dialog>
    </Wrapper>
  ) : null;
};

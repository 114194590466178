import styled from "@emotion/styled";
import pic1 from "assets/png/dataset-definition-docs/Picture_1.jpg";
import pic2 from "assets/png/dataset-definition-docs/Picture_2.jpg";
import pic3 from "assets/png/dataset-definition-docs/Picture_3.jpg";
import pic4 from "assets/png/dataset-definition-docs/Picture_4.jpg";
import pic5 from "assets/png/dataset-definition-docs/Picture_5.jpg";
import pic6 from "assets/png/dataset-definition-docs/Picture_6.jpg";
import pic7 from "assets/png/dataset-definition-docs/Picture_7.jpg";
import pic8 from "assets/png/dataset-definition-docs/Picture_8.jpg";
import pic9 from "assets/png/dataset-definition-docs/Picture_9.jpg";
import pic10 from "assets/png/dataset-definition-docs/Picture_10.jpg";
import pic11 from "assets/png/dataset-definition-docs/Picture_11.jpg";
import pic12 from "assets/png/dataset-definition-docs/Picture_12.jpg";
import pic13 from "assets/png/dataset-definition-docs/Picture_13.jpg";
import pic14 from "assets/png/dataset-definition-docs/Picture_14.jpg";
import pic15 from "assets/png/dataset-definition-docs/Picture_15.jpg";
import pic16 from "assets/png/dataset-definition-docs/Picture_16.jpg";
import pic17 from "assets/png/dataset-definition-docs/Picture_17.jpg";
import pic18 from "assets/png/dataset-definition-docs/Picture_18.jpg";
import { Divider } from "components_new";
import { FC } from "react";

import {
  Description,
  ElevatedTable,
  FooterTableCell,
  HeadTableCell,
  SubTitle,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "./Table";
import { datasetErrors, datasetWarnings } from "./data";

const CenteredFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
`;

const Image = styled.img`
  max-width: 600px;
`;

const ImageDescription = styled(Description)`
  max-width: 480px;
  margin-bottom: 24px;
  font-weight: 500;
`;

export const DatasetDefinition: FC = () => (
  <>
    <Description>
      Datasets allow to create subareas of a region with gates defined at the boundaries as well as within the subarea.
      Gates allow to determine which specific roads Origin-Destination flows went through on their way in and out of the
      subarea as well as within the subarea. Trips in a dataset are classified as internal - internal, internal -
      external, external - internal and external - external, based on where they start and end and whether they pass
      through any of the defined gates or not.
    </Description>
    <Description>The following table shows the different classification rules:</Description>
    <ElevatedTable>
      <TableHead>
        <TableRow>
          <HeadTableCell>Sub-trip starts at</HeadTableCell>
          <HeadTableCell>Sub-trip ends at</HeadTableCell>
          <HeadTableCell>Classification</HeadTableCell>
          <HeadTableCell>Notes</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell rowSpan={3}>Zone inside subarea</TableCell>
          <TableCell>Zone inside subarea</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>I-I</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gate</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>I-X</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Other</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>I-X</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={3}>Gate</TableCell>
          <TableCell>Zone inside subarea</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>X-I</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gate</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>X-X</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Other</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>X-X</TableCell>
          <TableCell>Kept as X-X to preserve outgoing counts at exit gates</TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={3}>Other</TableCell>
          <TableCell>Zone inside subarea</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>X-I</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gate</TableCell>
          <TableCell style={{ borderTop: "2px solid green" }}>X-X</TableCell>
          <TableCell>Kept as X-X to preserve incoming counts at entry gates</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Other</TableCell>
          <TableCell style={{ borderTop: "2px solid red" }}>X-X</TableCell>
          <TableCell>Dropped</TableCell>
        </TableRow>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </ElevatedTable>
    <Divider />
    <SubTitle style={{ marginTop: "54px" }}>
      The classification rules are visualized in the following examples:
    </SubTitle>
    <CenteredFlexContainer>
      <Image src={pic1} alt="" />
      <ImageDescription>
        I-I trips between two zones inside the subarea that don't pass through any gates are mapped to an OD flow
        between the origin and destination zone.
      </ImageDescription>
    </CenteredFlexContainer>
    <CenteredFlexContainer>
      <Image src={pic2} alt="" />
      <ImageDescription>
        I-X trips that leave from a zone within the subarea to outside the subarea without passing through any gate are
        mapped to an OD flow between the origin zone and the containing county.
      </ImageDescription>
    </CenteredFlexContainer>
    <CenteredFlexContainer>
      <Image src={pic3} alt="" />
      <ImageDescription>
        I-I trips between two zones inside the subarea that pass through a gate get split into an I-X OD flow between
        the origin zone and the gate and an X-I OD flow between the gate and the destination zone.
      </ImageDescription>
    </CenteredFlexContainer>
    <CenteredFlexContainer>
      <Image src={pic4} alt="" />
      <ImageDescription>
        I-X trips that leave from a zone within the subarea to outside the subarea while passing through a gate get
        split into an I-X OD flow between the origin zone and the gate and an X-X OD flow between the gate and the
        containing county.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic5} alt="" />
      <ImageDescription>
        X-I trips that go from outside the subarea to a zone within the subarea while passing through a gate get split
        into an X-X OD flow between the containing county and the gate and an X-I OD flow between the gate and the
        destination zone.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic6} alt="" />
      <ImageDescription>
        I-I trips that pass through a gate outside the zones get mapped to a I-X trip between the origin zone and the
        gate and a X-I trip between the gate and the destination zone.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic7} alt="" />
      <ImageDescription>
        Trips that start and end outside the subarea but do pass through the zones or the subarea without passing
        through any gate are not considered in the dataset computation.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic8} alt="" />
      <ImageDescription>
        X-X trips that start and end outside the subarea but pass through one or multiple gates are split into X-X OD
        flows between the containing counties and the gates.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic9} alt="" />
      <ImageDescription>
        I-I trips that pass through two gates get split into an I-X OD flow between the start zone and the first gate, a
        X-X OD flow between the two gates and a X-I OD flow between the second gate and the destination zone.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic10} alt="" />
      <ImageDescription>
        X-X trips get split into X-X OD flows by each gate the trip is passing through.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic11} alt="" />
      <ImageDescription>
        I-I trips that start inside a zone inside the subarea, leave the zones and subarea and then end inside a zone
        inside the subarea get mapped to an I-I OD flow between the origin and destination zones.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic12} alt="" />
      <ImageDescription>
        X-X trips that start outside the zones and the subarea, pass through a gate and then end outside the subarea get
        mapped to an X-X OD flow between the county and the gate and an X-X OD flow between the gate and the county.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic13} alt="" />
      <ImageDescription>
        X-X trips that don't pass through any gate are not included in the dataset computation.
      </ImageDescription>
    </CenteredFlexContainer>

    <SubTitle style={{ marginTop: "54px" }}>
      Handling of the special case of a subarea that is larger than the zones
    </SubTitle>
    <Description>
      Usually the subarea is fully covered by zones. In the special case when the subarea is larger than the zones of
      the dataset, the following rules are applied.
    </Description>

    <CenteredFlexContainer>
      <Image src={pic14} alt="" />
      <ImageDescription>
        I-X trips that start in a zone inside the subarea and end outside any zone but still inside the subarea after
        passing through a gate get mapped to an I-X OD flow between the origin zone and the gate and a X-X OD flow
        between the gate and the containing county.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic15} alt="" />
      <ImageDescription>
        X-I trips that start outside the zones but inside the subarea and end in a zone inside the subarea get mapped to
        an OD flow between the containing county and the destination zone.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic16} alt="" />
      <ImageDescription>
        X-X trips that start outside the zones but within the subarea, pass through a gate and then end outside the
        subarea get mapped to an X-X OD flow between the county and the gate and an X-X OD flow between the gate and the
        county.
      </ImageDescription>
    </CenteredFlexContainer>

    <CenteredFlexContainer>
      <Image src={pic17} alt="" />
      <ImageDescription>
        X-X trips that start outside the zones but inside the subarea and end outside the subarea that don’t pass
        through any gate are not included in the dataset computation.
      </ImageDescription>
    </CenteredFlexContainer>

    <SubTitle style={{ marginTop: "54px" }}>Handling of trips at the subarea and zone boundaries</SubTitle>
    <Description>
      In case a subarea and zones of a custom dataset are not including the adjacent roads, trips that start in a house
      right at the boundary of the subarea get their trip start location mapped to the road segment closest to the house
      right outside the subarea boundary. This can result in trips that start inside the subarea but don’t pass through
      any gate, despite gates covering all roads crossed by the subarea boundary. To resolve this issue and have all
      trips entering / leaving the subarea pass through a gate, it is recommended to slightly increase the coverage of
      the subarea to include the adjacent roads and to update the gate definition accordingly.
    </Description>
    <CenteredFlexContainer>
      <Image src={pic18} alt="" />
    </CenteredFlexContainer>

    <SubTitle style={{ marginTop: "54px" }}>Pre-Computation Validation of Datasets</SubTitle>
    <Description>
      Before starting a dataset computation, we validate that the dataset definition fulfills the following criteria.
    </Description>
    <Description>
      If they don't fulfill the criteria below, the dataset computation review page will issue a warning.
    </Description>
    <ElevatedTable>
      <TableHead>
        <TableRow>
          <HeadTableCell>Warning</HeadTableCell>
          <HeadTableCell>Resolution</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {datasetWarnings.map((f) => (
          <TableRow key={f.id}>
            <TableCell width={"450px"}>{f.warning}</TableCell>
            <TableCell>{f.resolution}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </ElevatedTable>
    <Description>
      If they don't fulfill the below criteria, the dataset computation review page will issue an error.
    </Description>
    <ElevatedTable>
      <TableHead>
        <TableRow>
          <HeadTableCell>Error</HeadTableCell>
          <HeadTableCell>Resolution</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {datasetErrors.map((f) => (
          <TableRow key={f.id}>
            <TableCell width={"450px"}>{f.warning}</TableCell>
            <TableCell>{f.resolution}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </ElevatedTable>
  </>
);

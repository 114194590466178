import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Popover, PopoverProps } from "components_new";
import React, { FC } from "react";

export interface InfoPopoverProps extends Omit<PopoverProps, "control"> {
  disabled?: boolean;
}

export const InfoPopover: FC<InfoPopoverProps> = ({ children, disabled, ...props }) => {
  return (
    <Popover
      {...props}
      control={(handleOpen, handleClose, open) => (
        <IconButton disabled={disabled} aria-haspopup="true" onMouseEnter={handleOpen} onMouseLeave={handleClose}>
          <InfoOutlined fontSize="small" />
        </IconButton>
      )}
      id="mouse-over-popover"
      elevation={2}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      hover
    >
      {children}
    </Popover>
  );
};

import { styled } from "@mui/material";
import { CircularProgress } from "components_new";
import { FC, MutableRefObject, memo, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { RoadAnalytics, RoadAttributeProps, RoadAttributeValue } from "features";

import { getCurrentMeasure } from "features/filters/utils";

import { RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { MapVisualizationType, RoadSegmentDetails, SegmentFeatureAttribute } from "types";

import { capitalize, convertMetersToMiles } from "utils/format";

import { useChangeRoadVolumeId, useSelectRoadVolumeId } from "./RoadsControllerCallbacks";

interface RoadsAnalyticsPanelProps {
  isPedestriansMode: boolean;
  closeRoadsAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

const RightSidebarLoaderWrapper = styled("div")`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mainSegmentAttribute = new Set(["segment_id", "factype", "highway"]);

const createSegmentAttributeMap: (
  attributes: SegmentFeatureAttribute[],
  labels: Map<string, { label: string; type: string }>,
) => RoadAttributeValue[] = (attributes, labels) =>
  attributes.map((attr) => {
    let value = attr.value;
    let mainAttribute = undefined;
    if (!attr.value) {
      value = "n/a";
    }
    if (attr.columnName === "distance_m") {
      value = convertMetersToMiles(attr.value).toFixed(5) + " miles";
    }
    if (labels.get(attr.columnName)?.type === "String") {
      value = capitalize(value);
    }
    if (mainSegmentAttribute.has(attr.columnName)) {
      mainAttribute = true;
    }

    return {
      label: labels.get(attr.columnName)?.label ?? attr.columnName,
      value: value,
      mainAttribute: mainAttribute,
    };
  });

export const RoadsAnalyticsPanel: FC<RoadsAnalyticsPanelProps> = memo(
  ({ isPedestriansMode, closeRoadsAnalyticsPanelRef }) => {
    const [searchParams] = useSearchParams();

    const measure = useAppSelector((state) => state.filters.measure);

    const selectedRoadVolume = useAppSelector((state) => state.analytics.selectedRoadVolume);
    const selectedRoadVolumeId = useAppSelector((state) => state.analytics.selectedRoadVolumeId);
    const roadsVolumesState = useAppSelector((state) => state.analytics.roadsVolumes.state);
    const roadSegmentIndexesState = useAppSelector((state) => state.analytics.roadSegmentIndexes.state);
    const roadSegmentsDetails = useAppSelector((state) => state.analytics.roadSegmentsDetails);
    const roadMeasures = useAppSelector((state) => state.analytics.roadsMetadata.data?.measures);

    const roadsMetadataAttributes = useAppSelector((state) => state.analytics.roadsMetadata.data?.featureAttributes);
    const roadFeatureSegmentsDetails = useAppSelector(
      (state) => state.analytics.roadSegmentsFeatureDetails.data?.segments,
    );

    const roadFeatureSegmentsDetailsMap = useMemo(() => {
      const labelMap = new Map(
        roadsMetadataAttributes?.map((attribute) => [
          attribute.columnName,
          { label: attribute.label, type: attribute.attributeType },
        ]),
      );
      return new Map(
        roadFeatureSegmentsDetails?.map((segDetail) => [
          segDetail.segmentId,
          createSegmentAttributeMap(segDetail.attributes, labelMap),
        ]),
      );
    }, [roadsMetadataAttributes, roadFeatureSegmentsDetails]);

    const currentRoadMeasure = useMemo(() => getCurrentMeasure(roadMeasures, measure), [roadMeasures, measure]);
    const roadsMetadataDimensions = useMemo(() => currentRoadMeasure?.dimensions || [], [currentRoadMeasure]);

    const selectRoadVolumeId = useSelectRoadVolumeId();
    const changeRoadVolumeId = useChangeRoadVolumeId(selectedRoadVolumeId, selectRoadVolumeId);

    const mode = useMemo(() => searchParams.get("mode") as MapVisualizationType, [searchParams]);
    const isLoading = useMemo(() => {
      return (
        roadsVolumesState === DataState.LOADING ||
        roadSegmentIndexesState === DataState.LOADING ||
        roadSegmentsDetails.state === DataState.LOADING
      );
    }, [roadsVolumesState, roadSegmentIndexesState, roadSegmentsDetails.state]);

    const isShowRightSidebarForRoads = useMemo(
      () =>
        Boolean(
          selectedRoadVolume &&
            roadSegmentsDetails.state === DataState.AVAILABLE &&
            roadSegmentsDetails?.data &&
            mode === MapVisualizationType.ROADS,
        ),
      [mode, roadSegmentsDetails, selectedRoadVolume],
    );

    const isSegmentDetailsLoading = useMemo(
      () => Boolean(selectedRoadVolume && isLoading),
      [selectedRoadVolume, isLoading],
    );

    const getRoadSegmentSubtitle = (
      roadSegmentsDetailsData: Record<string, RoadSegmentDetails>,
      selectedRoadVolumeId: string | null,
    ) => {
      if (!selectedRoadVolumeId) {
        return "";
      }

      if (isPedestriansMode) {
        return `Annual average daily trips ${
          roadSegmentsDetailsData
            ? Object.values(roadSegmentsDetailsData)
                .reduce((partialSum, data) => partialSum + (data.summary.filteredTotal ?? 0), 0)
                .toLocaleString("en-US")
            : "..."
        }`;
      }

      return `Annual average daily trips ${
        roadSegmentsDetailsData
          ? (roadSegmentsDetailsData[selectedRoadVolumeId]?.summary.filteredTotal ?? 0).toLocaleString("en-US")
          : "..."
      }`;
    };

    const handleClosePanel = () => {
      if (typeof closeRoadsAnalyticsPanelRef.current === "function") {
        closeRoadsAnalyticsPanelRef.current();
      }
    };

    const [segmentAttributeOpen, setSegmentAttributeOpen] = useState(false);
    const roadAttributeProps: RoadAttributeProps | undefined = selectedRoadVolumeId
      ? {
          values: roadFeatureSegmentsDetailsMap.get(selectedRoadVolumeId),
          openState: segmentAttributeOpen,
          setOpenState: setSegmentAttributeOpen,
        }
      : undefined;

    return (
      <RightSidebarPanel
        title={isSegmentDetailsLoading ? "Loading..." : (selectedRoadVolume?.streetName as string)}
        subtitle={getRoadSegmentSubtitle(roadSegmentsDetails.data!, selectedRoadVolumeId)}
        cardType="road-segment"
        onClose={handleClosePanel}
        isOpen={isShowRightSidebarForRoads || isSegmentDetailsLoading}
      >
        {!isSegmentDetailsLoading &&
        selectedRoadVolume &&
        selectedRoadVolumeId &&
        roadsMetadataDimensions &&
        roadSegmentsDetails.data ? (
          <RoadAnalytics
            breakdowns={
              roadSegmentsDetails.data[
                selectedRoadVolume.ftSegmentId === selectedRoadVolumeId
                  ? selectedRoadVolume.ftSegmentIdx!
                  : selectedRoadVolume.tfSegmentIdx!
              ]?.summary.breakdowns
            }
            selectedRoadVolumeId={selectedRoadVolumeId}
            selectedRoadVolume={selectedRoadVolume}
            roadMetadataDimensions={roadsMetadataDimensions}
            roadAttributeProps={roadAttributeProps}
            selectedSegmentId={selectedRoadVolumeId}
            changeRoadVolumeId={changeRoadVolumeId}
            isPedestriansMode={isPedestriansMode}
          />
        ) : null}
        {isSegmentDetailsLoading && (
          <RightSidebarLoaderWrapper>
            <CircularProgress />
          </RightSidebarLoaderWrapper>
        )}
      </RightSidebarPanel>
    );
  },
);

import { Stack, Typography, styled } from "@mui/material";
import logo from "assets/svg/open-paths-icon.svg";
import React, { FC, useMemo } from "react";

import { FlexContainer, NavItem, NavMenu, UserControls } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import {
  selectAtLeastOneCorridorDataPermission,
  selectAtLeastOneRoadVmtDataPermission,
  selectAtLeastOneSelectLinkPermission,
} from "store/permissionsSelectors";

import { ROUTES, UserPermissions } from "types";

interface Props {
  permissions: UserPermissions | null;
}

const NavbarContainer = styled(FlexContainer)(({ theme }) => ({
  width: "100%",
  padding: "0 0.5rem 0 1rem",
  backgroundColor: theme.palette.primary.main,
  justifyContent: "space-between",
  [theme.breakpoints.up("lg")]: {
    padding: "0 1rem ",
  },
}));

export const Navbar: FC<Props> = ({ permissions }) => {
  const isODMetadataLoading = useAppSelector((state) => state.analytics.ODMetadata.state) === DataState.LOADING;
  const isRoadsMetadataLoading = useAppSelector((state) => state.analytics.roadsMetadata.state) === DataState.LOADING;
  const isDatasetMetadataLoading =
    useAppSelector((state) => state.analytics.datasetMetadata.state) === DataState.LOADING;

  const isCorridorAllowed = useAppSelector(selectAtLeastOneCorridorDataPermission);
  const isRoadVmtAllowed = useAppSelector(selectAtLeastOneRoadVmtDataPermission);
  const isSelectLinkAllowed = useAppSelector(selectAtLeastOneSelectLinkPermission);

  const navItems: NavItem[] = useMemo(
    () => [
      {
        label: "Dashboard",
        url: ROUTES.Dashboard,
      },
      {
        label: "Map",
        url: ROUTES.Map,
        disabled: isODMetadataLoading || isDatasetMetadataLoading || isRoadsMetadataLoading,
      },
      {
        label: "Datasets",
        url: ROUTES.Datasets,
      },
      {
        label: "Analytics",
        url: ROUTES.Analytics,
        subItems: [
          ...(isSelectLinkAllowed ? [{ label: "Select Link", url: ROUTES.SelectLink }] : []),
          {
            label: "Corridor Discovery",
            url: ROUTES.CorridorDiscovery,
            disabled: !isCorridorAllowed,
          },
          ...(isRoadVmtAllowed
            ? [
                {
                  label: "Road VMT",
                  url: ROUTES.RoadVmt,
                  disabled: !isRoadVmtAllowed,
                },
              ]
            : []),
        ],
      },
      {
        label: "Exports",
        url: ROUTES.Exports,
      },
      {
        label: "Documentation",
        url: ROUTES.Documentation,
      },
    ],
    [
      isODMetadataLoading,
      isDatasetMetadataLoading,
      isRoadsMetadataLoading,
      isSelectLinkAllowed,
      isCorridorAllowed,
      isRoadVmtAllowed,
    ],
  );

  return (
    <NavbarContainer>
      <FlexContainer>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <img src={logo} alt="Bentley Logo" style={{ width: "34px" }} />

          <Typography sx={{ color: "#fff", fontSize: 18, fontWeight: 600, minWidth: "180px" }}>
            OpenPaths Patterns
          </Typography>
        </Stack>

        <NavMenu navItems={navItems} />
      </FlexContainer>
      <UserControls />
    </NavbarContainer>
  );
};

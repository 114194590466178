import booleanDisjoint from "@turf/boolean-disjoint";
import buffer from "@turf/buffer";
import { FeatureCollection, Geometry, feature } from "@turf/helpers";
import simplify from "@turf/simplify";

/**
 * Filter features of a feature collection to those intersecting a specified geometry
 */
export const filterByGeometry = (
  featureCollection: FeatureCollection,
  filterGeometry: Geometry,
  logTime: Boolean = false,
): FeatureCollection => {
  const startTime = performance.now();
  const filteredFeatures = featureCollection.features.filter((feature) => !booleanDisjoint(filterGeometry, feature));

  if (logTime) {
    console.debug(
      `input: ${featureCollection.features.length}; filtered: ${filteredFeatures.length}; time: ${
        performance.now() - startTime
      } ms`,
    );
  }

  return {
    ...featureCollection,
    features: filteredFeatures,
  };
};

/**
 * Simplifies a geometry by first buffering it and then simplifying the buffer, thus making sure that the simplified geometry never
 * intersects the interior of the original geometry.
 *
 * This is simplification is intended for performance optimization, and buffers/simplifies the input geometry using a tolerance
 * specified in decimal degrees, without projection to a planar coordinate system. The goal is merely to reduce the number of vertices.
 */
export const simplifyBuffered = (geometry: Geometry, toleranceDegrees: number): Geometry => {
  const buffered = buffer(feature(geometry), toleranceDegrees, { units: "degrees" });

  return simplify(buffered, { tolerance: toleranceDegrees, highQuality: false, mutate: false }).geometry as Geometry;
};

import React, { Dispatch, FC, MutableRefObject, RefObject, SetStateAction, useEffect, useState } from "react";

import { Popup } from "components";

interface PopupWrapperProps {
  renderPopupContent: (props: any) => JSX.Element;
  popupRef: RefObject<HTMLDivElement>;
  setPopupRef: RefObject<Dispatch<SetStateAction<any | null>>>;
}

export const PopupWrapper: FC<PopupWrapperProps> = ({ popupRef, setPopupRef, renderPopupContent }) => {
  const [popupProps, setPopupProps] = useState<any | null>(null);

  useEffect(() => {
    (setPopupRef as MutableRefObject<Dispatch<SetStateAction<any | null>>>).current = setPopupProps;
  }, [setPopupRef]);

  return (
    <Popup>
      <div ref={popupRef}>{popupProps ? renderPopupContent(popupProps) : null}</div>
    </Popup>
  );
};

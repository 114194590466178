import styled from "@emotion/styled";
import { FC } from "react";

import { BlurBackground } from "components/atoms/page-container/HighlightPageContainer";

export interface PageHeaderProps {
  title?: string;
  subtitle?: string;
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 0;

  @media only screen and (min-width: 992px) {
    font-size: 50px;
    font-weight: 700;
  }
`;

const Subtitle = styled.h3`
  color: #e5e7eb;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <Container>
      <BlurBackground />
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    </Container>
  );
};

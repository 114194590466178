import styled from "@emotion/styled";
import { ArrowRight, Download, Error as ErrorIcon, Notifications as NotificationsIcon } from "@mui/icons-material";
import { CircularProgress, Divider, Popover } from "components_new";
import { timeFormat } from "d3-time-format";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isExpired } from "features/export";

import { FlexContainer } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import { DataState } from "../../../store/interfaces";
import { licenseActions } from "../../../store/sections/license";
import { NotificationLevel, NotificationUrl, NotificationUrlType } from "../../../types";

const NotificationsContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 395px;
`;

const Header = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
  padding: 15px 20px;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

const NotificationsList = styled(FlexContainer)`
  width: 100%;
  flex-direction: column;
  height: 550px;
  overflow-y: auto;
`;

const NotificationsItem = styled(FlexContainer)`
  width: 100%;
  flex-direction: column;
  padding: 10px 16px;
`;

const NotificationsTitle = styled(FlexContainer)`
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  align-items: center;
  justify-content: flex-start;
`;

const NotificationsContentTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

const NotificationsContentDescription = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray-500);
`;

const NotificationsContentSimpleDate = styled.span`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 12px;
  color: var(--color-textSecondary);
`;

const NotificationsContent = styled(FlexContainer)`
  width: 100%;
  min-height: 51px;
  margin: 8px 0;
  padding: 8px;
  border: 1px solid var(--color-gray-200);
  border-radius: 4px;
  box-shadow: var(--box-shadow-xs);
  align-self: right;
  align-items: center;
  gap: 5px;

  :hover {
    cursor: pointer;
    border: 2px solid var(--color-gray-200);
    padding: 7px;
  }
`;

const ColumnFlexContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
`;

const NotificationsContentBody = styled(FlexContainer)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const UnreadNotificationCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2dd4bf;
  margin-right: 5px;
`;

const LoaderContainer = styled(FlexContainer)`
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
`;

const NotificationsTarget = styled(FlexContainer)`
  position: relative;
  margin-right: 14px;
  width: 20px;
  cursor: pointer;
`;

const UnreadNotificationsState = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--color-text);
  right: -4px;
  bottom: 8px;
  background-color: #eaeaea;
`;

const NotificationsPlaceHolder = styled.div`
  width: 370px;
  height: 100%;
  margin: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);
`;

const relativeTimePeriods: Array<[number, string]> = [
  [31536000, "year"],
  [2419200, "month"],
  [604800, "week"],
  [86400, "day"],
  [3600, "hour"],
  [60, "minute"],
  [1, "second"],
];

function relativeTime(date: string) {
  const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
  for (let [secondsPer, name] of relativeTimePeriods) {
    if (seconds >= secondsPer) {
      const amount = Math.floor(seconds / secondsPer);
      return `${amount} ${name}${amount === 1 ? "" : "s"} ago`;
    }
  }
  return "Just now";
}

const formatCreatedTime = (date: string | null) => (date ? timeFormat("%B %e, %Y | %I:%M%p")(new Date(date)) : "N/A");

const formatCreatedTimeSimple = (date: string | null) => (date ? relativeTime(date) : "N/A");

const getNotificationUrlIcon = (type: NotificationUrlType) => {
  switch (type) {
    case NotificationUrlType.APP_PAGE:
      return <ArrowRight />;
    case NotificationUrlType.DOWNLOAD:
      return <Download />;
  }
};

export const Notifications: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userOrganization = useAppSelector((state) => state.license.user.data?.organization);
  const notificationsState = useAppSelector((state) => state.license.notificationsState);
  const notifications = useAppSelector((state) => state.license.notifications);

  const [dateHoveringId, setDateHoveringId] = useState<number | null>(null);

  const notificationInterval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (notificationsState.state === DataState.EMPTY && userOrganization) {
      dispatch(licenseActions.fetchNotificationsInboxState());
    }
  }, [dispatch, notificationsState.state, userOrganization]);

  useEffect(() => {
    if (
      notificationsState.state === DataState.AVAILABLE &&
      !notificationInterval.current &&
      notificationsState.data.waitMilli
    ) {
      notificationInterval.current = setInterval(() => {
        dispatch(licenseActions.fetchNotificationsInboxState());
      }, notificationsState.data.waitMilli);
    }
  }, [notificationsState.state, notificationsState.data?.waitMilli, dispatch]);

  useEffect(() => {
    return () => {
      notificationInterval.current && clearInterval(notificationInterval.current);
    };
  }, []);

  useEffect(() => {
    if (notifications.state === DataState.AVAILABLE && notifications.data.data.length > 0) {
      const lastAccessed = notifications.data.data.reduce((a, b) =>
        new Date(a.audit.createdAt) > new Date(b.audit.createdAt) ? a : b,
      );
      dispatch(licenseActions.updateNotificationsInboxState(lastAccessed.audit.createdAt));
    }
  }, [notifications, dispatch]);

  const handleMouseOver = (id: number) => {
    setDateHoveringId(id);
  };

  const handleMouseOut = () => {
    setDateHoveringId(null);
  };

  const handleBellClick = () => {
    dispatch(licenseActions.fetchNotificationsInbox());
    dispatch(licenseActions.readAllNotificationsFromInbox());
  };

  const navigateToUrl = (url: string) => {
    const urlParams = new URLSearchParams(url);
    const datasetId = urlParams.get("id");

    if (url.includes("map") && datasetId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId: datasetId, redirectUrl: url }));
    } else {
      navigate(url);
    }
  };

  const getNotificationAction = (url: NotificationUrl | undefined) => {
    switch (url?.urlType) {
      case NotificationUrlType.APP_PAGE:
        return navigateToUrl(url.value);
      case NotificationUrlType.DOWNLOAD:
        return (window.location.href = url.value);
      default:
        return undefined;
    }
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      control={(handleOpen, open) => (
        <NotificationsTarget
          onClick={(e) => {
            handleBellClick();
            handleOpen(e);
          }}
        >
          <NotificationsIcon sx={{ color: "#fff" }} />
          {notificationsState.data && notificationsState.data.unseenNotificationCount > 0 && !open && (
            <UnreadNotificationsState />
          )}
        </NotificationsTarget>
      )}
    >
      <NotificationsContainer>
        <Header>
          <Title>Notifications</Title>
        </Header>
        <Divider style={{ width: "100%", margin: 0 }} />
        <NotificationsList>
          {notifications.state === DataState.LOADING && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
          {notifications.state === DataState.AVAILABLE && notifications.data.data.length === 0 && (
            <NotificationsPlaceHolder>
              <span>No notifications yet</span>
            </NotificationsPlaceHolder>
          )}
          {notifications.state === DataState.AVAILABLE &&
            notifications.data.data.length > 0 &&
            notifications.data.data
              .filter(({ content: { title }, audit: { createdAt } }) =>
                title.toLowerCase().includes("export") && isExpired(createdAt) ? false : true,
              )
              .map((n, i) => (
                <NotificationsItem key={n.id}>
                  {i !== 0 && (
                    <Divider
                      style={{
                        width: "95%",
                        marginTop: 0,
                        marginBottom: "10px",
                      }}
                    />
                  )}
                  <NotificationsTitle>
                    {!n.seen && <UnreadNotificationCircle />}
                    <h4>{n.content.title}</h4>
                  </NotificationsTitle>
                  <NotificationsContent onClick={() => getNotificationAction(n.content.url)}>
                    {n.content.level === NotificationLevel.ERROR && <ErrorIcon color="error" fontSize="small" />}
                    <NotificationsContentBody>
                      <ColumnFlexContainer>
                        <NotificationsContentTitle>{n.content.detailsText}</NotificationsContentTitle>
                        <NotificationsContentDescription>{n.content.detailsFooter}</NotificationsContentDescription>
                      </ColumnFlexContainer>
                      {n.content.url && <>{getNotificationUrlIcon(n.content.url.urlType)}</>}
                    </NotificationsContentBody>
                  </NotificationsContent>
                  <NotificationsContentSimpleDate onMouseOver={() => handleMouseOver(n.id)} onMouseOut={handleMouseOut}>
                    {dateHoveringId === n.id
                      ? formatCreatedTime(n.audit.createdAt)
                      : formatCreatedTimeSimple(n.audit.createdAt)}
                  </NotificationsContentSimpleDate>
                </NotificationsItem>
              ))}
        </NotificationsList>
      </NotificationsContainer>
    </Popover>
  );
};

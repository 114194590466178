import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

export function useRedirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const redirectUrl = useAppSelector((state) => state.global.redirectUrl);

  useEffect(() => {
    if (redirectUrl) {
      dispatch(globalActions.setRedirectUrl(null));
      navigate(redirectUrl);
    }
  }, [redirectUrl, navigate, dispatch]);
}

import { styled } from "@mui/material";

import { Card } from "components";

export const LeftSidebar = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  padding: theme.spacing(2),
  gridArea: "left-sidebar",
  boxShadow: "0 1px 2px rgba(60,64,67,0.3),0 2px 6px 2px rgba(60,64,67,0.15)",
  backgroundColor: theme.palette.background.paper,
}));

import React, { useMemo } from "react";

import { SpinnerOverlay } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

interface LoadingSpinnerWrapperProps {
  loading?: boolean;
}

export const LoadingSpinnerWrapper = ({ loading }: LoadingSpinnerWrapperProps) => {
  const ODMetadatState = useAppSelector((state) => state.analytics.ODMetadata.state);
  const ODIdsState = useAppSelector((state) => state.analytics.ODIds.state);
  const ODCountsState = useAppSelector((state) => state.analytics.ODCounts.state);
  const ODCountsByZoneIdState = useAppSelector((state) => state.analytics.ODCountsByZoneId.state);
  const datasetCountsState = useAppSelector((state) => state.analytics.datasetCounts.state);
  const zoneDetailsState = useAppSelector((state) => state.analytics.zoneDetails.state);
  const roadsMetadataState = useAppSelector((state) => state.analytics.roadsMetadata.state);
  const roadsVolumesState = useAppSelector((state) => state.analytics.roadsVolumes.state);
  const roadSegmentIndexesState = useAppSelector((state) => state.analytics.roadSegmentIndexes.state);

  const isLoading = useMemo(() => {
    return (
      loading ||
      ODMetadatState === DataState.LOADING ||
      ODIdsState === DataState.LOADING ||
      ODCountsState === DataState.LOADING ||
      ODCountsByZoneIdState === DataState.LOADING ||
      datasetCountsState === DataState.LOADING ||
      zoneDetailsState === DataState.LOADING ||
      roadsMetadataState === DataState.LOADING ||
      roadsVolumesState === DataState.LOADING ||
      roadSegmentIndexesState === DataState.LOADING
    );
  }, [
    loading,
    ODMetadatState,
    ODIdsState,
    ODCountsState,
    ODCountsByZoneIdState,
    datasetCountsState,
    zoneDetailsState,
    roadsMetadataState,
    roadsVolumesState,
    roadSegmentIndexesState,
  ]);

  return <>{isLoading ? <SpinnerOverlay /> : null}</>;
};

import styled from "@emotion/styled";

export const LinkButton = styled.button`
  background: transparent;
  color: var(--color-textLight);
  font-size: var(--font-size-sm);
  font-weight: 500;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  white-space: nowrap;
  cursor: pointer;
  min-width: 100px;
`;

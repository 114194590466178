import { FC } from "react";

import {
  BluePoint,
  Description,
  ElevatedTable,
  FooterTableCell,
  HeadTableCell,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "./Table";

export const AoiTripsTable: FC = () => (
  <>
    <Description>
      The following table shows the trips displayed on the OD Matrix map compared to the export content for{" "}
      <strong>area of interest</strong>:{" "}
    </Description>
    <ElevatedTable>
      <TableHead>
        <TableRow>
          <HeadTableCell>Trip in Area of Interest</HeadTableCell>
          <HeadTableCell>Included on Map</HeadTableCell>
          <HeadTableCell>Included in Export</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Internal - Internal</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Internal - External</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>External - Internal</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>External - External</TableCell>
          <TableCell>
            <BluePoint />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <FooterTableCell colSpan={7}>&nbsp;</FooterTableCell>
        </TableRow>
      </TableBody>
    </ElevatedTable>
  </>
);

import { DirectionsWalk, DriveEta, Info } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { FC } from "react";

import { MeasureType } from "types";

export interface MeasureIconProps extends SvgIconProps {
  measure?: string;
}

export const MeasureIcon: FC<MeasureIconProps> = ({ measure, ...props }) => {
  switch (measure) {
    case MeasureType.AADT:
      return <DriveEta {...props} />;
    case MeasureType.PEDESTRIANS:
      return <DirectionsWalk {...props} />;
    default:
      return <Info {...props} />;
  }
};

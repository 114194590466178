import { Divider as MuiDivider, DividerProps as MuiDividerProps, styled } from "@mui/material";
import * as React from "react";

export interface DividerProps extends MuiDividerProps {
  // Add your custom props here
}

const StyledDivider = styled((props: DividerProps) => <MuiDivider {...props} />)(({ theme }) => ({
  "&.MuiDivider-root": {
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));

export const Divider: React.FC<DividerProps> = ({ ...props }) => {
  return <StyledDivider {...props} />;
};

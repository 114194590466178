import { useAuth0 } from "@auth0/auth0-react";
import { Search } from "@mui/icons-material";
import { Autocomplete, Box, InputAdornment } from "@mui/material";
import { TextField } from "components_new";
import { Map, Marker } from "mapbox-gl";
import React, { FC, MutableRefObject, useCallback, useRef, useState } from "react";

import { useAppDispatch, useAppSelector, useDebounce } from "hooks";

import { analyticsActions } from "store/sections/analytics";

import { GeocodingSearchResultsFeature } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

const MIN_SEARCH_TEXT_LENGTH = 5;

interface GeocodingSearchBoxProps {
  map: MutableRefObject<Map | null>;
}

const GeocodingSearchBox: FC<GeocodingSearchBoxProps> = ({ map }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const geocodingSearchResults = useAppSelector((state) => state.analytics.geocodingSearch);
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const [value, setValue] = React.useState<GeocodingSearchResultsFeature | null>(null);

  const [searchText, setSearchText] = useState("");

  const searchMarker = useRef(new Marker({ scale: 0.8, offset: [0, -22], color: "blue" }));

  const handleGeocodingSearch = (searchText: string) => {
    if (map.current) {
      const proximity = map.current.getCenter().toArray().join(",");

      dispatch(
        analyticsActions.fetchGeocodingSearchResults(
          searchText,
          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string,
          proximity,
        ),
      );
    }
  };

  const handleClearGeocodingSearch = useCallback(() => {
    dispatch(analyticsActions.clearGeocodingSearchResults());
  }, [dispatch]);

  const handleRemoveSearchMarker = () => {
    searchMarker.current.remove();
  };

  const handleGeocodingFlyTo = (coordinates: [number, number], placeName: string) => {
    if (map.current) {
      map.current.flyTo({
        center: coordinates,
        zoom: 14,
      });

      searchMarker.current.remove();
      searchMarker.current.setLngLat(coordinates).addTo(map.current);
    }

    addCustomGAEvent("geocoding-search", "search", placeName, user, userOrganizationName);
  };

  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);

    debouncedSearchRequest();
  };

  const debouncedSearchRequest = useDebounce(() => {
    if (searchText.length >= MIN_SEARCH_TEXT_LENGTH) {
      handleGeocodingSearch(searchText);
    } else {
      handleClearGeocodingSearch();
    }
  }, 250);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "2px",
        left: "328px",
        width: "250px",
        zIndex: 2,
      }}
    >
      <Autocomplete
        disablePortal
        popupIcon={null}
        options={geocodingSearchResults.data?.features || []}
        noOptionsText="Search for a location..."
        slotProps={{
          paper: {
            sx: (theme) => ({
              fontSize: "14px",
              "& .MuiAutocomplete-noOptions": {
                padding: theme.spacing(1),
              },
            }),
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: 0, marginLeft: 1 }}>
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{
              "& fieldset": {
                boxShadow: "0 1px 2px rgba(60,64,67,0.3),0 2px 6px 2px rgba(60,64,67,0.15)",
                border: "0 !important",
              },
              "& .MuiInputBase-root": { height: "29px", paddingY: "0 !important" },
            }}
          />
        )}
        getOptionLabel={(option) => option.place_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={value}
        onChange={(event: any, newValue: GeocodingSearchResultsFeature | null, reason: string) => {
          setValue(newValue);
          if (newValue) {
            handleGeocodingFlyTo(newValue.center, newValue.place_name);
          }

          if (reason === "clear") {
            handleClearGeocodingSearch();
            handleRemoveSearchMarker();
          }
        }}
        inputValue={searchText}
        onInputChange={(event, newInputValue) => {
          handleChangeSearchText(newInputValue);
        }}
      />
    </Box>
  );
};

export const GeocodingSearch = React.memo(GeocodingSearchBox);

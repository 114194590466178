import { Delete, QueryBuilderOutlined } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button } from "components_new";
import { IconButton } from "components_new";
import { MuiFileInput } from "mui-file-input";
import React, { useEffect, useRef, useState } from "react";

import { useAppDispatch } from "hooks";

import { DataState, LoadingErrorData } from "store/interfaces";
import { datasetFoldersActions } from "store/sections/userFolders";

import { themeColors } from "theme/themeConstants";

import { ShapesInputFormat, UploadZoningResponse } from "types";

import { createZip } from "utils/file";

interface Props {
  zoningUploading: LoadingErrorData<UploadZoningResponse>;
}

const SHAPE_FILE_EXTENSIONS = [
  ".shp",
  ".shx",
  ".dbf",
  ".prj",
  ".sbn",
  ".sbx",
  ".fbn",
  ".fbx",
  ".ain",
  ".aih",
  ".ixs",
  ".mxs",
  ".atx",
  ".xml",
  ".cpg",
];
const GEOJSON_FILE_EXTENSIONS = [".geojson", ".json", ".geojsonl.json", ".geojson.json"];

export const InfoBox = styled(Box)`
  font-size: 11px;
  background: ${themeColors.secondaryLight};
  padding: 8px 16px;
  border-radius: 6px;
  color: ${themeColors.textSecondary};

  .MuiFormControl-root {
    margin-left: 10px;
  }
`;

const ListBox = styled(Box)`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid ${themeColors.border};
  color: ${themeColors.text};

  .MuiTypography-h6 {
    font-size: 16px;
  }
`;

const FileInputWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 20px;
`;

const InlineFormControlWrapper = styled(Box)`
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    margin: 0 10px;
  }
`;

const FormatDescription = styled(Box)`
  font-size: 14px;
`;

export const StyledDialogActions = styled(DialogActions)`
  display: grid;
  grid-template-columns: 1fr auto 100px;
  grid-gap: 20px;
  padding: 16px 0;
`;

export const UploadZoningFiles = ({ zoningUploading }: Props) => {
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<File[] | null>(null);
  const [inputFormats, setInputFormats] = useState<ShapesInputFormat>(ShapesInputFormat.SHAPEFILE);
  const [zipping, setZipping] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (zoningUploading.state === DataState.LOADING && zipping) {
      setZipping(false);
    }
  }, [zoningUploading.state, zipping]);

  const handleFilesChange = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  const handleRemoveFileByIndex = (index: number) => {
    setFiles((files) => {
      if (!files) {
        return files;
      }

      const newFiles = [...files];
      newFiles.splice(index, 1);

      return newFiles;
    });
  };

  const handleUploadFiles = async () => {
    setZipping(true);

    const zipBlob = await createZip(files!);

    dispatch(datasetFoldersActions.uploadZoningShapefiles(zipBlob, inputFormats));
  };

  const handleChangeInputFormat = (event: SelectChangeEvent<ShapesInputFormat>) => {
    setInputFormats(event.target.value as ShapesInputFormat);
    setFiles(null);
  };

  const handleBrowseFilesClick = () => {
    fileInputRef.current?.querySelector("input")?.focus();
    fileInputRef.current?.querySelector("input")?.click();
  };

  const isLoading = zoningUploading.state === DataState.LOADING || zipping;

  return (
    <>
      <InfoBox>
        <InlineFormControlWrapper>
          Zoning file type:
          <FormControl sx={{ minWidth: 120 }}>
            <Select disabled={isLoading} value={inputFormats} onChange={handleChangeInputFormat}>
              <MenuItem value={ShapesInputFormat.SHAPEFILE}>Shapefile</MenuItem>
              <MenuItem value={ShapesInputFormat.GEOJSON}>GeoJson</MenuItem>
            </Select>
          </FormControl>
          {inputFormats === ShapesInputFormat.SHAPEFILE && (
            <FormatDescription>
              please select all files for the shapefile to be uploaded
              <br />
              required: .shp, .shx, .dbf; if available: .prj and .cpg; .prj is required if coordinate system is not
              WGS84
            </FormatDescription>
          )}
          {inputFormats === ShapesInputFormat.GEOJSON && (
            <FormatDescription>please select a single GeoJson file (WGS84)</FormatDescription>
          )}
        </InlineFormControlWrapper>
      </InfoBox>
      <FileInputWrapper>
        <FormControl sx={{ m: "20px 0" }}>
          <MuiFileInput
            ref={fileInputRef}
            disabled={isLoading}
            placeholder="Insert files"
            size="small"
            value={files || []}
            onChange={handleFilesChange}
            inputProps={{
              accept:
                inputFormats === ShapesInputFormat.SHAPEFILE
                  ? SHAPE_FILE_EXTENSIONS.join(",")
                  : GEOJSON_FILE_EXTENSIONS.join(","),
            }}
            multiple
          />
        </FormControl>
        <FormControl sx={{ mt: "19px" }}>
          <Button size="medium" color="secondary" onClick={handleBrowseFilesClick} disabled={isLoading}>
            Browse...
          </Button>
        </FormControl>
      </FileInputWrapper>
      <ListBox sx={{ height: "400px" }}>
        <Typography variant="h6" component="div">
          {files?.length ? "Selected files:" : "No files selected"}
        </Typography>
        {files && (
          <List sx={{ maxHeight: "275px", overflowY: "auto" }} dense>
            {files.map((file, i) => (
              <ListItem
                key={i}
                secondaryAction={
                  <IconButton
                    disabled={isLoading}
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveFileByIndex(i)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        )}
      </ListBox>
      <StyledDialogActions>
        {zoningUploading.state === DataState.ERROR ? (
          <Alert severity={"error"}>Error: {zoningUploading.error?.body?.what || ""}</Alert>
        ) : isLoading ? (
          <Alert severity="info" icon={<QueryBuilderOutlined fontSize="inherit" />}>
            Files are being uploaded and validated, please wait…
          </Alert>
        ) : (
          <div />
        )}
        <div />
        <Button
          loading={isLoading}
          size="medium"
          color="secondary"
          disabled={!files || files.length === 0 || isLoading}
          onClick={handleUploadFiles}
        >
          Upload
        </Button>
      </StyledDialogActions>
    </>
  );
};

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface DimensionCodeProps {
  elements: Record<string, string>;
}

export const DimensionListWithCodes: React.FC<DimensionCodeProps> = ({ elements }) => {
  const maxKeyLength = Math.max(...Object.keys(elements).map((key) => String(key).length));

  return (
    <List dense={true}>
      {Object.entries(elements).map(([key, value]) => (
        <ListItem
          key={key}
          sx={{ display: "list-item" }}
          style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, margin: 0, display: "flex" }}
        >
          <div style={{ width: `${maxKeyLength * 10}px`, textAlign: "center", marginRight: "7px" }}>{key}</div>
          <ListItemText style={{ lineHeight: 0.7, margin: 0 }} primary={value} />
        </ListItem>
      ))}
    </List>
  );
};

export {};

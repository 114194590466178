import React, { createContext, useContext } from "react";

import memoryStore from "./memoryStore";

const MemoryStoreContext = createContext(memoryStore);

// Custom hook to use the memory store
export const useMemoryStore = () => {
  return useContext(MemoryStoreContext);
};

export const MemoryStoreProvider = ({ children }: any) => {
  return <MemoryStoreContext.Provider value={memoryStore}>{children}</MemoryStoreContext.Provider>;
};

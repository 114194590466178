import { AddCircleOutline, Cancel, Error, RemoveCircleOutline, Warning } from "@mui/icons-material";
import {
  Box,
  Chip,
  ChipProps,
  Grid,
  Grow,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useMemoryStore } from "api/MemoryStoreContext";
import { IntersectionDirection, ScreenlineValidationMessage, SegmentIntersection } from "api/analytics/index.d";
import { MemoryStoreKeys } from "api/memoryStore";
import { DirectionIcon, IconButton } from "components_new";
import { isEqual, uniqWith } from "lodash";
import React, { ForwardedRef, forwardRef, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions, selectSelectedScreenline } from "store/sections/screenlines";

import { getIntersectionLabel } from "./utils";

export interface IntersectionGroupElemet {
  intersectionId: number;
  intersection: SegmentIntersection;
  candidate: boolean;
  resolved: boolean;
  segmentIdx: number | null;
  validationMessages?: ScreenlineValidationMessage[];
}

type IntersectionGroupType = IntersectionGroupElemet[];

export interface ScreenlineIntersectionProps {
  intersectionGroup: IntersectionGroupType;
  leftLabel: string;
  rightLabel: string;
  selected: boolean;
  intersectionDirectionFilter: IntersectionDirection | undefined;
  editMode?: boolean;
}

const StyledChip = styled((props: ChipProps) => <Chip size="small" variant="outlined" {...props} />)(({ theme }) => ({
  paddingLeft: "4px",
  height: "auto",
  width: "100%",
  maxWidth: "150px",
  backgroundColor: "#fff !important",
  border: `1px solid ${theme.palette.secondary.light}`,
  color: theme.palette.text.secondary,

  "&.MuiChip-colorSuccess": {
    border: `1px solid ${theme.palette.screenline.candidate}`,
    color: theme.palette.grey[800],
    "& .MuiChip-deleteIcon": {
      color: `${theme.palette.screenline.candidate}`,
      "&:hover": {
        color: theme.palette.grey[800],
      },
    },
  },
  "&.MuiChip-colorWarning": {
    border: `1px solid ${theme.palette.screenline.line}`,
    color: theme.palette.grey[800],
    "& .MuiChip-deleteIcon": {
      color: `${theme.palette.screenline.line}`,
      "&:hover": {
        color: theme.palette.grey[800],
      },
    },
  },
  "&.MuiChip-colorError": {
    border: `1px solid ${theme.palette.screenline.unresolved}`,
    color: theme.palette.grey[800],
    "& .MuiChip-deleteIcon": {
      color: `${theme.palette.screenline.unresolved}`,
      "&:hover": {
        color: theme.palette.grey[800],
      },
    },
  },
}));

const ValidationMessagesTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[3],
    fontSize: 11,
    padding: theme.spacing(1),
  },
}));

export const ScreenlineIntersection = forwardRef(
  (
    {
      intersectionGroup,
      leftLabel,
      rightLabel,
      selected,
      intersectionDirectionFilter,
      editMode,
    }: ScreenlineIntersectionProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const dispatch = useAppDispatch();
    const memoryStore = useMemoryStore();

    const roadMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
    const selectedScreenline = useAppSelector(selectSelectedScreenline);

    const { roadName, roadClass } = intersectionGroup[0].intersection;

    const areAllCandidates = intersectionGroup.every((ig) => ig.candidate);

    const addIntersections = (intersections: SegmentIntersection[]) => {
      if (selectedScreenline && intersections.length) dispatch(screenlinesActions.addIntersections(intersections));
    };

    const deleteIntersections = (intersectionIds: number[]) => {
      if (selectedScreenline && intersectionIds.length)
        dispatch(screenlinesActions.deleteIntersections(intersectionIds));
    };

    const [validationMessages, validationMessageMaxSeverity] = useMemo(() => {
      const validationMessages = uniqWith(
        [
          ...(intersectionGroup[0]?.validationMessages?.map(({ severity, text }) => ({ severity, text })) || []),
          ...(intersectionGroup[1]?.validationMessages?.map(({ severity, text }) => ({ severity, text })) || []),
        ],
        isEqual,
      );

      const validationMessageMaxSeverity = validationMessages.find((m) => m.severity === "error") ? "error" : "warning";

      return [validationMessages, validationMessageMaxSeverity];
    }, [intersectionGroup]);

    return (
      <Grid ref={ref} container alignItems={"center"} padding={"4px 4px 8px 4px"}>
        <Grid item xs={11}>
          <Box width={"100%"}>
            <Typography variant="body2" noWrap maxWidth={320} marginLeft={0.5}>
              {roadName || "No name"}{" "}
              <Typography variant="caption" fontStyle={"italic"} color={"text.secondary"} noWrap>
                - {roadMetadata.data?.roadClasses.find((r) => r.id === roadClass)?.label || ""}
              </Typography>
            </Typography>

            <Grid container alignItems={"center"} marginTop={"4px"} columnGap={0.5}>
              <Grid item>
                <Stack spacing={0.5} direction={"row"}>
                  {[...intersectionGroup]
                    .sort((a, b) => (a.intersection.intersection.direction === "left" ? 1 : -1))
                    .map(({ intersection, candidate, resolved, segmentIdx, intersectionId }, i) => {
                      const roadVolume = memoryStore.getItem(MemoryStoreKeys.ROADS_SEGMENT_VOLUMES).get(segmentIdx);

                      return (
                        <StyledChip
                          sx={{ borderWidth: selected ? "2px !important" : "1px !important" }}
                          key={i}
                          color={!resolved ? "error" : !editMode ? "default" : candidate ? "success" : "warning"}
                          icon={<DirectionIcon direction={intersection.segmentDirection} fontSize="inherit" />}
                          label={
                            <>
                              <Typography
                                noWrap
                                fontSize={12}
                                fontWeight={700}
                                sx={{
                                  textDecoration:
                                    intersectionDirectionFilter &&
                                    intersectionDirectionFilter !== intersection.intersection.direction
                                      ? "line-through"
                                      : undefined,
                                }}
                              >
                                {!resolved ? "-" : roadVolume?.toLocaleString("en-US") || "0"}
                              </Typography>
                              <Typography
                                noWrap
                                fontSize={9}
                                marginTop={-0.5}
                                sx={{
                                  textDecoration:
                                    intersectionDirectionFilter &&
                                    intersectionDirectionFilter !== intersection.intersection.direction
                                      ? "line-through"
                                      : undefined,
                                }}
                              >
                                {getIntersectionLabel(intersection, leftLabel, rightLabel)}
                              </Typography>
                            </>
                          }
                          deleteIcon={
                            <Grow in={editMode && selected}>
                              <Tooltip
                                title={candidate ? "Add" : "Remove"}
                                arrow={false}
                                placement="left"
                                disableHoverListener
                              >
                                {candidate ? (
                                  <AddCircleOutline fontSize="inherit" />
                                ) : (
                                  <RemoveCircleOutline fontSize="inherit" />
                                )}
                              </Tooltip>
                            </Grow>
                          }
                          onDelete={() =>
                            candidate ? addIntersections([intersection]) : deleteIntersections([intersectionId])
                          }
                        />
                      );
                    })}
                </Stack>
              </Grid>
              <Grid item xs={"auto"}>
                <Grow in={intersectionGroup.length > 1 && editMode && selected}>
                  <IconButton
                    onClick={() =>
                      areAllCandidates
                        ? addIntersections(intersectionGroup.map((ig) => ig.intersection))
                        : deleteIntersections(intersectionGroup.map((ig) => ig.intersectionId))
                    }
                  >
                    {areAllCandidates ? (
                      <AddCircleOutline
                        fontSize="medium"
                        sx={(theme) => ({
                          color: `${theme.palette.screenline.candidate}`,
                          "&:hover": { color: theme.palette.grey[800] },
                        })}
                      />
                    ) : (
                      <RemoveCircleOutline
                        fontSize="medium"
                        sx={(theme) => ({
                          color: intersectionGroup.find((int) => !int.resolved)
                            ? `${theme.palette.screenline.unresolved}`
                            : `${theme.palette.screenline.line}`,
                          "&:hover": { color: theme.palette.grey[800] },
                        })}
                      />
                    )}
                  </IconButton>
                </Grow>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {validationMessages.length ? (
          <Grid item xs={1} container alignItems={"center"} fontSize={20}>
            <ValidationMessagesTooltip
              placement="bottom-start"
              title={
                <Stack>
                  {validationMessages.map((msg, i) => (
                    <Grid key={i} container alignItems={"center"}>
                      {msg.severity === "error" ? (
                        <Error fontSize="inherit" color="error" />
                      ) : (
                        <Warning fontSize="inherit" color="warning" />
                      )}
                      <Typography fontSize={"inherit"} color={"inherit"} marginLeft={0.5}>
                        {msg.text}
                      </Typography>
                    </Grid>
                  ))}
                </Stack>
              }
              sx={{
                "&.MuiTooltip-tooltip": {
                  maxWidth: "600px",
                  backgroundColor: "#fff",
                },
              }}
            >
              {!intersectionGroup[0].resolved ? (
                <Cancel
                  fontSize="inherit"
                  sx={(theme) => ({
                    color: theme.palette.screenline.unresolved,
                  })}
                />
              ) : validationMessageMaxSeverity === "error" ? (
                <Error fontSize="inherit" color="error" />
              ) : (
                <Warning fontSize="inherit" color="warning" />
              )}
            </ValidationMessagesTooltip>
          </Grid>
        ) : null}
      </Grid>
    );
  },
);

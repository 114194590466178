import { Check, Clear, Edit } from "@mui/icons-material";
import { Box, Paper, Stack, SxProps, Typography } from "@mui/material";
import { Button, IconButton } from "components_new";
import { FC, ReactNode } from "react";

export interface EditPaperProps {
  children: ReactNode;
  title: string;
  editMode: boolean;
  confirmDisabled?: boolean;
  editDisabled?: boolean;
  handleSetEditMode: (editMode: boolean) => void;
  handleApply: () => void;
  handleCancel: () => void;
  sx?: SxProps;
}

export const EditPaper: FC<EditPaperProps> = ({
  children,
  title,
  editMode,
  confirmDisabled,
  editDisabled,
  sx,
  handleSetEditMode,
  handleApply,
  handleCancel,
}) => {
  return (
    <Box sx={{ paddingTop: 1, marginTop: 0.5 }}>
      <Paper
        variant="outlined"
        sx={{
          ...sx,
          height: "100%",
          position: "relative",
          paddingTop: 1,
          backgroundColor: "grey.50",
        }}
      >
        <Typography
          fontSize={11}
          fontWeight={500}
          sx={{
            position: "absolute",
            top: "-12px",
            left: "8px",
            paddingX: 1,
            backgroundColor: "#ffffff",
            border: 1,
            borderColor: "divider",
            borderRadius: "4px",
            padding: "1px 4px",
            height: "20px",
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            position: "absolute",
            top: "-10px",
            right: "16px",
            backgroundColor: "#ffffff",
            border: 1,
            borderColor: "divider",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            height: "20px",
          }}
        >
          {editMode ? (
            <Stack direction={"row"} spacing={1} paddingX={1}>
              <IconButton color="success" disabled={confirmDisabled} onClick={handleApply}>
                <Check fontSize="small" />
              </IconButton>
              <IconButton color="error" onClick={handleCancel}>
                <Clear fontSize="small" />
              </IconButton>
            </Stack>
          ) : (
            <Button
              variant="text"
              color="secondary"
              sx={{
                padding: 0,
                margin: 0,
                "& .buttonLabel": { fontSize: 11, fontWeight: 500 },
              }}
              startIcon={<Edit sx={{ fontSize: "11px !important" }} />}
              onClick={() => handleSetEditMode(true)}
              disabled={editDisabled}
            >
              Edit
            </Button>
          )}
        </Box>

        {children}
      </Paper>
    </Box>
  );
};

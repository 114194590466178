import { LicenseType, LicenseTypeResponse } from "types";

import RestHandler from "./RestHandler";

export interface LicenseApiType {
  getSoftwareLicenseTypes(): Promise<LicenseType[]>;
}

export default function LicenseApi(restHandler: RestHandler) {
  return {
    async getSoftwareLicenseTypes() {
      const body = await restHandler.get<LicenseTypeResponse>("software-license-type");

      return body.data;
    },
  };
}

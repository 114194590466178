import React, { FC } from "react";
import styled from "@emotion/styled";

interface LegendBaseProps {
  label: string;
  isSquare?: boolean;
}

interface LineProps {
  height: number;
  color: string;
}

export type LegendProps = LegendBaseProps & LineProps;

const LegendContainer = styled.div`
  text-align: center;
`;

const Line = styled.div<LineProps>`
  height: ${({ height }) => `${height}px`};
  width: 32px;
  background-color: ${({ color }) => `${color}`};
  border-radius: 9999px;
`;

const SquareColor = styled.div<LineProps>`
  height: ${({ height }) => `${height}px`};
  width: ${({ height }) => `${height}px`};
  background-color: ${({ color }) => `${color}`};
  border-radius: 4px;
`;

const Label = styled.h5`
  font-weight: 500;
  margin-top: 4px;
`;

export const Legend: FC<LegendProps> = ({ label, height, color, isSquare }) => (
  <LegendContainer>
    {!isSquare && <Line height={height} color={color} />}
    {isSquare && <SquareColor height={height} color={color} />}
    <Label>{label}</Label>
  </LegendContainer>
);

import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";

import { Api, makeApi } from "../api";
import { rootReducer, rootSaga } from "./root";

const api = makeApi();

const sagaMiddleware = createSagaMiddleware<{ api: Api }>({
  context: {
    api,
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

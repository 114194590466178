import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React from "react";

import { Zoning } from "types";

import { convertSquareKmToSquareMiles } from "utils/format";

interface Props {
  zoning: Zoning;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[50],
  },

  "& td": {
    "&:nth-of-type(odd)": {
      fontWeight: 500,
      width: "240px",
    },
  },
}));

const getCombinedTextWithSeparator = (textValues: string[], values: (number | undefined)[]) => {
  let combinedText = "";

  textValues.forEach((text, index) => {
    if (values[index] !== undefined) {
      combinedText += `${text}: ${values[index]} `;
    }
  });

  return combinedText.trim();
};

const isValuesExists = (values: (number | undefined)[]) => {
  return values.some((value) => value !== undefined);
};

const convertToSquareMiles = (squareKm: number | undefined): number | undefined => {
  return squareKm !== undefined ? Number(convertSquareKmToSquareMiles(squareKm).toFixed(2)) : undefined;
};

export const SummaryTab = ({ zoning }: Props) => {
  const {
    featureCount,
    nullGeometryCount,
    emptyGeometryCount,
    invalidGeometryCount,
    multiPartZoneCount,
    avgVertexCount,
    maxAreaSqKm,
    minAreaSqKm,
    maxPartCount,
    maxVertexCount,
    minVertexCount,
    avgAreaSqKm,
    medianAreaSqKm,
  } = zoning.statistics;
  return (
    <Table sx={{ mt: 1, tableLayout: "fixed" }} size="small">
      <TableBody>
        <StyledTableRow key="crsName">
          <TableCell align="left">Coordinate reference system</TableCell>
          <TableCell align="right">
            <Tooltip title={zoning.crsName}>
              <Typography noWrap variant="body2">
                {zoning.crsName}
              </Typography>
            </Tooltip>
          </TableCell>
        </StyledTableRow>
        <StyledTableRow key="featureCount">
          <TableCell align="left">Zone count</TableCell>
          <TableCell align="right">{featureCount}</TableCell>
        </StyledTableRow>
        <StyledTableRow key="invalidGeometryCount">
          <TableCell align="left">Zones with invalid geometries</TableCell>
          <TableCell align="right">{invalidGeometryCount}</TableCell>
        </StyledTableRow>
        <StyledTableRow key="nullEmptyGeometryCount">
          <TableCell align="left">Zones without geometry</TableCell>
          <TableCell align="right">
            null: {nullGeometryCount} empty: {emptyGeometryCount}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow key="vertexCount">
          <TableCell align="left">Vertex count per feature</TableCell>
          <TableCell align="right">
            {getCombinedTextWithSeparator(
              ["min", "avg", "max"],
              [minVertexCount, avgVertexCount ? Number(avgVertexCount.toFixed(2)) : undefined, maxVertexCount],
            )}
          </TableCell>
        </StyledTableRow>
        <StyledTableRow key="multiPartZoneCount">
          <TableCell align="left">Zones with multiple parts</TableCell>
          <TableCell align="right">
            {multiPartZoneCount}
            {maxPartCount ? ` (max: ${maxPartCount} part${maxPartCount === 1 ? "" : "s"})` : ""}
          </TableCell>
        </StyledTableRow>
        {isValuesExists([minAreaSqKm, maxAreaSqKm, avgAreaSqKm, medianAreaSqKm]) && (
          <StyledTableRow key="areaSqKm">
            <TableCell align="left" sx={{ verticalAlign: "top" }}>
              Zone area (square miles)
            </TableCell>
            <TableCell align="right">
              {getCombinedTextWithSeparator(
                ["min", "max"],
                [minAreaSqKm, maxAreaSqKm].map((value) => convertToSquareMiles(value)),
              )}
              <br />
              {getCombinedTextWithSeparator(
                ["avg", "median"],
                [avgAreaSqKm, medianAreaSqKm].map((value) => convertToSquareMiles(value)),
              )}
            </TableCell>
          </StyledTableRow>
        )}
      </TableBody>
    </Table>
  );
};

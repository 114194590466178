import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { DirectionIcon } from "components_new";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import React from "react";

import { VolumeCount } from "components";

import { useAppSelector } from "hooks";

import { LEFT_SIDE_DIRECTIONS } from "types";

import { convertMetersToMiles } from "utils/format";

export interface EdgePopupProps {
  id: string;
  feature: MapboxGeoJSONFeature;
  fromToCounts: number;
  toFromCounts: number;
}

const CountContainer = styled.table`
  min-width: 30px;
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid var(--color-border);
`;

const isEdgeInRange = (count: number, range: [number, number] | null) => {
  if (!range || (count >= range[0] && count <= range[1])) return true;

  return false;
};

export const EdgePopupContent = ({ id, feature, fromToCounts, toFromCounts }: EdgePopupProps) => {
  const corridorEdgeRange = useAppSelector((state) => state.corridor.corridorEdgeRange);
  const distance = convertMetersToMiles(feature?.properties?.length_m || 0);

  return (
    <>
      <CountContainer>
        <tbody>
          <tr>
            {toFromCounts > 0 && LEFT_SIDE_DIRECTIONS.indexOf(feature.properties?.tf_dir) !== -1 ? (
              <VolumeCount
                icon={<DirectionIcon direction={feature.properties?.tf_dir} />}
                count={toFromCounts}
                disabled={!isEdgeInRange(toFromCounts, corridorEdgeRange)}
              />
            ) : null}
            {fromToCounts > 0 ? (
              <VolumeCount
                icon={<DirectionIcon direction={feature.properties?.ft_dir} />}
                count={fromToCounts}
                disabled={!isEdgeInRange(fromToCounts, corridorEdgeRange)}
              />
            ) : null}
            {toFromCounts > 0 && LEFT_SIDE_DIRECTIONS.indexOf(feature.properties?.tf_dir) === -1 ? (
              <VolumeCount
                icon={<DirectionIcon direction={feature.properties?.tf_dir} />}
                count={toFromCounts}
                disabled={!isEdgeInRange(toFromCounts, corridorEdgeRange)}
              />
            ) : null}
          </tr>
        </tbody>
      </CountContainer>

      <Typography sx={{ fontSize: 12, textAlign: "right", padding: "0.25rem 0.5rem" }}>
        Distance: {distance.toFixed(2)} mile
        {distance < 1 || distance >= 2 ? "s" : ""}
      </Typography>
    </>
  );
};

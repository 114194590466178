import { FC, memo } from "react";

import { ODLegend } from "components";

interface RoadVmtLegendProps {
  colorScaleFn: Function;
  colorScheme: string;
}

export const RoadVmtLegend: FC<RoadVmtLegendProps> = memo(({ colorScaleFn, colorScheme }) => {
  return (
    <ODLegend
      colorSchemeName={colorScheme}
      scale={colorScaleFn}
      title="Road Vehicle Miles Traveled"
      subtitle="Vehicle miles traveled per square mile"
    />
  );
});

import { FC, Fragment } from "react";
import styled from "@emotion/styled";

export interface FilterListProps {
  filters: { [key: string]: string[] } | undefined;
}

export const List = styled.ul`
  margin: 0 0 0.5rem 1.5rem;
  list-style-type: square;
`;

export const ListItem = styled.li`
  font-size: 11px;
`;

const Label = styled.p`
  margin-bottom: 0.25rem;
  font-size: 12px;
  font-weight: 600;
`;

export const FilterList: FC<FilterListProps> = ({ filters }) => {
  return (
    <>
      {Object.keys(filters || {}).length ? (
        Object.entries(filters || {}).map(([filterLabel, activeItems], i) => {
          return (
            <Fragment key={i}>
              <Label>{filterLabel}</Label>
              <List>
                {activeItems.map((itemLabel, i) => (
                  <ListItem key={i}>{itemLabel}</ListItem>
                ))}
              </List>
            </Fragment>
          );
        })
      ) : (
        <Label>No filters applied</Label>
      )}
    </>
  );
};

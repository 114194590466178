export const selectLinkQueries = [
  {
    id: "1",
    query: "Segment A",
    interpretation: "All trips that pass at least once through segment A.",
  },
  {
    id: "2",
    query: "Segment A AND Segment B",
    interpretation:
      "All trips that pass at least once through both Segment A and B, independent of the order of A and B.",
  },
  {
    id: "3",
    query: "Segment A OR Segment B",
    interpretation:
      "All trips that pass at least once through Segment A or Segment B, independent of the order of A and B. There can be arbitrary many additional Segments added to this list with extra “OR” operators.",
  },
  {
    id: "4",
    query: "(Segment A OR Segment B) AND (Segment C OR Segment D)",
    interpretation:
      "All trips that pass at least once through Segment A or Segment B (independent of their order) and at least once through Segment C or D (independent of their order). There can be arbitrary many additional Segments added to this list with extra “OR” operators within the parentheses and extra “AND” operators between the parentheses.",
  },
  {
    id: "5",
    query: "(Segment A AND Segment B) OR (Segment C AND Segment D)",
    interpretation:
      "All trips that pass at least once through Segment A and Segment B (independent of their order) or at least once through Segment C and D (independent of their order). There can be arbitrary many additional Segments added to this list with extra “AND” operators within the parentheses and extra “OR” operators between the parentheses.",
  },
];

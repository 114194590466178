import styled from "@emotion/styled";
import { FC } from "react";

interface EmptyStateProps {
  title?: string;
  message?: string;
}

const ChartTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: var(--font-size-sm);
  font-weight: bold;
`;

const ChartWrapper = styled.div`
  width: 100%;
  padding: 35px 15px 0 5px;
`;

const EmptyStateContainer = styled.div`
  padding: 8px 0 8px 15px;
`;

export const BarChartEmptyState: FC<EmptyStateProps> = ({ title = "", message }) => {
  return (
    <div style={{ position: "relative" }}>
      <ChartTitle>{title}</ChartTitle>
      <ChartWrapper>
        <EmptyStateContainer>{message}</EmptyStateContainer>
      </ChartWrapper>
    </div>
  );
};

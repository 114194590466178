import { SegmentsGroup, SelectedSegmentConfig } from "types";

export const generateRandomColor = (): string => {
  return "#" + (~~(Math.random() * 2 ** 24)).toString(16).padStart(6, "0");
};

export const isLastSegmentInGroup = (group: SegmentsGroup, segment: SelectedSegmentConfig): boolean => {
  if (group.segments.length === 0) return true;
  return group.segments[group.segments.length - 1].segmentId === segment.segmentId;
};

export const isLastSegmentsGroup = (groups: SegmentsGroup[], group: SegmentsGroup): boolean => {
  if (groups.length <= 1) return true;
  return groups[groups.length - 1].groupName === group.groupName;
};

export const selectColorOrGenerate = (takenColors: Set<string>): string => {
  return colorsPool.find((color) => !takenColors.has(color)) || generateRandomColor();
};

const colorsPool = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#800080",
  "#008000",
  "#008080",
  "#800000",
  "#808000",
  "#FFD700",
  "#A0522D",
  "#FF1493",
  "#4B0082",
  "#00FF7F",
  "#8B0000",
  "#FF4500",
  "#2E8B57",
  "#8A2BE2",
  "#48D1CC",
  "#FF69B4",
  "#556B2F",
  "#9932CC",
  "#00CED1",
  "#6A5ACD",
  "#228B22",
  "#FF6347",
  "#8B008B",
];

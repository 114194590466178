import { Close } from "@mui/icons-material";
import { Box, Grid, ListItemIcon, ListItemText, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { Button, Checkbox, Divider, IconButton, TextField } from "components_new";
import React, { ChangeEvent, FC, ReactNode } from "react";

export interface CheckboxDropdownItem {
  label: string;
  isChecked: boolean;
}

export type CheckboxDropdownItems = {
  [key: string]: CheckboxDropdownItem;
};

export interface CheckboxDropdownProps {
  isGroupChecked: boolean;
  groupName: string;
  groupLabel: string;
  groupIcon: ReactNode;
  items: CheckboxDropdownItems;
  error: boolean;
  disabled?: boolean;
  placement?: "bottom" | "top";
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectAll: (groupName: string) => void;
  clearAll: (groupName: string) => void;
}

export const CheckboxDropdown: FC<CheckboxDropdownProps> = ({
  isGroupChecked,
  groupName,
  groupLabel,
  groupIcon,
  items,
  error,
  placement,
  disabled,
  onChange,
  selectAll,
  clearAll,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const generateInputValue = (items: CheckboxDropdownItems) => {
    let str = "";
    let count = 0;
    Object.values(items).forEach((item, i) => {
      if (item.isChecked) {
        if (!str) {
          str += item.label;
        } else {
          count++;
        }
      }
    });
    return (str += count > 0 ? ` + ${count} more` : "");
  };

  return (
    <>
      <TextField
        fullWidth
        select
        disabled={disabled}
        error={error}
        label={groupLabel}
        value={1}
        onClick={handleClick}
        SelectProps={{ open: false }}
      >
        <MenuItem value={1}>
          <Grid container alignItems={"center"} wrap="nowrap">
            <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>{groupIcon}</ListItemIcon>
            <ListItemText
              primary={isGroupChecked ? "All" : generateInputValue(items)}
              primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
              sx={{ margin: 0 }}
            />
          </Grid>
        </MenuItem>
      </TextField>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: placement === "top" ? "bottom" : "top",
          horizontal: "left",
        }}
      >
        <Box width={240}>
          <Grid container justifyContent={"space-between"} padding={1}>
            <Typography fontSize={14} fontWeight={700} maxWidth={200} marginLeft={1} noWrap>
              {groupLabel}
            </Typography>
            <IconButton size="small" onClick={handleClose}>
              <Close fontSize="inherit" />
            </IconButton>
          </Grid>
          <Divider />
          <Box sx={{ overflowY: "auto", maxHeight: "300px" }}>
            <Stack spacing={1} justifyContent={"center"} padding={1}>
              {Object.entries(items).map(([itemKey, item], i) => (
                <Checkbox
                  key={i}
                  name={`${groupName}-${itemKey}`}
                  label={item.label}
                  checked={item.isChecked}
                  onChange={onChange}
                  color="secondary"
                />
              ))}
            </Stack>
          </Box>
          <Divider />

          <Stack direction={"row"} spacing={1} justifyContent={"space-between"} paddingX={1} paddingY={0.5}>
            <Button variant="text" onClick={() => clearAll(groupName)}>
              Clear all
            </Button>
            <Button variant="text" onClick={() => selectAll(groupName)}>
              Select all
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

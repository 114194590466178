import { ArrowForward, Info } from "@mui/icons-material";
import { alpha, styled } from "@mui/material";
import { IconButton } from "components_new";
import React, { FC, ReactNode } from "react";

import { FlexContainer } from "components";

export interface RightSidebarPanelProps {
  children?: ReactNode;
  isOpen: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  cardType?: string;
  onClose?: () => void;
}

const RightSidebarContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  zIndex: 9,
  minWidth: "var(--right-sidebar-width)",
  maxWidth: "var(--right-sidebar-width)",
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  transform: isOpen ? "translateX(0)" : "translateX(110%)",
  transition: "0.5s ease",
  paddingBottom: theme.spacing(2),
  boxShadow: "0 1px 2px rgba(60,64,67,0.3),0 2px 6px 2px rgba(60,64,67,0.15)",
}));

const CardHeader = styled(FlexContainer)(({ theme }) => ({
  justifyContent: "space-between",
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const CardTitle = styled(FlexContainer)`
  font-weight: 600;
  & svg {
    margin-right: 0.5rem;
  }
`;

const CardSubtitle = styled(FlexContainer)(({ theme }) => ({
  justifyContent: "space-between",
  fontSize: "0.8rem",
  fontWeight: 500,
  color: theme.palette.text.secondary,
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: "40px",
}));

export const RightSidebarPanel: FC<RightSidebarPanelProps> = ({
  children,
  isOpen,
  title,
  subtitle,
  cardType,
  onClose,
}) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <RightSidebarContainer isOpen={isOpen}>
      <CardHeader>
        <CardTitle>
          <Info color="secondary" />
          <span>{title}</span>
        </CardTitle>
        <IconButton onClick={handleClose}>
          <ArrowForward />
        </IconButton>
      </CardHeader>
      <CardSubtitle>{subtitle}</CardSubtitle>
      {children}
    </RightSidebarContainer>
  );
};

export const CrossSvg = () => (
  <svg
    className="recharts-surface"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
  >
    <title></title>
    <desc></desc>
    <path
      fill="#ff0000"
      cx="16"
      cy="16"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M-16,-5.333L-5.333,-5.333L-5.333,-16L5.333,-16L5.333,-5.333L16,-5.333L16,5.333L5.333,5.333L5.333,16L-5.333,16L-5.333,5.333L-16,5.333Z"
    ></path>
  </svg>
);

export const DiamondSvg = () => (
  <svg
    className="recharts-surface"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
  >
    <title></title>
    <desc></desc>
    <path
      fill="#ffea00"
      cx="16"
      cy="16"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M0,-16L9.238,0L0,16L-9.238,0Z"
    ></path>
  </svg>
);

export const SquareSvg = () => (
  <svg
    className="recharts-surface"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
  >
    <title></title>
    <desc></desc>
    <path
      fill="#03b051"
      cx="16"
      cy="16"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M-16,-16h32v32h-32Z"
    ></path>
  </svg>
);

export const TriangleSvg = () => (
  <svg
    className="recharts-surface"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
  >
    <title></title>
    <desc></desc>
    <path
      fill="#3718f7"
      cx="16"
      cy="16"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M0,-18.475L16,9.238L-16,9.238Z"
    ></path>
  </svg>
);

export const StarSvg = () => (
  <svg
    className="recharts-surface"
    width="14"
    height="14"
    viewBox="0 0 32 32"
    style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
  >
    <title></title>
    <desc></desc>
    <path
      fill="#787878"
      cx="16"
      cy="16"
      className="recharts-symbols"
      transform="translate(16, 16)"
      d="M0,-16.823L3.777,-5.199L16,-5.199L6.111,1.986L9.889,13.61L0,6.426L-9.889,13.61L-6.111,1.986L-16,-5.199L-3.777,-5.199Z"
    ></path>
  </svg>
);

import { AnySourceData } from "mapbox-gl";

export const CORRIDOR_EDGE_SOURCE_ID = "CORRIDOR_EDGE_SOURCE_ID";
export const CORRIDOR_NODE_SOURCE_ID = "CORRIDOR_NODE_SOURCE_ID";

export const getCorridorSources = (corridorLevels: any) => {
  const corridorSources: {
    id: string;
    source: AnySourceData;
  }[] = [];

  Object.keys(corridorLevels).forEach((zoningLevelId) => {
    const zoningLevel = corridorLevels[zoningLevelId];

    corridorSources.push({
      id: `${CORRIDOR_EDGE_SOURCE_ID}_${zoningLevelId}`,
      source: {
        type: "vector",
        tiles: [zoningLevel.edgeTileServiceLayer.url + "/{z}/{x}/{y}.pbf"],
        promoteId: zoningLevel.edgeTileServiceLayer.idField,
        minzoom: zoningLevel.edgeTileServiceLayer.minZoom,
        maxzoom: zoningLevel.edgeTileServiceLayer.maxZoom,
      } as AnySourceData,
    });

    corridorSources.push({
      id: `${CORRIDOR_NODE_SOURCE_ID}_${zoningLevelId}`,
      source: {
        type: "vector",
        tiles: [zoningLevel.nodeTileServiceLayer.url + "/{z}/{x}/{y}.pbf"],
        promoteId: zoningLevel.nodeTileServiceLayer.idField,
        minzoom: zoningLevel.nodeTileServiceLayer.minZoom,
        maxzoom: zoningLevel.nodeTileServiceLayer.maxZoom,
      } as AnySourceData,
    });
  });

  return corridorSources;
};

import { styled } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { AnalyticMap } from "features/map/AnalyticMap";
import { LoadingSpinnerWrapper } from "features/map/LoadingSpinnerWrapper";
import { ModuleManager } from "features/map/ModuleManager";

import {
  ODPopup,
  ODPopupProps,
  PopupWrapper,
  RoadsHoverPopup,
  RoadsHoverPopupProps,
  VolumePopupContent,
  VolumePopupContentProps,
} from "components";

import { useAppDispatch, useAppSelector, usePageTracking, useResetMap } from "hooks";

import { selectLinkActions } from "store/sections/selectLink";

const MapPageContainer = styled("div")`
  position: relative;
  display: grid;
  grid-template-columns: 280px 1fr 280px;
  grid-template-rows: 100%;
  grid-template-areas: "left-sidebar map right-sidebar";
  height: 100%;
  overflow: hidden;
`;

export const SelectLinkPage = () => {
  const dispatch = useAppDispatch();
  const { configId } = useParams();

  const [mapLoaded, setMapLoaded] = useState(false);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const map = useRef(null);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  const ODPopupRef = useRef<HTMLDivElement>(null);
  const setODPopupPropsRef = useRef<Dispatch<SetStateAction<ODPopupProps | null>>>(null);
  const roadsPopupRef = useRef<HTMLDivElement>(null);
  const setRoadsPopupRef = useRef<Dispatch<SetStateAction<RoadsHoverPopupProps | null>>>(null);
  const roadsVolumesPopupRef = useRef<HTMLDivElement>(null);
  const setRoadsVolumesPopupRef = useRef<Dispatch<SetStateAction<VolumePopupContentProps | null>>>(null);

  usePageTracking();

  useResetMap(map, mapLoaded, setMapLoaded, [selectedFocusAreaId, baseMapStyle]);

  // Fetch select link configuration
  useEffect(() => {
    if (configId && isTokenLoaded) {
      dispatch(selectLinkActions.fetchSelectLinkConfig(configId));
    }

    return () => {
      dispatch(selectLinkActions.clearSelectLinkSegmentCounts());
      dispatch(selectLinkActions.clearNewSelectLinkConfig());
      dispatch(selectLinkActions.clearSelectLinkConfig());
    };
  }, [configId, isTokenLoaded, dispatch]);

  return (
    <MapPageContainer>
      <AnalyticMap map={map} mapLoaded={mapLoaded} onMapLoaded={setMapLoaded}>
        <PopupWrapper
          popupRef={ODPopupRef}
          setPopupRef={setODPopupPropsRef}
          renderPopupContent={(ODPopupProps) => (
            <ODPopup
              count={ODPopupProps.count}
              type={ODPopupProps.type}
              countByDensity={ODPopupProps.countByDensity}
              gateId={ODPopupProps.gateId}
              flowInfo={ODPopupProps.flowInfo}
            />
          )}
        />
        <PopupWrapper
          popupRef={roadsPopupRef}
          setPopupRef={setRoadsPopupRef}
          renderPopupContent={(roadsPopupProps) => (
            <RoadsHoverPopup
              volume={roadsPopupProps.volume}
              gateId={roadsPopupProps.gateId}
              isPedestriansMode={false}
            />
          )}
        />
        <PopupWrapper
          popupRef={roadsVolumesPopupRef}
          setPopupRef={setRoadsVolumesPopupRef}
          renderPopupContent={(volumePopupProps) => (
            <VolumePopupContent
              selectedVolume={volumePopupProps.selectedVolume}
              volume={volumePopupProps.volume}
              onHover={volumePopupProps.onHover}
              onClick={volumePopupProps.onClick}
              isPedestriansMode={volumePopupProps.isPedestriansMode}
            />
          )}
        />
      </AnalyticMap>
      {mapLoaded ? (
        <ModuleManager
          map={map}
          ODPopupRef={ODPopupRef}
          setODPopupPropsRef={setODPopupPropsRef}
          roadsPopupRef={roadsPopupRef}
          setRoadsPopupRef={setRoadsPopupRef}
          roadsVolumesPopupRef={roadsVolumesPopupRef}
          setRoadsVolumesPopupRef={setRoadsVolumesPopupRef}
          setVolumesLoading={() => {}} // @TODO implement loading visibility for road segments
        />
      ) : null}
      <LoadingSpinnerWrapper />
    </MapPageContainer>
  );
};

import styled from "@emotion/styled";
import React, { FC, MouseEvent } from "react";

import { LinkButton, NavButton } from "components";

export interface NavItem {
  label: string;
  url: string;
  newTab?: boolean;
  disabled?: boolean;
  subItems?: NavItem[];
  action?: () => void;
}

export interface NavProps {
  navItems: NavItem[];
}

const Nav = styled.nav<{ navItemsLength: number }>`
  display: grid;
  grid-template-columns: ${({ navItemsLength }) => `repeat(${navItemsLength}, auto)`};
  grid-column-gap: 0.5rem;
  margin-left: 1.5rem;

  @media only screen and (min-width: 1200px) {
    margin-left: 80px;
  }
`;

const SubNav = styled.nav`
  display: none;
  position: absolute;
  top: 35px;
  left: 0;
  background-color: var(--color-text);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 20;
  flex-direction: column;
  padding: 0.5rem;
  box-shadow: var(--box-shadow-lg);
`;

const TopMenuContainer = styled.div`
  position: relative;
  border-radius: 6px;

  &:hover {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .sub-menu {
      display: flex;
    }
  }
`;

const SubNavButton = styled(NavButton)`
  margin: 4px 0;
  border: 0;
  padding: 4px 10px;
  background-color: transparent;
  color: var(--color-textContrast);
  font-size: var(--font-size-xs);
  justify-content: flex-start;

  &.active {
    background-color: var(--color-text);
  }
`;

export const NavMenu: FC<NavProps> = ({ navItems }) => {
  const handleNavLink = (e: MouseEvent<HTMLAnchorElement>, isDisabled: boolean, callback?: () => void) => {
    if (isDisabled || callback) e.preventDefault();
    if (callback && !isDisabled) callback();
  };

  return (
    <Nav navItemsLength={navItems.length}>
      {navItems.map(({ label, url, newTab, disabled, subItems, action }, i) =>
        newTab ? (
          <LinkButton key={i} onClick={() => window.open(url, "_blank")?.focus()}>
            {label}
          </LinkButton>
        ) : !subItems ? (
          <NavButton key={i} to={url} onClick={(e) => handleNavLink(e, Boolean(disabled), action)}>
            {label}
          </NavButton>
        ) : (
          <TopMenuContainer key={i}>
            <NavButton className="sub-nav-anchor-button" to={url} onClick={(e) => handleNavLink(e, true, action)}>
              {label}
            </NavButton>
            <SubNav className="sub-menu">
              {subItems?.map(
                ({ label: subItemLabel, url: subItemUrl, action: subItemAction, disabled: subItemDisabled }, i) => (
                  <SubNavButton
                    key={i}
                    to={subItemUrl}
                    onClick={(e) => handleNavLink(e, Boolean(subItemDisabled), subItemAction)}
                  >
                    {subItemLabel}
                  </SubNavButton>
                ),
              )}
            </SubNav>
          </TopMenuContainer>
        ),
      )}
    </Nav>
  );
};

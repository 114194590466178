import { Layer } from "mapbox-gl";

import { BOUNDARIES_SOURCE_ID } from "./sources";

export const BOUNDARIES_LAYER_ID = "BOUNDARIES_LAYER_ID";

export const getCommonLayers = () => {
  return [
    {
      id: BOUNDARIES_LAYER_ID,
      type: "line",
      source: BOUNDARIES_SOURCE_ID,
      paint: {
        "line-color": "#111827",
        "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 3, 19, 20],
        "line-opacity": 0.6,
      },
      layout: {
        "line-cap": "round",
      },
    },
  ] as Layer[];
};

import { styled } from "@mui/material";
import * as React from "react";

import { Badge, BadgeProps } from "../index";

export interface CountBadgeProps extends Omit<BadgeProps, "color" | "variant" | "size"> {
  label: number;
  loading?: boolean;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: "100%",
  margin: `2px 0`,

  "& .MuiChip-icon": {
    margin: `0 ${theme.spacing(0.5)}`,
    color: theme.palette.secondary.main,
  },
}));

export const CountBadge: React.FC<CountBadgeProps> = ({ label = 0, loading, ...props }) => {
  return <StyledBadge color="secondary" label={loading ? "..." : label.toLocaleString("en-US")} {...props} />;
};

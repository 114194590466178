import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  styled,
} from "@mui/material";

export interface CircularProgressProps extends MuiCircularProgressProps {}

export const CircularProgress = styled((props: CircularProgressProps) => (
  <MuiCircularProgress thickness={3} {...props} />
))(({ theme }) => ({}));

import { MenuItem, Stack, ToggleButtonGroup, styled } from "@mui/material";
import { TextField, ToggleButton } from "components_new";
import { ChangeEvent, FC } from "react";

import { CheckboxDropdown } from "components";

import { FiltersType, QueryType } from "types";

import { areAllItemsUnChecked } from "./Filters";

export interface ODFiltersPanelProps {
  filters: FiltersType | null;
  queryType: QueryType;
  loading: boolean;
  disabled?: boolean;
  handleChangeQueryType: (type: QueryType) => void;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAllFilters: (isChecked: boolean) => (groupName: string) => void;
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({ fontSize: 11, padding: "2px 4px" }));

export const ODFiltersPanel: FC<ODFiltersPanelProps> = ({
  filters,
  queryType,
  loading,
  disabled,
  handleChangeQueryType,
  handleChangeFilter,
  handleChangeAllFilters,
}) => {
  const handleQueryTypeChange = (event: React.MouseEvent<HTMLElement>, newQueryType: QueryType) => {
    handleChangeQueryType(newQueryType);
  };

  return (
    <>
      <TextField
        fullWidth
        select
        label="Trip Direction"
        value={0}
        SelectProps={{ open: false, IconComponent: () => <div style={{ marginLeft: "-16px" }} /> }}
        sx={{ marginBottom: 2 }}
      >
        <MenuItem value={0} disableGutters>
          <ToggleButtonGroup fullWidth exclusive value={queryType} onChange={handleQueryTypeChange}>
            <StyledToggleButton value={QueryType.INCOMING}>Incoming</StyledToggleButton>
            <StyledToggleButton value={QueryType.OUTGOING}>Outgoing</StyledToggleButton>
          </ToggleButtonGroup>
        </MenuItem>
      </TextField>
      <Stack spacing={2} maxWidth={248}>
        {filters &&
          Object.entries(filters).map(([groupKey, group], i) => (
            <CheckboxDropdown
              key={i}
              isGroupChecked={group.isChecked}
              groupName={groupKey}
              groupLabel={group.label}
              groupIcon={group.icon}
              items={group.items}
              placement="top"
              disabled={loading || disabled}
              error={areAllItemsUnChecked(group.items)}
              onChange={handleChangeFilter}
              selectAll={handleChangeAllFilters(true)}
              clearAll={handleChangeAllFilters(false)}
            />
          ))}
      </Stack>
    </>
  );
};

import styled from "@emotion/styled";

export const AreaName = styled.h5`
  color: var(--color-textSecondary);
  font-size: 10px;
  font-weight: 600;
  width: 230px;
  max-height: 45px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

import styled from "@emotion/styled";
import { Box, CardMedia, Typography } from "@mui/material";
import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

export interface LearnMoreCardProps {
  item: {
    event: string;
    url: string;
    urlText: string;
    title: string;
    imageUrl: string;
  };
  triggerGAEvent: (event: string) => void;
}

const LearnMoreCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: solid var(--color-gray-200) 1px;
  border-radius: 1rem;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`;

const LearnMoreTitleContainer = styled(Box)`
  display: flex;
  padding: 0.75rem;
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  a {
    color: var(--color-text);
  }
  a:hover {
    color: var(--color-text);
  }
`;

const LearnMoreTitle = styled(Typography)`
  margin-bottom: 1rem;
  text-align: center;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--color-gray-500);
`;

export const LearnMoreCard: FC<LearnMoreCardProps> = ({ item, triggerGAEvent }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    triggerGAEvent(item.event);
    navigate(item.url);
  };

  const handleUrlClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // we need to stop propagating, otherwise the link is open "two times" (by this callback and also handleCardClick)
    event.stopPropagation();
    triggerGAEvent(`url-${item.event}`);
  };

  return (
    <LearnMoreCardContainer onClick={handleCardClick}>
      <CardMedia sx={{ width: "100%", height: "auto" }} component="img" image={item.imageUrl} alt={item.title} />
      <LearnMoreTitleContainer>
        <LearnMoreTitle variant="subtitle1">{item.title}</LearnMoreTitle>
        <Typography
          to={item.url}
          onClick={handleUrlClick}
          sx={{ fontWeight: "500", fontSize: "0.9rem", textDecoration: "none" }}
          component={Link}
        >
          {item.urlText}
        </Typography>
      </LearnMoreTitleContainer>
    </LearnMoreCardContainer>
  );
};

import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Button } from "components_new";
import React, { FC } from "react";

export interface BookTrainingProps {
  item: {
    title: string;
    description: string;
    scheduleCallUrl: string;
    scheduleCallText: React.ReactNode;
  };
  triggerGAEvent: (event: string) => void;
}

const BookTrainingContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  border: solid var(--color-gray-200) 1px;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;
  align-items: center;
`;

const BookTrainingTitle = styled(Typography)`
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--color-primaryLight);
`;

const BookTrainingDescription = styled(Typography)`
  font-weight: 500;
  font-size: 0.9rem;
  text-align: justify;
`;

export const BookTraining: FC<BookTrainingProps> = ({ item, triggerGAEvent }) => {
  const handleSchedule = () => {
    triggerGAEvent("book-trainning");
    window.open(item.scheduleCallUrl, "_black");
  };

  return (
    <BookTrainingContainer>
      <Box>
        <BookTrainingTitle>{item.title}</BookTrainingTitle>
        <BookTrainingDescription>{item.description}</BookTrainingDescription>
      </Box>
      <Button size="medium" color="secondary" onClick={() => handleSchedule()}>
        {item.scheduleCallText}
      </Button>
    </BookTrainingContainer>
  );
};

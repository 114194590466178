import { Switch } from "components_new";
import { FC, MutableRefObject, useCallback, useEffect, useMemo } from "react";

import { BaseMapStyles } from "features/filters/BaseMapStyles";
import { ColorSchemeSelector } from "features/filters/ColorSchemeSelector";
import { RangeFilter } from "features/filters/RangeFilter";
import { isInAvailableRange } from "features/filters/utils";
import { ModuleData } from "features/map/ModuleManager";
import { sequentialSchemes } from "features/map/layerColors";
import { getLayerFromZoom, getQueryType } from "features/map/utils";

import { ODLegend } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";
import { mapActions } from "store/sections/map";

import { MapVisualizationType, MeasureRange } from "types";

interface ODMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  ODModuleData: ModuleData;
}

export const ODMapLayers: FC<ODMapLayersProps> = ({ map, ODModuleData }) => {
  const dispatch = useAppDispatch();

  const { zoningLevels, ODZoningLevelBlockedZoom, filterZonesByRange, filterGatesByRange, updateODModeCounts } =
    ODModuleData.data;

  const mapMode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const colorScale = useAppSelector((state) => state.map.colorScale);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);

  const queryType = useAppSelector((state) => state.filters.queryType);
  const zoningLevel = useAppSelector((state) => state.filters.zoningLevel);

  const rangeByZone = useAppSelector((state) => state.filters.rangeByZone);
  const availableRangeByZone = useAppSelector((state) => state.filters.availableRangeByZone);

  //OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODZoneIds = useAppSelector((state) => state.analytics.ODIds);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZone = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const ODRange = useAppSelector((state) => state.filters.ODRange);
  const ODAvailableRange = useAppSelector((state) => state.filters.ODAvailableRange);
  // @TODO replace with Select Link mode later
  const isOD =
    (mapMode === MapVisualizationType.OD || mapMode === null) && selectedFocusArea && !selectedFocusArea?.datasetId;

  //Dataset
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const datasetIds = useAppSelector((state) => state.analytics.datasetIds);
  const gatesIds = useAppSelector((state) => state.analytics.datasetGates.data)?.map((gate) => gate.identifier);
  const datasetCounts = useAppSelector((state) => state.analytics.datasetCounts);
  const datasetCountsByZone = useAppSelector((state) => state.analytics.datasetCountsByZoneId);
  const datasetRange = useAppSelector((state) => state.filters.datasetRange);
  const datasetAvailableRange = useAppSelector((state) => state.filters.datasetAvailableRange);
  // @TODO replace with Select Link mode later
  const isDataset = (mapMode === MapVisualizationType.OD || mapMode === null) && selectedFocusArea?.datasetId;

  const layers = isOD ? ODMetadata.data?.tileService.layers : datasetMetadata.data?.tileService.layers;

  const availableRange = useMemo(() => {
    if (selectedZone && availableRangeByZone) return availableRangeByZone;
    if (isOD && ODAvailableRange.data && zoningLevel) return ODAvailableRange.data?.[zoningLevel?.level];
    if (isDataset && datasetAvailableRange.data && zoningLevel) return datasetAvailableRange.data?.[zoningLevel?.level];
  }, [
    selectedZone,
    availableRangeByZone,
    isOD,
    ODAvailableRange.data,
    zoningLevel,
    isDataset,
    datasetAvailableRange.data,
  ]);

  const range = useMemo(() => {
    const availableRangeTouple: [number, number] = availableRange ? [availableRange.min, availableRange.max] : [0, 0];
    if (selectedZone) return rangeByZone || availableRangeTouple;
    if (isOD && zoningLevel)
      return ODRange?.[zoningLevel?.level] ? ODRange?.[zoningLevel?.level] : availableRangeTouple;
    if (isDataset && zoningLevel)
      return datasetRange?.[zoningLevel?.level] ? datasetRange?.[zoningLevel?.level] : availableRangeTouple;
  }, [selectedZone, rangeByZone, availableRange, isOD, zoningLevel, ODRange, isDataset, datasetRange]);

  const zoneCounts = useMemo(() => {
    if (isOD) {
      return selectedZone ? ODCountsByZone.data?.counts.zones : ODCounts.data?.zones;
    }
    if (isDataset) {
      return selectedZone
        ? new Map([
            ...(datasetCountsByZone.data?.counts.zones || []),
            ...(datasetCountsByZone.data?.counts.gates || []),
          ])
        : new Map([...(datasetCounts.data?.zones || []), ...(datasetCounts.data?.gates || [])]);
    }
  }, [ODCounts.data, ODCountsByZone.data, datasetCounts.data, datasetCountsByZone.data, isDataset, isOD, selectedZone]);

  const loading =
    ODCounts.state === DataState.LOADING ||
    ODCountsByZone.state === DataState.LOADING ||
    datasetCounts.state === DataState.LOADING ||
    datasetCountsByZone.state === DataState.LOADING;

  useEffect(() => {
    const zoom = map.current?.getZoom();
    const isZoningLevelBlocked =
      (range && availableRange && (range[0] !== availableRange.min || range[1] !== availableRange.max)) || selectedZone;

    if (isZoningLevelBlocked && !ODZoningLevelBlockedZoom.current && zoom !== undefined) {
      ODZoningLevelBlockedZoom.current = zoom;
    } else if (!isZoningLevelBlocked) {
      ODZoningLevelBlockedZoom.current = null;
      const layer = getLayerFromZoom(zoom, layers);
      if (layer) dispatch(filtersActions.setZoningLevel(layer));
      updateODModeCounts.current?.();
    }
  }, [
    range,
    availableRange,
    selectedZone,
    map,
    layers,
    dispatch,
    zoningLevel,
    ODZoningLevelBlockedZoom,
    updateODModeCounts,
  ]);

  const handleSetRange = (newRange: [number, number], availableRange: MeasureRange) => {
    if (selectedZone) {
      dispatch(filtersActions.setRangeByZone(isInAvailableRange(newRange, availableRange) ? null : newRange));
    } else if (isOD && zoningLevel) {
      dispatch(
        filtersActions.setODRange(
          isInAvailableRange(newRange, availableRange) ? null : { [zoningLevel?.level]: newRange },
        ),
      );
    } else if (isDataset && zoningLevel) {
      dispatch(
        filtersActions.setDatasetRange(
          isInAvailableRange(newRange, availableRange) ? null : { [zoningLevel?.level]: newRange },
        ),
      );
    }
  };

  const filterZones = useCallback(() => {
    if (map.current && zoneCounts && range && availableRange && zoningLevel) {
      if ((range[0] > availableRange.min || range[1] < availableRange.max) && showZoneCounts) {
        const featureIds: any = [];

        for (const [key, value] of zoneCounts) {
          if (value >= range[0] && value <= range[1]) {
            featureIds.push(key);
          }
        }

        filterZonesByRange(featureIds, zoningLevel);
        if (isDataset) filterGatesByRange(featureIds);
      } else {
        const ids = isDataset
          ? Array.from(datasetIds.data?.get(zoningLevel.level)?.keys() || []).filter((id) => zoneCounts.has(id))
          : Array.from(ODZoneIds.data?.get(zoningLevel.level)?.keys() || []).filter((id) => zoneCounts.has(id));

        filterZonesByRange(ids, zoningLevel);
        if (isDataset) filterGatesByRange(gatesIds);
      }
    }
  }, [
    ODZoneIds.data,
    availableRange,
    datasetIds.data,
    filterZonesByRange,
    filterGatesByRange,
    isDataset,

    map,
    range,
    showZoneCounts,
    zoneCounts,
    zoningLevel,
    gatesIds,
  ]);

  const handleChangeShowZoneCounts = () => {
    dispatch(mapActions.setShowZoneCounts(!showZoneCounts));
    ODModuleData.data.changeShowZoneCounts(!showZoneCounts);
  };

  const handleChangeColorScheme = useCallback(
    (colorScheme: string) => {
      dispatch(mapActions.setColorScheme(colorScheme));
    },
    [dispatch],
  );

  return (
    <>
      <ODLegend
        colorSchemeName={colorScheme}
        scale={colorScale}
        queryType={selectedZone ? getQueryType(queryType) : queryType}
        zoningLevel={zoningLevel?.level}
        zoningLevels={zoningLevels}
        subtitle="Counts per square mile"
      />

      <Switch label="OD Counts" checked={showZoneCounts} onChange={handleChangeShowZoneCounts} />

      <RangeFilter
        label={"Counts"}
        range={range}
        availableRange={availableRange}
        loading={loading}
        filterByRange={filterZones}
        setRange={handleSetRange}
      />
      <ColorSchemeSelector
        availableSchemes={sequentialSchemes}
        selectedColorScheme={colorScheme}
        onChange={handleChangeColorScheme}
      />

      <BaseMapStyles map={map.current} />
    </>
  );
};

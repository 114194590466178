import * as Sentry from "@sentry/react";

export const reportAboutErrorState = (extras?: any, message?: string) => {
  Sentry.withScope((scope) => {
    if (extras) {
      scope.setExtras(extras);
    }

    if (message) {
      Sentry.captureException(new Error(message));
    }
  });
};

export const reportAboutCustomEventInfo = (message: string, extras?: any) => {
  Sentry.withScope((scope) => {
    if (extras) {
      scope.setExtras(extras);
    }

    Sentry.captureMessage(message, "info");
  });
};

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemProps,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { IntersectionDirection } from "api/analytics/index.d";
import { Button, Divider, IconButton } from "components_new";
import { LngLatLike } from "mapbox-gl";
import { FC, RefObject, createRef, useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions, selectCandidateIntersections, selectSelectedScreenline } from "store/sections/screenlines";

import { ScreenlineIntersection } from "./ScreenlineIntersection";
import { getIntersectionGroups } from "./utils";

export interface ScreenlineIntersectionsProps {
  loading: boolean;
  intersectionDirectionFilter: IntersectionDirection | undefined;
  editMode: boolean;
  rightLabel: string;
  leftLabel: string;
  handleZoomOnPoint: (lngLat: LngLatLike, zoom?: number) => void;
  setSelectedIntersectionId: ((intersectionId: string | null) => void) | null;
}

export const IntersectionListItem = styled((props: ListItemProps) => (
  <ListItem disableGutters disablePadding {...props} />
))(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.background.paper,
}));

const IntersectionListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export const ScreenlineIntersections: FC<ScreenlineIntersectionsProps> = ({
  loading,
  intersectionDirectionFilter,
  editMode,
  leftLabel,
  rightLabel,
  handleZoomOnPoint,
  setSelectedIntersectionId,
}) => {
  const dispatch = useAppDispatch();

  const selectedScreenline = useAppSelector(selectSelectedScreenline);
  const draftScreenline = useAppSelector((state) => state.screenlines.draftScreenline);
  const selectedIntersectionId = useAppSelector((state) => state.screenlines.selectedIntersectionId);
  const candidateIntersections = useAppSelector(selectCandidateIntersections);
  const candidateSegments = useAppSelector((state) => state.screenlines.screenlineValidation.data?.candidateSegments);
  const resolvedSegments = useAppSelector((state) => state.screenlines.screenlineValidation.data?.resolvedSegments);
  const validatedSegmentIntersections = useAppSelector(
    (state) => state.screenlines.screenlineValidation.data?.validatedSegmentIntersections,
  );
  const validationMessages = useAppSelector((state) => state.screenlines.screenlineValidation.data?.validationMessages);
  const isEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);

  const [showCandidates, setShowCandidates] = useState(true);
  const [showNotIncluded, setShowNotIncluded] = useState(true);

  const notIncludedIntersections = useMemo(
    () => validatedSegmentIntersections?.filter((intersection) => !roadClasses?.includes(intersection.roadClass)),
    [validatedSegmentIntersections, roadClasses],
  );

  const handleClickOnIntersection = (intersectionId: string, lat: number, lon: number) => {
    setSelectedIntersectionId?.(intersectionId);
    handleZoomOnPoint([lon, lat]);
  };

  const handleAddAllCandidates = () => {
    if (draftScreenline && candidateIntersections.length) {
      dispatch(screenlinesActions.addIntersections(candidateIntersections));
    }
  };

  const handleRemoveAllNotIncludedIntersections = () => {
    if (draftScreenline && notIncludedIntersections?.length) {
      dispatch(screenlinesActions.deleteIntersections(notIncludedIntersections.map((i) => i.id)));
    }
  };

  const intersectionGroups = useMemo(() => {
    const groups = getIntersectionGroups(
      editMode && showCandidates ? candidateSegments || [] : [],
      !editMode || showNotIncluded
        ? validatedSegmentIntersections || []
        : validatedSegmentIntersections?.filter((intersection) => roadClasses?.includes(intersection.roadClass)) || [],
      validationMessages || [],
    );

    const refs = groups.reduce((refsObj: { [key: string]: RefObject<any> }, group) => {
      const intersectionId = group[0];
      refsObj[intersectionId] = createRef();
      return refsObj;
    }, {});

    return { groups, refs };
  }, [
    validatedSegmentIntersections,
    candidateSegments,
    validationMessages,
    editMode,
    showCandidates,
    showNotIncluded,
    roadClasses,
  ]);

  useEffect(() => {
    if (
      selectedIntersectionId &&
      intersectionGroups.refs[selectedIntersectionId] &&
      intersectionGroups.refs[selectedIntersectionId].current &&
      isEditorOpen
    ) {
      intersectionGroups.refs[selectedIntersectionId].current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedIntersectionId, intersectionGroups.refs, isEditorOpen]);

  return (
    <Box sx={{ height: "100%", paddingTop: 1 }}>
      <Box>
        <Collapse in={editMode}>
          <Stack alignItems={"flex-start"} spacing={0.5} paddingX={1} paddingY={0.5}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
              <Grid item xs container alignItems={"center"}>
                <IconButton
                  disabled={!candidateIntersections.length}
                  size="small"
                  color={"secondary"}
                  sx={{ marginRight: 1 }}
                  onClick={() => setShowCandidates(!showCandidates)}
                >
                  {showCandidates ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                </IconButton>
                <Typography sx={{ fontSize: "11px" }}>
                  {`${candidateIntersections.length || 0} additional intersection${
                    candidateIntersections.length === 1 ? "" : "s"
                  } can be included`}
                </Typography>
              </Grid>
              <Button
                size="small"
                color="secondary"
                disabled={!candidateIntersections.length}
                onClick={handleAddAllCandidates}
                sx={{ padding: 0, fontSize: "11px !important", marginRight: 1 }}
              >
                Include
              </Button>
            </Grid>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
              <Grid item xs container alignItems={"center"}>
                <IconButton
                  disabled={!notIncludedIntersections?.length}
                  size="small"
                  color={"secondary"}
                  sx={{ marginRight: 1 }}
                  onClick={() => setShowNotIncluded(!showNotIncluded)}
                >
                  {showNotIncluded ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                </IconButton>
                <Typography sx={{ fontSize: "11px" }}>
                  {`${notIncludedIntersections?.length || 0} intersection${
                    (notIncludedIntersections?.length || 0) === 1 ? "" : "s"
                  } with excluded road classes`}
                </Typography>
              </Grid>
              <Button
                size="small"
                color="secondary"
                disabled={!notIncludedIntersections?.length}
                onClick={handleRemoveAllNotIncludedIntersections}
                sx={{ padding: 0, fontSize: "11px !important", marginRight: 1 }}
              >
                Remove
              </Button>
            </Grid>
          </Stack>
        </Collapse>
      </Box>

      <Divider />
      <List
        sx={{
          overflowY: "auto",
          height: `calc(100% - ${editMode ? "58px" : "8px"})`,
          maxHeight: `calc(100% - ${editMode ? "58px" : "8px"})`,
          transition: "height 0.3s",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {loading ? (
          Array.from({ length: selectedScreenline?.segmentIntersections.length || 5 }, (_, index) => 0 + index).map(
            (i) => <Skeleton key={i} variant="rounded" height={40} sx={{ marginBottom: 1, borderRadius: "8px" }} />,
          )
        ) : resolvedSegments?.length === 0 ? (
          <Grid container padding={1} justifyContent={"center"} color={"text.secondary"}>
            <Typography variant="caption" marginTop={2}>
              No segment intersections assigned
            </Typography>
            <br />
            <Typography variant="caption" marginTop={1} textAlign={"center"}>
              There are{" "}
              <Typography variant="caption" color={"text.primary"}>
                {candidateIntersections.length} intersecting segment
                {candidateIntersections.length === 1 ? "" : "s"}
              </Typography>{" "}
              matching the road class filter that can be added to the screenline by selecting them on the map, or adding
              them all
            </Typography>
          </Grid>
        ) : (
          intersectionGroups.groups.map(([intersectionGroupId, intersectionGroup], i) => {
            const { roadClass } = intersectionGroup[0].intersection;
            return (
              <IntersectionListItem key={i}>
                <IntersectionListItemButton
                  divider
                  disabled={!roadClasses?.includes(roadClass)}
                  selected={selectedIntersectionId?.toString() === intersectionGroupId.toString()}
                  onClick={() =>
                    handleClickOnIntersection(
                      intersectionGroupId,
                      intersectionGroup[0].intersection.intersection.lat,
                      intersectionGroup[0].intersection.intersection.lon,
                    )
                  }
                >
                  <ScreenlineIntersection
                    key={i}
                    ref={intersectionGroups.refs[intersectionGroupId]}
                    intersectionGroup={intersectionGroup}
                    leftLabel={leftLabel}
                    rightLabel={rightLabel}
                    selected={selectedIntersectionId === intersectionGroupId}
                    intersectionDirectionFilter={intersectionDirectionFilter}
                    editMode={editMode}
                  />
                </IntersectionListItemButton>
              </IntersectionListItem>
            );
          })
        )}
      </List>
      <Divider />
    </Box>
  );
};

import { Box, Grid, Pagination, Typography } from "@mui/material";
import { Map, MapboxGeoJSONFeature } from "mapbox-gl";
import React, { ChangeEvent, MutableRefObject, useEffect, useMemo, useState } from "react";

import { AttributesTable } from "features/zoning-uploader/AttributesTable";

import { useAppSelector } from "hooks";

interface OverlayPopupContentProps {
  map: MutableRefObject<Map | null>;
  features: MapboxGeoJSONFeature[];
}

export const OverlayPopupContent = ({ map, features }: OverlayPopupContentProps) => {
  const serviceLayers = useAppSelector((state) => state.corridor.serviceLayers);
  const [page, setPage] = useState(1);

  const feature = features?.[page - 1];
  const layer = useMemo(
    () => serviceLayers.data?.find((layer) => layer.serviceLayerId === feature?.layer.id),
    [feature?.layer.id, serviceLayers.data],
  );

  useEffect(() => {
    setPage(1);
  }, [features]);

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    const source = feature?.layer.source as string;
    if (source) {
      map.current?.setFeatureState(
        {
          source,
          id: feature?.id,
        },
        { ...feature?.properties, highlightSelect: false },
      );
    }
    const newFeature = features?.[page - 1];
    const newSource = newFeature?.layer.source as string;
    if (newFeature && newSource) {
      map.current?.setFeatureState(
        {
          source: newSource,
          id: newFeature?.id,
        },
        { ...newFeature?.properties, highlightSelect: true },
      );
    }
    setPage(page);
  };

  return (
    <Box width={350}>
      <Typography m={2} variant="subtitle2" fontSize={11} fontWeight={700} lineHeight={"inherit"}>
        {layer?.layerName}
      </Typography>

      <AttributesTable
        tableHeadLabels={["Attribute", "Value"]}
        attributes={feature?.properties}
        maxHeight={200}
        maxWidth={350}
      />
      <Grid container alignItems={"center"} sx={{ borderTop: ({ palette }) => `1px solid ${palette.divider}` }}>
        <Pagination
          size="small"
          variant="text"
          shape="rounded"
          page={page}
          count={features.length}
          onChange={handleChangePage}
          sx={{ m: 1, "& button": { fontSize: 10, height: 20, minWidth: 20 } }}
        />
      </Grid>
    </Box>
  );
};

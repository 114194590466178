export const parseTileServiceUrl = (url: string): string => {
  const baseUrl = process.env.REACT_APP_API_HOST;

  if (baseUrl && !url.includes(baseUrl)) {
    return baseUrl + url;
  }

  return url;
};

export const isFromToSegment = function (segmentIndex: number): boolean {
  return !(segmentIndex & 1); // even value
};

export const isToFromSegment = function (segmentIndex: number): boolean {
  return Boolean(segmentIndex & 1); // odd value (note: & is 2x faster than segmentIndex%2==1)
};

export const getReverseSegmentIndex = function (segmentIndex: number): number {
  return isFromToSegment(segmentIndex)
    ? segmentIndex + 1 // get the to-from segment index; exists only for segment pairs
    : segmentIndex - 1; // get the corresponding from-to segment index; exists always
};

export const getFromToSegmentIndex = function (segmentIndex: number): number {
  return !(segmentIndex & 1) // true if even value -> from-to segment
    ? segmentIndex
    : segmentIndex - 1; // get the corresponding from-to segment index; exists always
};

import { ActionCreatorsMapObject } from "redux";

export type Action<T extends string = string, P = void, M = void> = P extends void
  ? M extends void
    ? Readonly<{ type: T; error: boolean }>
    : Readonly<{ type: T; meta: M; error: boolean }>
  : M extends void
  ? Readonly<{ type: T; payload: P; error: boolean }>
  : Readonly<{ type: T; payload: P; meta: M; error: boolean }>;

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): Action<T, P>;
export function createAction<T extends string, P, M>(type: T, payload: P, meta: M): Action<T, P, M>;
export function createAction<T extends string, P, M>(type: T, payload?: P, meta?: M) {
  if (payload === undefined) {
    if (meta === undefined) {
      return {
        type,
        error: false,
      };
    }

    return {
      type,
      meta,
      error: false,
    };
  }

  if (meta === undefined) {
    return {
      type,
      payload,
      error: payload instanceof Error,
    };
  }

  return {
    type,
    payload,
    meta,
    error: payload instanceof Error,
  };
}

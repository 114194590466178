import { styled } from "@mui/material";

export const RightSidebar = styled("div", {
  shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "animation",
})<{
  isOpen: boolean;
  animation?: boolean;
}>(({ isOpen, theme }) => ({
  gridArea: "right-sidebar",
  minWidth: "var(--sidebar-width)",
  maxWidth: "var(--sidebar-width)",
  position: "fixed",
  top: "64px",
  bottom: 0,
  right: 0,
  padding: "var(--padding-lg)",
  transform: isOpen ? "translateX(0)" : "translateX(110%)",
  transition: "0.5s ease",
  zIndex: 3,
  boxShadow: "0 1px 2px rgba(60,64,67,0.3),0 2px 6px 2px rgba(60,64,67,0.15)",
  backgroundColor: theme.palette.background.paper,
}));

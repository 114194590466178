import React, { FC } from "react";
import styled from "@emotion/styled";

interface InfoPaneProps {
  value: number | string;
  label: string;
  description?: string;
}

const InfoPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 123px;
`;

const InfoPaneValueWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 123px;
  height: 67px;
  background: rgba(19, 158, 236, 0.1);
  border-radius: 8px;
`;

const Value = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: var(--color-text);
`;

const ValueLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text);
`;

const Description = styled.div`
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text);
`;

export const InfoPane: FC<InfoPaneProps> = ({ value, label, description }) => {
  return (
    <InfoPaneWrapper>
      <InfoPaneValueWithLabel>
        <Value>{value}</Value>
        <ValueLabel>{label}</ValueLabel>
      </InfoPaneValueWithLabel>
      {description && <Description>{description}</Description>}
    </InfoPaneWrapper>
  );
};

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { FC } from "react";

import { AttributesTable } from "./AttributesTable";

export interface AttributesPopupContentProps {
  feature: MapboxGeoJSONFeature;
}

export const AttributesPopupContent: FC<AttributesPopupContentProps> = ({ feature }) => (
  <Box>
    <Typography m={1} variant="subtitle2" fontSize={11}>
      Zone ID: {feature?.id}
    </Typography>
    <AttributesTable
      tableHeadLabels={["Attribute", "Value"]}
      attributes={feature?.properties}
      maxHeight={200}
      maxWidth={300}
    />
  </Box>
);

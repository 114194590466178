import RestHandler from "./RestHandler";
import { TileServiceMetadata } from "types";

export interface TileServiceApiType {
  getTileServiceMetadata(url: string): Promise<TileServiceMetadata>;
}

export default function TileServiceApi(restHandler: RestHandler) {
  return {
    async getTileServiceMetadata(url: string) {
      const body = await restHandler.get<TileServiceMetadata>(url + ".json");
      return body;
    },
  };
}

import React, { useMemo } from "react";

import { SpinnerOverlay } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

export const LoadingSpinnerWrapper = () => {
  const corridorMetadataState = useAppSelector((state) => state.corridor.corridorMetadata.state);
  const corridorEdgeIdsState = useAppSelector((state) => state.corridor.corridorEdgeIds.state);
  const corridorEdgeCountsState = useAppSelector((state) => state.corridor.corridorEdgeCounts.state);
  const corridorNodeIdsState = useAppSelector((state) => state.corridor.corridorNodeIds.state);
  const corridorNodeCountsState = useAppSelector((state) => state.corridor.corridorNodeCounts.state);
  const serviceOverlayLayersState = useAppSelector((state) => state.corridor.serviceLayers.state);
  const edgeCountsAvailableRangeState = useAppSelector((state) => state.corridor.corridorEdgeAvailabeRange.state);

  const isLoading = useMemo(() => {
    return (
      corridorMetadataState === DataState.LOADING ||
      corridorEdgeIdsState === DataState.LOADING ||
      corridorEdgeCountsState === DataState.LOADING ||
      corridorNodeIdsState === DataState.LOADING ||
      corridorNodeCountsState === DataState.LOADING ||
      serviceOverlayLayersState === DataState.LOADING ||
      edgeCountsAvailableRangeState === DataState.LOADING
    );
  }, [
    corridorMetadataState,
    corridorEdgeIdsState,
    corridorEdgeCountsState,
    corridorNodeIdsState,
    corridorNodeCountsState,
    serviceOverlayLayersState,
    edgeCountsAvailableRangeState,
  ]);

  return <>{isLoading ? <SpinnerOverlay /> : null}</>;
};

import styled from "@emotion/styled";
import React, { FC } from "react";

import { StreetName } from "components";

import { Volume } from "types";

import { getVolumesByDirections } from "utils/ui";

export interface RoadsHoverPopupProps {
  volume?: Volume;
  gateId?: string;
  isPedestriansMode?: boolean;
  loading?: boolean;
}

const CountContainer = styled.table`
  min-width: 40px;
  margin: 0 0.5rem;
`;

const GateId = styled.h3`
  font-weight: 500;
`;

export const RoadsHoverPopup: FC<RoadsHoverPopupProps> = ({ loading, volume, gateId, isPedestriansMode = false }) => {
  return (
    <>
      {gateId && (
        <CountContainer>
          <tbody>
            <tr>
              <td>
                <GateId>Gate {gateId}</GateId>
              </td>
            </tr>
          </tbody>
        </CountContainer>
      )}
      {volume ? (
        <CountContainer>
          <tbody>
            <tr>
              <StreetName>{volume.st_name || "Not named"}</StreetName>
              {getVolumesByDirections(volume, isPedestriansMode, false, undefined, loading)}
            </tr>
          </tbody>
        </CountContainer>
      ) : null}
    </>
  );
};

export const subareaShapeFileColumns = [
  {
    column: "ds_name",
    type: "Text",
    description: "Dataset name",
    notes: "",
  },
  {
    column: "ds_desc",
    type: "Text",
    description: "Dataset description",
    notes: "",
  },
  {
    column: "gate_count",
    type: "Integer",
    description: "Number of gates defined for the dataset",
    notes: "",
  },
  {
    column: "zone_count",
    type: "Integer",
    description: "Number of zones used for the dataset",
    notes: "",
  },
  {
    column: "zoning",
    type: "Text",
    description: "The zoning name/level",
    notes: "",
  },
  {
    column: "timeperiod",
    type: "Text",
    description: "The time period of the input data for the dataset",
    notes: "",
  },
  {
    column: "created",
    type: "Date",
    description: "The date of the dataset creation",
    notes: "",
  },
];

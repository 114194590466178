export const datasetErrors = [
  {
    id: "E1",
    warning: "Subarea polygon is not defined",
    resolution: "Create subarea polygon.",
  },
  {
    id: "E2",
    warning: "Subarea polygon is empty",
    resolution: "Make sure the subarea polygon consists of at least three points.",
  },
  {
    id: "E3",
    warning: "Subarea polygon is not valid: self-intersection",
    resolution: "Make sure the boundaries of the polygon are not intersecting with each other.",
  },
  {
    id: "E4",
    warning: "Subarea polygon is not valid: insufficient number of points",
    resolution: "Make sure polygon consists of at least three points.",
  },
  {
    id: "E5",
    warning: "No zones selected given the subarea and zoning",
    resolution: "Increase size of polygon until it touches at least 25% of one zone to select at least one zone.",
  },
  {
    id: "E6",
    warning: "A gate has no segment",
    resolution: "Add a segment to a gate or delete empty gate.",
  },
];

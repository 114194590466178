import { useRef, useEffect, useMemo } from "react";
import debounce from "lodash/debounce";

export const useDebounce = (callback: any, time: number = 1000) => {
  const ref = useRef<() => {}>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      if (typeof ref.current === "function") {
        ref.current();
      }
    };

    return debounce(func, time);
  }, [time]);

  return debouncedCallback;
};

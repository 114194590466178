import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { FC, useEffect, useState } from "react";

import { ValidationMessageSeverity } from "types";

import { capitalize } from "utils/format";

import { AttributesPopupContent } from "./AttributesPopupContent";
import { IssuePopupContent } from "./IssuePopupContent";

type ZoningPopupContentType = "zone" & typeof ValidationMessageSeverity;

export interface ZoningPopupProps extends BoxProps {
  features: MapboxGeoJSONFeature[];
  type: ZoningPopupContentType;
}

export const ZoningPopup: FC<ZoningPopupProps> = ({ type, features, ...props }) => {
  const [page, setPage] = useState(1);

  const feature = features[page - 1];
  const isIssue = Object.values(ValidationMessageSeverity).includes(type as any);

  useEffect(() => {
    setPage(1);
  }, [features]);

  return (
    <Box {...props}>
      {isIssue ? (
        <IssuePopupContent feature={feature} issueSeverity={type as any} />
      ) : (
        <AttributesPopupContent feature={feature} />
      )}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ borderTop: ({ palette }) => `1px solid ${palette.divider}` }}
      >
        {features.length > 1 ? (
          <Pagination
            size="small"
            variant="text"
            shape="rounded"
            page={page}
            count={features.length}
            onChange={(e, page) => setPage(page)}
            sx={{ m: 1, "& button": { fontSize: 10, height: 20, minWidth: 20 } }}
          />
        ) : (
          <div />
        )}

        <Typography m={1} variant="subtitle2" fontSize={11}>
          {features.length} {`${isIssue ? capitalize(type) : "Zone"}${features.length > 1 ? "s" : ""}`}
        </Typography>
      </Grid>
    </Box>
  );
};

import styled from "@emotion/styled";

import { FlexContainer } from "components";

export const StatePageContainer = styled(FlexContainer)`
  width: 100%;
  min-width: 280px;
  height: 100%;
  justify-content: center;
`;

import { User } from "@auth0/auth0-react";

export const addCustomGAEvent = (
  category: string, // The category of the event
  action: string, // The value that will appear as the event action in Google Analytics Event reports
  label: string, // The label of the event
  user: User | undefined, // The user object from Auth0
  userOrganizationName: string | undefined, // The name of the user's organization
) => {
  if (user && userOrganizationName) {
    const splittedEmail = user?.email?.split("@");

    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      // !!! user__nickname was removed from the event for privacy reasons
      // user_nickname: splittedEmail?.[0] || "anonymous",
      user_auth0_id: user?.sub || "anonymous",
      user_organization: userOrganizationName,
      email_domain: splittedEmail?.[1] || "anonymous",
    });
  }
};

import { Map } from "mapbox-gl";

import { FocusAreaItem } from "types";

interface AddBoundariesLayerProps {
  map: Map;
  selectedArea: FocusAreaItem;
}

export const addBoundariesLayer = ({ map, selectedArea }: AddBoundariesLayerProps) => {
  const boundariesLayerId = `boundaries ${selectedArea.id}`;

  map.addSource(boundariesLayerId, {
    type: "geojson",
    data: selectedArea.geometry,
  });

  map.addLayer({
    id: boundariesLayerId,
    type: "line",
    source: boundariesLayerId,
    paint: {
      "line-color": "#111827",
      "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 3, 19, 20],
      "line-opacity": 0.6,
    },
    layout: {
      "line-cap": "round",
    },
  });
};

import { Button, Dialog } from "components_new";
import { FC } from "react";

export enum EmptyResultWarningType {
  EmptyResult = "EmptyResult",
  Error = "Error",
}

export interface EmptyResultWarningProps {
  isOpen: boolean;
  warningType: EmptyResultWarningType;
  onClose: () => void;
}

function getDialogTitle(type: EmptyResultWarningType) {
  switch (type) {
    case EmptyResultWarningType.EmptyResult:
      return "Select link query did not return any trips.";
    case EmptyResultWarningType.Error:
      return "An error occurred. Please try again.";
  }
}

export const EmptyResultWarning: FC<EmptyResultWarningProps> = ({ isOpen, warningType, onClose }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    title={getDialogTitle(warningType)}
    actions={
      <Button size="medium" color="secondary" onClick={onClose}>
        Ok
      </Button>
    }
  ></Dialog>
);

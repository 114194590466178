import { MenuItem, SxProps } from "@mui/material";
import { TextField } from "components_new";
import { FC } from "react";

interface TimePeriodProps {
  selectedTimePeriod: string;
  entitledTimePeriods: string[];
  disabled?: boolean;
  sx?: SxProps;
  setSelectedTimePeriod: (timePeriod: string) => void;
}

export const TimePeriodSelector: FC<TimePeriodProps> = ({
  selectedTimePeriod,
  entitledTimePeriods,
  disabled,
  sx,
  setSelectedTimePeriod,
}) => {
  //Checking if time period is available for the selected licesed area and if not, setting the first available time period
  if (entitledTimePeriods.length && !entitledTimePeriods.includes(selectedTimePeriod)) {
    setSelectedTimePeriod(entitledTimePeriods[0]);
  }

  const isDisabled = entitledTimePeriods?.length <= 1 || disabled;

  return (
    <TextField fullWidth select label="Time period" disabled={isDisabled} value={selectedTimePeriod} sx={sx}>
      {entitledTimePeriods.map((timePeriod) => (
        <MenuItem key={timePeriod} value={timePeriod} onClick={() => setSelectedTimePeriod(timePeriod)}>
          {timePeriod}
        </MenuItem>
      ))}
    </TextField>
  );
};

import React, { forwardRef, CSSProperties } from "react";

export interface ActionProps extends React.HTMLAttributes<any> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties["cursor"];
}

export const Action = forwardRef<any, ActionProps>(({ active, className, cursor, style, ...props }, ref) => {
  return <div ref={ref} {...props} tabIndex={0} />;
});

export const commonODShapeFileColumns = [
  {
    column: "blckgrp_id",
    type: "Text",
    description: "Block Group GEOID",
    notes: "",
  },
  {
    column: "tract_id",
    type: "Text",
    description: "Tract GEOID",
    notes: "",
  },
  {
    column: "county_id",
    type: "Text",
    description: "County ID (FIPS Code)",
    notes: "",
  },
  {
    column: "state_id",
    type: "Text",
    description: "State ID",
    notes: "",
  },
  {
    column: "center_lon",
    type: "Double",
    description: "Longitude of zone centroid",
    notes: "",
  },
  {
    column: "center_lat",
    type: "Double",
    description: "Latitude of zone centroid",
    notes: "",
  },
];

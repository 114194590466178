export enum SelectLinkAnalysisMode {
  Configuration = "0",
  Results = "1",
}

export enum ZoneSelectionMode {
  Origins = "0",
  Destinations = "1",
}

export interface SelectLinkAnalysisOptions {
  minVolume: number;
}

export interface SelectLinkAnalysisDetails {
  analysisName: string;
  timePeriod: string;
  description?: string;
  focusAreaId?: string;
  configId?: string;
}

import styled from "@emotion/styled";

export const Popup = styled.div`
  display: none;

  & + .mapboxgl-map {
    & .mapboxgl-popup {
      max-width: none !important;
    }

    & .mapboxgl-popup-content {
      font-family: "Inter";
      color: var(--color-text);
      border-radius: 8px;
      box-shadow: var(--box-shadow-sm);
      padding: 0;
    }

    & .mapboxgl-popup-tip {
      margin-top: -1px;
    }

    & .mapboxgl-popup-close-button {
      margin-top: 0.1rem;
      margin-right: 0.5rem;
      font-size: 1.2rem;
      background-color: transparent;

      &:hover {
        background-color: transparent !important;
        font-weight: 500;
      }
    }
  }
`;

import { Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";

const PagePanelBase = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const PagePanelBaseContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StandardTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 800;
  display: flex;
  padding-bottom: 1rem;
  align-items: center;
`;

const WhiteTitle = styled(StandardTitle)`
  color: white;
`;

type PageContinerPanelProps = {
  title: string;
  titleColor?: "white";
  backgroundImage?: string;
  // if compact = true, padding will not be defined
  // Othewise the content will have padding
  compact?: boolean;
  noRoundCorners?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

export const PageContainerPanel: FC<PageContinerPanelProps> = ({
  title,
  titleColor,
  children,
  compact,
  backgroundImage,
  noRoundCorners,
}) => {
  return (
    <PagePanelBase>
      {titleColor === "white" ? <WhiteTitle>{title}</WhiteTitle> : <StandardTitle>{title}</StandardTitle>}
      <PagePanelBaseContent
        sx={{
          backgroundImage: backgroundImage,
          backgroundSize: backgroundImage ? "cover" : undefined,
          padding: compact === true ? undefined : "2rem 2rem 0 2rem",
          borderRadius: noRoundCorners === true ? undefined : "1rem",
        }}
      >
        {children}
      </PagePanelBaseContent>
    </PagePanelBase>
  );
};

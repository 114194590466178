import { Delete, QueryBuilderOutlined } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button } from "components_new";
import { IconButton } from "components_new";
import { MuiFileInput } from "mui-file-input";
import { useEffect, useRef, useState } from "react";

import { DataState, LoadingErrorData } from "store/interfaces";

import { themeColors } from "theme/themeConstants";

import { ShapesInputFormat } from "types";

import { createZip } from "utils/file";

import { StyledDialogActions } from "./ImportScreenlinesDialog";

export interface UploadFilesProps<T> {
  uploadedFiles: LoadingErrorData<T>;
  uploadFiles: (zippedFiles: Blob) => void;
}

const SHAPE_FILE_EXTENSIONS = [
  ".shp",
  ".shx",
  ".dbf",
  ".prj",
  ".sbn",
  ".sbx",
  ".fbn",
  ".fbx",
  ".ain",
  ".aih",
  ".ixs",
  ".mxs",
  ".atx",
  ".xml",
  ".cpg",
];
const GEOJSON_FILE_EXTENSIONS = [".geojson", ".json", ".geojsonl.json", ".geojson.json"];

export const InfoBox = styled(Box)`
  font-size: 12px;
  background: ${themeColors.secondaryLight};
  padding: 10px 20px;
  border-radius: 5px;
  color: ${themeColors.textSecondary};

  .MuiFormControl-root {
    margin-left: 10px;
  }
`;

const ListBox = styled(Box)`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid ${themeColors.border};
  color: ${themeColors.text};

  .MuiTypography-h6 {
    font-size: 16px;
  }
`;

const FileInputWrapper = styled(Box)`
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 20px;
`;

const InlineFormControlWrapper = styled(Box)`
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    margin: 0 10px;
  }
`;

const FormatDescription = styled(Box)`
  font-size: 14px;
`;

export const UploadFiles = <T,>({ uploadedFiles, uploadFiles }: UploadFilesProps<T>) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const [inputFormats, setInputFormats] = useState<ShapesInputFormat>(ShapesInputFormat.SHAPEFILE);
  const [zipping, setZipping] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (uploadedFiles.state === DataState.LOADING && zipping) {
      setZipping(false);
    }
  }, [uploadedFiles.state, zipping]);

  const handleFilesChange = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  const handleRemoveFileByIndex = (index: number) => {
    setFiles((files) => {
      if (!files) {
        return files;
      }

      const newFiles = [...files];
      newFiles.splice(index, 1);

      return newFiles;
    });
  };

  const handleUploadFiles = async () => {
    setZipping(true);

    const zipBlob = await createZip(files!);

    uploadFiles(zipBlob);
  };

  const handleChangeInputFormat = (event: SelectChangeEvent<ShapesInputFormat>) => {
    setInputFormats(event.target.value as ShapesInputFormat);
    setFiles(null);
  };

  const handleBrowseFilesClick = () => {
    fileInputRef.current?.querySelector("input")?.focus();
    fileInputRef.current?.querySelector("input")?.click();
  };

  const isLoading = uploadedFiles.state === DataState.LOADING || zipping;

  return (
    <>
      <InfoBox>
        <InlineFormControlWrapper>
          File type:
          <FormControl sx={{ minWidth: 120 }}>
            <Select disabled={isLoading || true} value={inputFormats} onChange={handleChangeInputFormat}>
              <MenuItem value={ShapesInputFormat.SHAPEFILE}>Shapefile</MenuItem>
              <MenuItem value={ShapesInputFormat.GEOJSON}>GeoJson</MenuItem>
            </Select>
          </FormControl>
          {inputFormats === ShapesInputFormat.SHAPEFILE && (
            <FormatDescription>
              please select all files for the shapefile to be uploaded
              <br />
              required: .shp, .shx, .dbf; if available: .prj and .cpg; .prj is required if coordinate system is not
              WGS84
            </FormatDescription>
          )}
          {inputFormats === ShapesInputFormat.GEOJSON && (
            <FormatDescription>please select a single GeoJson file (WGS84)</FormatDescription>
          )}
        </InlineFormControlWrapper>
      </InfoBox>
      <FileInputWrapper>
        <MuiFileInput
          ref={fileInputRef}
          disabled={isLoading}
          placeholder="Insert files"
          size="small"
          value={files || []}
          onChange={handleFilesChange}
          inputProps={{
            accept:
              inputFormats === ShapesInputFormat.SHAPEFILE
                ? SHAPE_FILE_EXTENSIONS.join(",")
                : GEOJSON_FILE_EXTENSIONS.join(","),
          }}
          multiple
        />

        <Button size="medium" color="secondary" onClick={handleBrowseFilesClick} disabled={isLoading}>
          Browse...
        </Button>
      </FileInputWrapper>
      <ListBox sx={{ height: "350px" }}>
        <Typography variant="h6" component="div">
          {files?.length ? "Selected files:" : "No files selected"}
        </Typography>
        {files && (
          <List sx={{ maxHeight: "275px", overflowY: "auto" }} dense>
            {files.map((file, i) => (
              <ListItem
                key={i}
                secondaryAction={
                  <IconButton
                    disabled={isLoading}
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveFileByIndex(i)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        )}
      </ListBox>

      <StyledDialogActions>
        {uploadedFiles.state === DataState.ERROR ? (
          <Alert severity={"error"}>Error: {uploadedFiles.error?.body?.what || ""}</Alert>
        ) : isLoading ? (
          <Alert
            severity="info"
            icon={<QueryBuilderOutlined fontSize="inherit" />}
            sx={{ padding: "0 16px", height: "38px" }}
          >
            Files are being uploaded and validated, please wait…
          </Alert>
        ) : (
          <div />
        )}

        <Button
          loading={isLoading}
          size="medium"
          color="secondary"
          disabled={!files || files.length === 0 || isLoading}
          onClick={handleUploadFiles}
        >
          Upload
        </Button>
      </StyledDialogActions>
    </>
  );
};

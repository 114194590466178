import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { IconButton } from "components_new";
import React, { useState } from "react";

import { FlexContainer, ToggleButtons } from "components";

import { SelectedArea, ZoneSelectionMode } from "types";

export const ElementsList = styled("ul")`
  padding: 0.25rem 0;
`;

export const ListItem = styled("li", { shouldForwardProp: (prop) => prop !== "isSelected" })<{ isSelected?: boolean }>`
  width: 100%;
  min-height: 32px;
  margin: 0.25rem 0;
  padding: 0.25rem 1rem;
  border: ${({ isSelected }) => (isSelected ? "1px solid var( --color-primary)" : "1px solid var(--color-gray-300)")};
  transition: border 0.3s ease-out;
  border-radius: 8px;
  box-shadow: var(--box-shadow-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const EllipsisText = styled("div")`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ElementName = styled(EllipsisText)`
  font-size: 12px;
  font-weight: 500;
  max-width: 120px;
`;

const StyledIconButton = styled(IconButton)`
  border: none;
`;

export const DeleteButton = styled(StyledIconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 10px;
  height: 20px;
  cursor: pointer;
  color: #000000;
  border: none;
  transition: color 350ms ease;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    color: var(--color-primaryLight);
  }
`;

const TitlePanel = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupHeader = styled("div")`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0.25rem;
`;

const ZonesContainer = styled("div")`
  height: auto;
  overflow-y: auto;
  height: calc(100% - 234px + 0.25rem);
`;

interface Props {
  activeZoneSelectionMode: ZoneSelectionMode;
  selectedOrigins: SelectedArea[];
  selectedDestinations: SelectedArea[];
  selectedZone: SelectedArea | null;
  setSelectedZone: (zone: SelectedArea | null) => void;
  onChangeZoneSelectionMode: (zoneMode: ZoneSelectionMode) => void;
  deleteSelectedZone: (zoneToDelete: SelectedArea) => void;
}

export const SelectLinkZonesConfigPanel = ({
  activeZoneSelectionMode,
  selectedOrigins,
  selectedDestinations,
  selectedZone,
  setSelectedZone,
  onChangeZoneSelectionMode,
  deleteSelectedZone,
}: Props) => {
  const [zoneSelectionMode, setZoneSelectionMode] = useState<ZoneSelectionMode>(activeZoneSelectionMode);

  const handleChangeZoneSelectionMode = (zoneMode: string) => {
    if (zoneMode === zoneSelectionMode) return;

    setZoneSelectionMode(zoneMode as ZoneSelectionMode);
    onChangeZoneSelectionMode(zoneMode as ZoneSelectionMode);
  };

  const handleZoneDeleteButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, zone: SelectedArea) => {
    deleteSelectedZone(zone);
  };

  const handleSelectZone = (zone: SelectedArea) => {
    setSelectedZone(selectedZone?.id === zone.id ? null : zone);
  };

  return (
    <>
      <TitlePanel>
        <div>
          <GroupHeader>{`Zones (${
            zoneSelectionMode === ZoneSelectionMode.Origins ? selectedOrigins.length : selectedDestinations.length
          })`}</GroupHeader>
        </div>
      </TitlePanel>
      <ToggleButtons
        leftButtonLabel="Origins"
        rightButtonLabel="Destinations"
        activeIndex={zoneSelectionMode}
        onChangeIndex={handleChangeZoneSelectionMode}
        leftButtonDisabled={false}
        rightButtonDisabled={false}
        leftButtonIndex={ZoneSelectionMode.Origins}
        rightButtonIndex={ZoneSelectionMode.Destinations}
        sx={{ marginTop: 1 }}
      />
      <ZonesContainer>
        <ElementsList>
          {(zoneSelectionMode === ZoneSelectionMode.Origins ? selectedOrigins : selectedDestinations).map((zone) => (
            <ListItem key={zone.id} isSelected={selectedZone?.id === zone.id} onClick={() => handleSelectZone(zone)}>
              <FlexContainer>
                <ElementName>{zone.id}</ElementName>
              </FlexContainer>
              <DeleteButton onClick={(e) => handleZoneDeleteButtonClick(e, zone)}>
                <Close fontSize="inherit" />
              </DeleteButton>
            </ListItem>
          ))}
        </ElementsList>
      </ZonesContainer>
    </>
  );
};

import { Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { Checkbox, MenuItem, TextField } from "components_new";
import { FC } from "react";

import { AreaOfFocusSelector, TimePeriodSelector } from "components";

import { FocusAreaItem } from "types";

import { InfoPopover } from "./InfoPopover";
import { roadClassGroups } from "./roadClassGroups";

export interface DataQualityMetricsProps {
  areas: FocusAreaItem[] | undefined;
  selectedAreaId: string;
  selectedTimePeriod: string;
  selectedRoadClasses: number[];
  metadataRoadClassesIds: number[];
  availableTimePeriods: string[];
  disabled?: boolean;
  handleChangeSelectedAreaId: (areaId: string) => void;
  handleChangeSelectedTimePeriod: (timePeriod: string) => void;
  handleRoadClassChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DataQualityMetricsControls: FC<DataQualityMetricsProps> = ({
  areas,
  selectedAreaId,
  selectedTimePeriod,
  selectedRoadClasses,
  metadataRoadClassesIds,
  availableTimePeriods,
  disabled,
  handleChangeSelectedAreaId,
  handleChangeSelectedTimePeriod,
  handleRoadClassChange,
}) => {
  return (
    <Stack spacing={2} width={"100%"}>
      <AreaOfFocusSelector
        areas={areas || []}
        selectedAreaId={selectedAreaId}
        disabled={disabled}
        onAreaChange={(areaId) => handleChangeSelectedAreaId(areaId)}
      />
      <TimePeriodSelector
        entitledTimePeriods={availableTimePeriods}
        selectedTimePeriod={selectedTimePeriod}
        disabled={disabled}
        setSelectedTimePeriod={handleChangeSelectedTimePeriod}
      />
      <TextField
        fullWidth
        select
        label="Road classes"
        value={0}
        disabled={disabled}
        SelectProps={{ open: false, IconComponent: () => <div style={{ marginLeft: "-16px" }} /> }}
      >
        <MenuItem value={0}>
          <List>
            {Object.entries(roadClassGroups).map(([key, { label, legendSymbol }]) => {
              const roadClassGroupIds = roadClassGroups[key].classes.map((c) => c.id);
              const isDiasbled = !roadClassGroupIds.find((roadClass) => metadataRoadClassesIds?.includes(roadClass));
              const isChecked = roadClassGroupIds.some((roadClass) => selectedRoadClasses?.includes(roadClass));

              return (
                <ListItem key={key} sx={{ justifyContent: "space-between" }}>
                  <Checkbox
                    disabled={isDiasbled || disabled}
                    label={label}
                    name={key}
                    checked={isChecked}
                    onChange={handleRoadClassChange}
                  />
                  <Grid item xs={"auto"} container alignItems={"center"}>
                    <Grid container alignItems={"center"} marginRight={1} item xs={"auto"}>
                      {legendSymbol}
                    </Grid>
                    <InfoPopover disabled={isDiasbled}>
                      <Stack margin={1}>
                        {roadClassGroups[key].classes
                          .filter((c) => metadataRoadClassesIds?.includes(c.id))
                          .map((c) => (
                            <Typography key={c.id} variant="caption">
                              {c.label}
                            </Typography>
                          ))}
                      </Stack>
                    </InfoPopover>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </MenuItem>
      </TextField>
    </Stack>
  );
};

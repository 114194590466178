import { useEffect, useRef } from "react";

import { EditorRoadsHighlightStatus } from "features";

import { GateSegment } from "types";

export const useHighlightGateSegments = (
  selectedSegment: GateSegment | null,
  selectedGateSegments: GateSegment[] | null,
  segmentsIdToIdxMapData: Map<string, number> | null,
  setSegmentFeatureState: (id: number, state: { [key: string]: boolean }) => void,
  setGateSegmentsFeatureState: (segments: GateSegment[], state: { [key: string]: boolean }) => void,
) => {
  const highlightedGateSegments = useRef<GateSegment[] | null>(null);
  const highlightedSegment = useRef<GateSegment | null>(null);

  useEffect(() => {
    const highlightedSegmentIdx = segmentsIdToIdxMapData?.get(highlightedSegment.current?.id ?? "");
    const selectedSegmentIdx = segmentsIdToIdxMapData?.get(selectedSegment?.id ?? "");

    if (highlightedSegment.current && highlightedSegmentIdx) {
      setSegmentFeatureState(highlightedSegmentIdx, {
        [EditorRoadsHighlightStatus.SelectedSegment]: false,
      });
    }
    if (selectedSegment && selectedSegment.id !== highlightedSegment.current?.id && selectedSegmentIdx) {
      setSegmentFeatureState(selectedSegmentIdx, {
        [EditorRoadsHighlightStatus.SelectedSegment]: true,
      });
    }
    highlightedSegment.current = selectedSegment;
  }, [selectedSegment, selectedGateSegments, segmentsIdToIdxMapData, setSegmentFeatureState]);

  useEffect(() => {
    if (highlightedGateSegments.current) {
      setGateSegmentsFeatureState(highlightedGateSegments.current, {
        [EditorRoadsHighlightStatus.SelectedGateSegments]: false,
      });
    }

    if (selectedGateSegments && selectedGateSegments !== highlightedGateSegments.current) {
      setGateSegmentsFeatureState(selectedGateSegments, {
        [EditorRoadsHighlightStatus.SelectedGateSegments]: true,
      });
    }
    highlightedGateSegments.current = selectedGateSegments;
  }, [selectedGateSegments, setGateSegmentsFeatureState]);
};

import { useEffect } from "react";
import { ToastOptions, toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

export const useToastMessage = () => {
  const dispatch = useAppDispatch();

  const toastMessage = useAppSelector((state) => state.global.toastMessage);

  useEffect(() => {
    if (toastMessage) {
      const { content, severity, options } = toastMessage;

      const defaultOptions: ToastOptions = {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
      };

      const toastOptions = options ? { ...defaultOptions, ...options } : defaultOptions;

      switch (severity) {
        case "error":
          toast.error(content, toastOptions);
          break;
        case "info":
          toast.info(content, toastOptions);
          break;
        case "success":
          toast.success(content, toastOptions);
          break;
        case "warning":
          toast.warn(content, toastOptions);
          break;
      }

      dispatch(globalActions.setToastMessage(null));
    }
  }, [toastMessage, dispatch]);
};

import { FlyToOptions, IControl, LngLatBoundsLike, Map } from "mapbox-gl";

export function getMapHomeControl(map: Map, bounds: LngLatBoundsLike, options: FlyToOptions) {
  class MapHomeControl implements IControl {
    onAdd(map: Map) {
      const div = document.createElement("div");
      div.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
      div.innerHTML = `
        <button>
            <svg focusable="false" viewBox="-3 -3 30 30" aria-hidden="true" style="font-size: 20px;">
                <title>Reset map</title>
                <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
            </svg>
        </button>`;
      div.addEventListener("click", () => map.fitBounds(bounds, options));

      return div;
    }

    onRemove(map: Map): void {}
  }

  return new MapHomeControl();
}

import { styled } from "@mui/material";
import { CountBadge } from "components_new";
import React, { FC, ReactElement } from "react";

import { useAppSelector } from "hooks";

export interface VolumeCountProps {
  icon?: ReactElement;
  count: number;
  couldBeAdded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Container = styled("td")`
  padding: 0 0.25rem;
`;

export const VolumeCount: FC<VolumeCountProps> = ({ icon, count, disabled, loading, onClick }) => {
  const roadsRange = useAppSelector((state) => state.filters.roadRange);

  return (
    <Container onClick={onClick}>
      <CountBadge
        icon={icon}
        disabled={disabled || (roadsRange ? count < roadsRange[0] || count > roadsRange[1] : false)}
        label={count}
        loading={loading}
      />
    </Container>
  );
};

import styled from "@emotion/styled";
import pic1 from "assets/png/corridor-discovery-docs/Picture_1.jpeg";
import pic2 from "assets/png/corridor-discovery-docs/Picture_2.jpeg";
import pic3 from "assets/png/corridor-discovery-docs/Picture_3.jpeg";
import pic4 from "assets/png/corridor-discovery-docs/Picture_4.jpeg";
import pic5 from "assets/png/corridor-discovery-docs/Picture_5.jpeg";
import pic6 from "assets/png/corridor-discovery-docs/Picture_6.jpeg";
import { FC } from "react";

import { Description, SubTitle } from "./Table";

const CenteredFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 600px;
`;

export const CorridorDiscovery: FC = () => (
  <>
    <Description>
      Corridor Discovery is a revolutionary new way of turning origin-destination (OD) matrices into actionable insights
      on where to prioritize new infrastructure development. By bundling OD flows into common corridors, Corridor
      Discovery makes it easy to discover where a bike path extension or new bus service would have the biggest impact.
    </Description>

    <CenteredFlexContainer>
      <Image src={pic1} alt="pic1" />
    </CenteredFlexContainer>

    <Description>
      Only considering and comparing single OD flows don't result in a good understanding where demand is highest for
      new infrastructure. All OD flows that would use different segments of a new bike path or bus service need to be
      considered.
    </Description>

    <SubTitle style={{ marginTop: "2rem" }}>OD Corridors are being generated as follows:</SubTitle>

    <CenteredFlexContainer>
      <Image src={pic2} alt="pic2" />
    </CenteredFlexContainer>
    <Description>
      First we are converting the OD zones into a fully connected, planar graph - connecting all zone centroids.
    </Description>
    <Description>Then we route each OD flow on the newly created zone-to-zone network</Description>
    <CenteredFlexContainer>
      <Image src={pic3} alt="pic3" style={{ margin: "0 0 -50px 0" }} />
    </CenteredFlexContainer>
    <CenteredFlexContainer>
      <Image src={pic4} alt="pic4" style={{ margin: "-50px 0 -50px 0" }} />
    </CenteredFlexContainer>
    <CenteredFlexContainer>
      <Image src={pic5} alt="pic5" style={{ margin: "-50px 0 -30px 0" }} />
    </CenteredFlexContainer>
    <CenteredFlexContainer>
      <Image src={pic6} alt="pic6" style={{ margin: "-50px 0 0 0" }} />
    </CenteredFlexContainer>
    <Description>
      After routing all OD flows on the zone-to-zone network, it quickly becomes apparent that a combination of several
      OD flows on the right hand side of the graph produce a much stronger mobility demand corridor compared to the one
      on the left hand-side.
    </Description>
    <SubTitle style={{ marginTop: "2rem" }}>Heatmap Color Scales</SubTitle>
    <Description>
      Heatmap color scales are calibrated differently for each license area / area of interest / dataset and therefore
      cannot be used to compare absolute travel intensity between them.
    </Description>
  </>
);

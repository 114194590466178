import { Stack } from "@mui/material";
import { Alert, Button, Dialog, DialogProps, TextField } from "components_new";
import React, { FC, useState } from "react";

import { SelectLinkAnalysisDetails } from "components/pages/analytics/select-link/types";

import { useAppSelector } from "hooks";

import { FocusAreaItem } from "types";

import { AreaOfFocusSelector } from "../../components/organisms/area-of-focus-selector/AreaOfFocusSelector";
import { TimePeriodSelector } from "../../components/organisms/time-period-selector/TimePeriodSelector";

interface SelectLinkOptionsDialogProps extends Omit<DialogProps, "onSubmit"> {
  buttonLabel?: string;
  areas: FocusAreaItem[];
  defaultAreaId: string;
  configId?: string;
  initialName?: string;
  initialDescription?: string;
  errorMessage?: string | null;
  onSubmit: (params: SelectLinkAnalysisDetails) => void;
}

export const EditSelectLinkOptionsDialog: FC<SelectLinkOptionsDialogProps> = ({
  areas,
  defaultAreaId,
  configId,
  initialName,
  initialDescription,
  errorMessage,
  ...props
}) => {
  return (
    <SelectLinkOptionsDialog
      title="Rename Select Link analysis"
      buttonLabel="Save analysis"
      areas={areas}
      defaultAreaId={defaultAreaId}
      configId={configId}
      initialName={initialName}
      initialDescription={initialDescription}
      {...props}
    />
  );
};

export const SelectLinkOptionsDialog: FC<SelectLinkOptionsDialogProps> = ({
  buttonLabel,
  areas,
  defaultAreaId,
  configId,
  initialName,
  initialDescription,
  errorMessage,
  onSubmit,
  ...props
}) => {
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const [focusAreaId, setFocusAreaId] = useState(defaultAreaId);
  const [analysisName, setAnalysisName] = useState(initialName || "");
  const [description, setDescription] = useState(initialDescription || "");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(timePeriod || "");

  const availableTimePeriods = areas.find((area) => area.id === focusAreaId)?.timePeriods || [];

  const loading = useAppSelector((state) => state.selectLink.loading);

  const handleAreaChange = (areaId: string) => {
    setFocusAreaId(areaId);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnalysisName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      actions={
        <Button
          size="medium"
          color="secondary"
          onClick={() => onSubmit({ analysisName, timePeriod: selectedTimePeriod, description, focusAreaId, configId })}
          disabled={!analysisName}
          loading={loading}
        >
          {buttonLabel}
        </Button>
      }
      {...props}
    >
      <Stack spacing={2} marginTop={1}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

        <TextField
          fullWidth
          label="Analysis name (unique)"
          value={analysisName}
          onChange={handleNameChange}
          disabled={loading || !areas.length}
        />

        <TextField
          fullWidth
          multiline
          rows={2}
          label="Analysis description (optional)"
          value={description}
          onChange={handleDescriptionChange}
          disabled={loading || !areas.length}
        />

        <AreaOfFocusSelector
          areas={areas}
          selectedAreaId={focusAreaId}
          disabled={loading || !!configId || !areas.length}
          onAreaChange={handleAreaChange}
        />
        <TimePeriodSelector
          selectedTimePeriod={selectedTimePeriod}
          entitledTimePeriods={availableTimePeriods}
          setSelectedTimePeriod={setSelectedTimePeriod}
          disabled={loading || !!configId}
        />
      </Stack>
    </Dialog>
  );
};

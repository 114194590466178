import { OdCsvCommonColumnsRows } from "./OdCsvCommonColumns";

const zoneIdColumns = [
  {
    column: "start_zone_id",
    type: "Text",
    description: <div>Zone ID of the flow start zone</div>,
    notes: "",
  },
  {
    column: "end_zone_id",
    type: "Text",
    description: <div>Zone ID of the flow end zone</div>,
    notes: "",
  },
];

const additionalColumns = [
  {
    column: "hour",
    type: "Text",
    description: <div>HH format of the hour of the day</div>,
    notes: "",
  },
  {
    column: "trips",
    type: "Double",
    description: <div>Vehicle count</div>,
    notes: "",
  },
];

export const selectLinkODVolumesFileColumns = zoneIdColumns.concat(OdCsvCommonColumnsRows, additionalColumns);

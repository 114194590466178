import { ReactNode } from "react";

import { CrossSvg, DiamondSvg, SquareSvg, StarSvg, TriangleSvg } from "./LegendSvgs";

export interface RoadClassGroups {
  [key: string]: {
    label: string;
    classes: { id: number; label: string }[];
    color: string;
    symbol: "cross" | "diamond" | "square" | "star" | "triangle";
    legendSymbol: ReactNode;
  };
}

export const roadClassGroups: RoadClassGroups = {
  limitedAccess: {
    label: "Limited access",
    classes: [
      {
        id: 1,
        label: "Limited access highway",
      },
    ],
    color: "#3718f7",
    symbol: "triangle",
    legendSymbol: <TriangleSvg />,
  },
  arterials: {
    label: "Arterials",
    classes: [
      {
        id: 2,
        label: "Principal arterial",
      },
      {
        id: 3,
        label: "Major arterial",
      },
      {
        id: 4,
        label: "Minor arterial",
      },
    ],
    color: "#ff0000",
    symbol: "cross",
    legendSymbol: <CrossSvg />,
  },
  collectorLocal: {
    label: "Collector/Local",
    classes: [
      {
        id: 5,
        label: "Major collector",
      },
      {
        id: 6,
        label: "Minor collector",
      },
      {
        id: 7,
        label: "Local street",
      },
    ],
    color: "#03b051",
    symbol: "square",
    legendSymbol: <SquareSvg />,
  },
  ramp: {
    label: "Ramp",
    classes: [
      {
        id: 11,
        label: "Freeway ramp",
      },
      {
        id: 12,
        label: "Principal ramp",
      },
      {
        id: 13,
        label: "Major ramp",
      },
      {
        id: 14,
        label: "Minor ramp",
      },
      {
        id: 15,
        label: "Local ramp",
      },
    ],
    color: "#ffea00",
    symbol: "diamond",
    legendSymbol: <DiamondSvg />,
  },
  other: {
    label: "Other",
    classes: [
      {
        id: 8,
        label: "Service road",
      },
      {
        id: 9,
        label: "Rural road",
      },

      {
        id: 21,
        label: "Busway",
      },
      {
        id: 31,
        label: "Pedestrian",
      },
      {
        id: 32,
        label: "Non-motorized",
      },
      {
        id: 81,
        label: "Ferry",
      },
      {
        id: 97,
        label: "Abandoned",
      },
      {
        id: 98,
        label: "Construction",
      },
    ],
    color: "#787878",
    symbol: "star",
    legendSymbol: <StarSvg />,
  },
};

import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import React from "react";

const ItemContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1px 250px 250px 80px 1fr 80px 80px 90px 31px;
  grid-template-rows: 1fr;
  column-gap: 24px;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const EmptyItem = React.memo(() => {
  return (
    <>
      {[1, 2, 3].map((_, i) => (
        <ItemContent key={i}>
          <Skeleton variant="rounded" height={15} width={15} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rounded" height={25} sx={{ borderRadius: "9999px" }} />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </ItemContent>
      ))}
    </>
  );
});

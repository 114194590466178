import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  styled,
} from "@mui/material";

export interface FormControlLabelProps extends MuiFormControlLabelProps {
  // Add your custom props here
}
export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: 12,
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));
